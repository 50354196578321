import React, {useState, useRef, useMemo, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {kebabCase, camelCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {alfDestinationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Form, InputField, AutosuggestField, SelectField, Option} from '@ace-de/ui-components';
import {Icon, locationIcon, searchIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import config from '../../config';

const LocationInput = props => {
    const {name, invoiceLineData, invoice, searchDestinationLocationGeolocation} = props;
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');
    const {partnerLocationSearchQuery: destinationLocationSearchQuery, partnerLocationCandidates} = invoice;

    const [newLocation, setNewLocation] = useState(invoiceLineData || null);

    const searchDestinationLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchDestinationLocationGeolocation === 'function') {
            return debounce(
                searchDestinationLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchDestinationLocationGeolocation]);

    const handleLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchDestinationLocationGeolocationDebounced({
                searchQueryString,
                invoiceId: `${invoice.id}`,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.address.formattedAddress;
        setNewLocation(locationCandidate);
    };

    return (
        <div className={cx('global!ace-u-grid', 'ace-u-margin--top-24', 'global!ace-full-width')}>
            <Form name={name}>
                {formValues => (
                    <Fragment>
                        <SelectField
                            name="destinationType"
                            value={newLocation?.destinationType || ''}
                            label={translate(`invoice_service_location.select_label.destination_type`)}
                            className={cx('ace-u-grid-column--span-2')}
                        >
                            {Object.keys(alfDestinationTypes).map((locationType, index) => (
                                <Option
                                    key={camelCase(locationType)}
                                    name={`invoice-location-${kebabCase(locationType)}`}
                                    value={locationType}
                                >
                                    {translate(`global.towing_destination_type.${locationType.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <InputField
                            name="name"
                            label={translate('invoice_service_location.input_label.destination_name')}
                            value={newLocation?.name || ''}
                            className={cx('ace-u-grid-column--span-5')}
                        />
                        <AutosuggestField
                            name="destinationAddress"
                            icon={searchIcon}
                            className={cx('ace-u-grid-column--span-5')}
                            label={translate('invoice_service_location.input_label.destination_address')}
                            value={newLocation?.address?.formattedAddress || ''}
                            onChange={handleLocationSearchQueryChange}
                            onOptionSelect={handleLocationCandidateSelect}
                            optionValueSelector={locationCandidate => {
                                return locationCandidate.address.formattedAddress;
                            }}
                        >
                            {(formValues['destinationAddress'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                            && destinationLocationSearchQuery === lastLocationSearchQuery.current
                                ? partnerLocationCandidates
                                    .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                    .map((locationCandidate, index) => {
                                        return (
                                            <Option
                                                key={`destination-location-candidate-${kebabCase(locationCandidate.address.formattedAddress)}`}
                                                name={`destination-location-candidate-${index}`}
                                                value={locationCandidate}
                                            >
                                                <Icon
                                                    icon={locationIcon}
                                                    className={cx('global!ace-u-margin--right-16')}
                                                />
                                                {locationCandidate.address.formattedAddress}
                                            </Option>
                                        );
                                    }) : null}
                        </AutosuggestField>
                        <InputField type="hidden" value={newLocation?.address || ''} name="address" />
                        <InputField type="hidden" value={newLocation?.coordinates || ''} name="coordinates" />
                        <InputField type="hidden" value="DESTINATION" name="type" />
                    </Fragment>
                )}
            </Form>
        </div>
    );
};
LocationInput.propTypes = {
    name: PropTypes.string.isRequired,
    searchDestinationLocationGeolocation: PropTypes.func.isRequired,
    invoiceLineData: PropTypes.object,
    invoice: PropTypes.object.isRequired,
};
LocationInput.defaultProps = {
    invoiceLineData: null,
};

const mapDispatchToProps = dispatch => ({
    searchDestinationLocationGeolocation: payload => dispatch({
        type: invoiceActionTypes.SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(LocationInput);
