export const leaFeatureActions = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
};

export const leaFeatures = {
    MEMBER_DATA: 'MEMBER_DATA',
    SERVICE_CASE: 'SERVICE_CASE',
    INVOICE: 'INVOICE',
    CASE_STATUS: 'CASE_STATUS',
    INVOICE_STATUS: 'INVOICE_STATUS',
    MESSAGE: 'MESSAGE',
    CONTACT: 'CONTACT',
    TASKS: 'TASKS',
    DOCUMENT: 'DOCUMENT',
};
