import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';

const searchTasks = function* searchTasks({payload}) {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const {searchQueryParams} = payload;

    yield fork(
        fetchRequest,
        taskActionTypes.SEARCH_TASKS_REQUEST,
        ecsTaskManagementService.getTasks, {
            searchQueryParams,
        },
    );

    const responseAction = yield take([
        taskActionTypes.SEARCH_TASKS_REQUEST_FAILED,
        taskActionTypes.SEARCH_TASKS_REQUEST_SUCCEEDED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskDTOs, totalElements: taskSearchResultsCount} = response;

        yield put({
            type: taskActionTypes.STORE_TASK_SEARCH_RESULTS,
            payload: {taskDTOs, taskSearchResultsCount},
        });
    }
};

export default searchTasks;
