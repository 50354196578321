import {select, fork, take, put} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../../contacts/contactActionTypes';

const loadSelectedContact = function* loadSelectedContact({payload}) {
    const {match} = payload;
    const {invoiceId, serviceCaseId} = match.params;
    const invoice = yield select(state => state.invoices.invoices[invoiceId]);
    const serviceCase = yield select(state => state.serviceCases.serviceCases[serviceCaseId]);
    const accountParty = invoice?.accountParty;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!accountParty || !serviceCase) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('client', `${serviceCase.client}`);
    searchQueryParams.append('ids', `${accountParty.id}`);

    yield fork(
        fetchRequest,
        contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST,
        leaAssignmentFlowService.getContacts,
        {searchQueryParams},
    );

    const responseAction = yield take([
        contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED,
        contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {payload: searchResultsPayload} = responseAction;
        const {contactDTOs} = searchResultsPayload.response;

        yield put({
            type: contactActionTypes.SET_SELECTED_CONTACT,
            payload: {contactDTOs},
        });
    }
};
export default loadSelectedContact;
