import {take, fork, put, select} from 'redux-saga/effects';
import {push, resolveRoute} from '@computerrock/formation-router';
import * as invoiceActionTypes from '../invoiceActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import routePaths from '../../routePaths';

const createInvoiceFlow = function* createInvoiceFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.CREATE_NEW_INVOICE);
        const {serviceCaseId} = payload;

        yield fork(fetchRequest,
            invoiceActionTypes.CREATE_NEW_INVOICE_REQUEST,
            leaAssignmentFlowService.createInvoice,
            {serviceCaseId});

        const responseAction = yield take([
            invoiceActionTypes.CREATE_NEW_INVOICE_REQUEST_SUCCEEDED,
            invoiceActionTypes.CREATE_NEW_INVOICE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {invoiceDTO} = response;
            const invoiceDTOs = [invoiceDTO];

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs},
            });

            yield put(push(resolveRoute(routePaths.INVOICES, {
                serviceCaseId,
                invoiceId: invoiceDTO.id,
            })));
        }
    }
};

export default createInvoiceFlow;
