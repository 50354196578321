import {put, take, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import updateInvoice from './updateInvoice';
import * as contactActionTypes from '../../contacts/contactActionTypes';

const invoiceContactSelectionFlow = function* invoiceContactSelectionFlow({payload}) {
    const {invoiceId, contactId, initiateContactSearch, client} = payload;

    yield* openModal(modalIds.BC_CONTACT_MODAL, {
        ...(contactId && {id: contactId}),
        ...(client && {client: `${client}`}),
        contentType: 'INVOICE_CONTACT_SEARCH',
    });

    if (initiateContactSearch && contactId) {
        const searchQueryParams = new URLSearchParams();
        searchQueryParams.append(`ids`, contactId);
        searchQueryParams.append(`client`, client);

        yield put({
            type: contactActionTypes.SEARCH_CONTACTS,
            payload: {searchQueryParams},
        });
    }

    const chosenModalOption = yield take([
        contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
        invoiceActionTypes.SELECT_INVOICE_CONTACT,
    ]);

    if (chosenModalOption.type === invoiceActionTypes.SELECT_INVOICE_CONTACT) {
        const {payload: chosenModalPayload} = chosenModalOption;
        const {selectedContact, isVKRInvoice} = chosenModalPayload;

        yield* updateInvoice({
            caller: invoiceActionTypes.SUBMIT_INVOICE_BASIC_DATA_FORM,
            invoiceId,
            invoiceData: {accountPartyId: isVKRInvoice ? selectedContact.debtorId : selectedContact.creditorId},
        });
    }

    yield put({type: contactActionTypes.RESET_CONTACT_SEARCH});

    yield put({
        type: contactActionTypes.STORE_CONTACTS_SEARCH_ERROR,
        payload: {isInvoiceContactsSearchError: false},
    });

    const {location} = yield select(state => state.router);
    yield* closeModal(modalIds.BC_CONTACT_MODAL, {...(location?.query && {...location.query})});
};

export default invoiceContactSelectionFlow;
