import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, downloadIcon, Icon, InteractiveIcon, Panel, outgoingMessageIcon, useStyles, uploadIcon, incomingMessageIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useRouteUnmountEffect, withRouter} from '@computerrock/formation-router';
import * as invoiceSelectors from './invoiceSelectors';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import config from '../config';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';
import serviceManager from '../serviceManager';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;
    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const InvoiceCommunicationTab = props => {
    const {cx} = useStyles();
    const {initiateReplyUploadFlow, initiateDownloadAllDocuments, updateMessageAsRead} = props;
    const {caseMessages, invoice} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('invoice_communication_tab');
    const [messages, setMessages] = useState([]);
    const [readMessageIds, setReadMessageIds] = useState([]);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const handleOnMessageContentExpansion = (messageContent, messageId, event) => {
        event.preventDefault();
        event.stopPropagation();

        const messageContentPreview = generateMessagePreview(messageContent, true);
        const openedMessage = messages.find(message => message.id === messageId);
        if (!openedMessage) return;

        openedMessage.messageContentPreview = messageContentPreview;
        const openedMessages = messages.map(message => {
            if (message.id === messageId) return openedMessage;
            return message;
        });

        if (!openedMessage.isRead) setReadMessageIds(prevState => ([
            ...prevState,
            openedMessage.id,
        ]));

        setMessages(openedMessages);
    };

    useEffect(() => {
        if (caseMessages.length === 0) return;
        setMessages(caseMessages.map(message => ({
            ...message,
            ...(message.channel === alfMessageChannelTypes.ELLA && message.messageType === alfMessageTypes.INCOMING && {
                messageContentPreview: generateMessagePreview(message.content, false),
            }),
        })));
    }, [caseMessages]);

    useRouteUnmountEffect(() => {
        if (readMessageIds.length === 0) return;
        updateMessageAsRead({messageIds: readMessageIds});
    }, [updateMessageAsRead, readMessageIds]);

    useBeforeUnloadEffect(event => {
        if (readMessageIds.length === 0) return;
        return {
            request: leaAssignmentFlowService.updateMessagesAsRead,
            requestData: {
                messageIds: readMessageIds,
            },
        };
    });

    if (!invoice) return null;

    return (
        <Panel
            title={translateTab('messages_panel.title')}
            actions={(
                <ButtonPrimary onClick={() => initiateReplyUploadFlow({invoiceId: invoice.id})}>
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translateTab('button_label.upload_reply')}
                </ButtonPrimary>
            )}
        >
            <Table qaIdent="messages-overview-table">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="message-type" colSpan={2} />
                        <TableCell qaIdentPart="created-date" colSpan={3}>
                            {translateTab('table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="channel" colSpan={3}>
                            {translateTab('table_header.communication_channel')}
                        </TableCell>
                        <TableCell qaIdentPart="message-subject" colSpan={9}>
                            {translateTab('table_header.subject')}
                        </TableCell>
                        <TableCell qaIdentPart="contact-name" colSpan={4}>
                            {translateTab('table_header.contact_name')}
                        </TableCell>
                        <TableCell qaIdentPart="user" colSpan={3}>
                            {translateTab('table_header.user')}
                        </TableCell>
                        <TableCell qaIdentPart="attachment" colSpan={2}>
                            {translateTab('table_header.attachment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages.length > 0 && messages.map(message => {
                        return (
                            <TableRow
                                key={`${message.invoiceId}-${message.id}`}
                                qaIdentPart={message.invoiceId}
                            >
                                <TableCell
                                    qaIdentPart="message-type"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <Icon
                                            icon={message.messageType === alfMessageTypes.OUTGOING
                                                ? outgoingMessageIcon
                                                : incomingMessageIcon}
                                            className={cx('global!ace-u-flex--shrink-0')}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="created-date"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {message.createdAt || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="channel"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {translate(`global.communication_channel_option.${message.channel.toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="message-subject"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={9}
                                    className={cx([
                                        'ace-c-table__cell--overflow-visible',
                                        'ace-c-table__cell--white-space-normal',
                                    ])}
                                >
                                    {message.channel === alfMessageChannelTypes.ELLA
                                    && message.messageType === alfMessageTypes.INCOMING
                                        ? (
                                            <div>
                                                {message.messageContentPreview}
                                                {message.content?.length > config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT
                                                    && (
                                                    <span
                                                        className={cx([
                                                            'global!ace-u-typography--variant-body-bold',
                                                            'global!ace-u-typography--color-highlighted',
                                                            'global!ace-u-cursor--pointer',
                                                        ])}
                                                        onClick={event => handleOnMessageContentExpansion(
                                                            message.content,
                                                            message.id,
                                                            event,
                                                        )}
                                                    >
                                                        [{translateTab('table_body.read_more')}]
                                                    </span>
                                                    )}
                                            </div>
                                        )
                                        : message.subject || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="contact-name"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={4}
                                >
                                    {message.messageType === alfMessageTypes.OUTGOING
                                        ? message.recipient || '-'
                                        : message.sender || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="user"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={3}
                                >
                                    {message.creatorName || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="attachment"
                                    qaIdentPartPostfix={message.invoiceId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <InteractiveIcon
                                            icon={downloadIcon}
                                            className={cx([
                                                'ace-c-interactive-icon--primary',
                                                'global!ace-u-flex--shrink-0',
                                                'global!ace-u-cursor--pointer',
                                            ])}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                initiateDownloadAllDocuments({
                                                    attachments: message.attachments,
                                                });
                                            }}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

InvoiceCommunicationTab.propTypes = {
    initiateReplyUploadFlow: PropTypes.func.isRequired,
    initiateDownloadAllDocuments: PropTypes.func.isRequired,
    updateMessageAsRead: PropTypes.func.isRequired,
    caseMessages: PropTypes.array,
    invoice: PropTypes.object,
};

InvoiceCommunicationTab.defaultProps = {
    caseMessages: [],
    invoice: null,
};

const mapStateToProps = (state, props) => {
    const invoiceSelector = invoiceSelectors.createInvoicesSelector();

    return {
        caseMessages: state.communication.messages,
        invoice: invoiceSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateReplyUploadFlow: payload => dispatch({
        type: communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW,
        payload,
    }),
    initiateDownloadAllDocuments: payload => dispatch({
        type: communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW,
        payload,
    }),
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceCommunicationTab));
