import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {alfMessageChannelTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';
import * as contactActionTypes from '../../contacts/contactActionTypes';
import modalIds from '../../modalIds';

const initiateSendMessageFlow = function* initiateSendMessageFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        const {payload} = yield take([communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW]);
        const {invoiceId, selectedContact} = payload;
        yield put({type: communicationActionTypes.RESET_ACCOUNT_PARTY_CONTACT_DETAILS});

        if (selectedContact) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });
        }
        yield* openModal(modalIds.SEND_MESSAGE_MODAL, {
            ...(invoiceId ? {invoiceId} : {}),
            ...(selectedContact ? {contactId: selectedContact.id} : {}),
        });

        const sendMessageConfirmAction = yield take([
            communicationActionTypes.DECLINE_SEND_MESSAGE,
            communicationActionTypes.CONFIRM_SEND_MESSAGE,
        ]);

        if (sendMessageConfirmAction.type === communicationActionTypes.CONFIRM_SEND_MESSAGE) {
            const {payload} = sendMessageConfirmAction;
            if (payload) {
                yield fork(
                    fetchRequest,
                    communicationActionTypes.SEND_MESSAGE_REQUEST,
                    leaAssignmentFlowService.sendMessage,
                    {
                        messageData: payload,
                    },
                );

                const sendMessageResponseAction = yield take([
                    communicationActionTypes.SEND_MESSAGE_REQUEST_FAILED,
                    communicationActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
                ]);
                if (sendMessageResponseAction.error) {
                    // todo handle error
                }

                if (!sendMessageResponseAction.error) {
                    const {response} = sendMessageResponseAction.payload;
                    const {messageDataDTO} = response;

                    if (messageDataDTO.channel === alfMessageChannelTypes.MAIL
                    && messageDataDTO.attachments?.length > 0) {
                        const relevantAttachments = messageDataDTO.attachments.filter(attachment => (
                            !!attachment.isInternal
                        ));
                        const {fileName, url} = relevantAttachments[0];
                        yield fork(
                            fetchRequest,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST,
                            alfFileAssetsService.downloadFile,
                            {
                                fileName,
                                url,
                                shouldOpenFile: true,
                            },
                        );
                        yield take([
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED,
                        ]);
                    }
                }
            }
        }

        yield* closeModal(modalIds.SEND_MESSAGE_MODAL, {invoiceId: payload.invoiceId});
    }
};

export default initiateSendMessageFlow;
