import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import PropTypes from 'prop-types';
import {withRouter} from '@computerrock/formation-router';
import {alfRelevantLocationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, ContentBlock, ContentItem, DataRow} from '@ace-de/ui-components';
import {ArcGISMapWidget, useArcGISMap, createMarkerGraphic, markerTowingVariantBluePNG, markerHomeVariantBluePNG, markerWorkshopVariantBluePNG, markerPersonVariantBluePNG, markerCombinedCPAndHome, markerCombinedDamageAndCP, markerCombinedDamageAndHome, markerCombinedDamageAndTowing, markerCombinedTowingAndCP, markerCombinedTowingAndHome} from '@ace-de/eua-arcgis-map';
import {useTranslate} from '@computerrock/formation-i18n';
import * as invoiceSelectors from '../invoiceSelectors';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import config from '../../config';

const combinedIconTypes = {
    COMBINED_ACE_PARTNER_AND_MEMBER: 'COMBINED_ACE_PARTNER_AND_MEMBER',
    COMBINED_TOWING_AND_ACE_PARTNER: 'COMBINED_TOWING_AND_ACE_PARTNER',
    COMBINED_DAMAGE_AND_ACE_PARTNER: 'COMBINED_DAMAGE_AND_ACE_PARTNER',
    COMBINED_DAMAGE_AND_MEMBER: 'COMBINED_DAMAGE_AND_MEMBER',
    COMBINED_DAMAGE_AND_TOWING: 'COMBINED_DAMAGE_AND_TOWING',
    COMBINED_TOWING_AND_MEMBER: 'COMBINED_TOWING_AND_MEMBER',
};

const relevantLocationIcons = {
    [alfRelevantLocationTypes.TOWING]: markerWorkshopVariantBluePNG,
    [alfRelevantLocationTypes.FINAL_TOWING]: markerWorkshopVariantBluePNG,
    [alfRelevantLocationTypes.DAMAGE]: markerPersonVariantBluePNG,
    [alfRelevantLocationTypes.SERVICE]: markerPersonVariantBluePNG,
    [alfRelevantLocationTypes.MEMBER]: markerHomeVariantBluePNG,
    [alfRelevantLocationTypes.ACE_PARTNER]: markerTowingVariantBluePNG,
    [combinedIconTypes.COMBINED_ACE_PARTNER_AND_MEMBER]: markerCombinedCPAndHome,
    [combinedIconTypes.COMBINED_TOWING_AND_ACE_PARTNER]: markerCombinedTowingAndCP,
    [combinedIconTypes.COMBINED_DAMAGE_AND_ACE_PARTNER]: markerCombinedDamageAndCP,
    [combinedIconTypes.COMBINED_DAMAGE_AND_MEMBER]: markerCombinedDamageAndHome,
    [combinedIconTypes.COMBINED_DAMAGE_AND_TOWING]: markerCombinedDamageAndTowing,
    [combinedIconTypes.COMBINED_TOWING_AND_MEMBER]: markerCombinedTowingAndHome,
    [alfRelevantLocationTypes.DESTINATION]: markerTowingVariantBluePNG,
    // [alfRelevantLocationTypes.DROP_OFF]: '',
    // [alfRelevantLocationTypes.PICK_UP]: '',
};
const relevantLocationsHierarchy = {
    [alfRelevantLocationTypes.DAMAGE]: 1,
    [alfRelevantLocationTypes.SERVICE]: 1,
    [alfRelevantLocationTypes.TOWING]: 2,
    [alfRelevantLocationTypes.FINAL_TOWING]: 2,
    [alfRelevantLocationTypes.ACE_PARTNER]: 3,
    [alfRelevantLocationTypes.MEMBER]: 4,
    [alfRelevantLocationTypes.DESTINATION]: 3,
    // For the combined places take the hierarchy of the higher ranked as primary
    [combinedIconTypes.COMBINED_ACE_PARTNER_AND_MEMBER]: 3,
    [combinedIconTypes.COMBINED_TOWING_AND_ACE_PARTNER]: 2,
    [combinedIconTypes.COMBINED_DAMAGE_AND_ACE_PARTNER]: 1,
    [combinedIconTypes.COMBINED_DAMAGE_AND_MEMBER]: 1,
    [combinedIconTypes.COMBINED_DAMAGE_AND_TOWING]: 1,
    [combinedIconTypes.COMBINED_TOWING_AND_MEMBER]: 2,
};

const InvoiceRelevantLocationsMap = props => {
    const {cx} = useStyles();
    const {invoice, serviceCase} = props;
    const {member} = serviceCase;
    const {createTranslateShorthand, activeLocale, translate} = useTranslate();
    const translateTab = createTranslateShorthand('invoice_overview_auditing');
    const mapName = 'invoice-relevant-locations-map';
    const arcGISMap = useArcGISMap(mapName);
    const memberAddress = member?.personalDetails?.address?.formattedAddress
        ? member?.personalDetails?.address.formattedAddress
        : [
            member?.personalDetails?.address?.street,
            member?.personalDetails?.address?.city,
            member?.personalDetails?.address?.postCode,
            member?.personalDetails?.address?.country,
        ].filter(addressComponent => !!addressComponent).join(',') || '-';

    const {locations, relevantLocationMapExtent} = invoice;

    useEffect(() => {
        if (!arcGISMap || !locations || locations.length === 0 || !relevantLocationMapExtent) return;
        const sortedLocations = [...locations]
            .sort((locationA, locationB) => {
                const locationHierarchyA = relevantLocationsHierarchy[locationA.type];
                const locationHierarchyB = relevantLocationsHierarchy[locationB.type];

                return locationHierarchyA >= locationHierarchyB ? -1 : 1;
            });

        arcGISMap.setGraphics({
            graphics: [
                ...(sortedLocations
                    ? sortedLocations.map(location => {
                        return createMarkerGraphic({
                            id: camelCase(location.type),
                            longitude: location.coordinates?.longitude,
                            latitude: location.coordinates?.latitude,
                            icon: relevantLocationIcons[location.type],
                            isDraggable: false,
                        });
                    })
                    : []
                ),
            ],
        });
        // setGraphics method by default sets map's center to the coordinates of the first location
        // therefore we need to reset it by setting map extent after graphics are created
        arcGISMap.setMapExtent(relevantLocationMapExtent);
    }, [arcGISMap, locations, relevantLocationMapExtent]);

    if (!invoice || !serviceCase) return null;

    const towingLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.TOWING;
    });
    const finalTowingLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.FINAL_TOWING;
    });
    const acePartner = locations.find(location => {
        return location.type === alfRelevantLocationTypes.ACE_PARTNER;
    });
    const towingLocationAddress = towingLocation?.address?.formattedAddress
    || [towingLocation?.address?.street, towingLocation?.address?.postCode, towingLocation?.address?.city]
        .filter(value => (!!value)).join(', ');
    const finalTowingLocationAddress = finalTowingLocation?.address?.formattedAddress || [
        finalTowingLocation?.address?.street,
        finalTowingLocation?.address?.postCode,
        finalTowingLocation?.address?.city,
    ].filter(value => (!!value)).join(', ');
    const invoiceLineLocations = invoice?.lines?.map(invoiceLine => invoiceLine.destination)
        .filter(destination => !!destination) || [];

    return (
        <Panel title={translateTab('panel_title.map')} contentClassName={cx('global!ace-u-padding--bottom-32')}>
            <ContentBlock>
                <ContentItem className={cx('ace-c-content-item--span-6')}>
                    <ArcGISMapWidget
                        name={mapName}
                        tempArcGISMapId={config.TEMP_ARCGIS_MAP_ID}
                        locale={activeLocale}
                        className={cx('ace-c-arcgis-map-widget--is-visible')}
                    />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--span-6')}>
                    <div>
                        <DataRow
                            label={translateTab('data_row_label.service_provider')}
                            qaIdent="invoice-service-provider"
                        >
                            {invoice?.lines?.length ? invoice.lines[0].partnerName || '-' : '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.service_provider_address')}
                            qaIdent="invoice-service-provider-address"
                        >
                            {acePartner?.address?.formattedAddress || invoice.lines[0]?.partnerAddress || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.damage_location')}
                            qaIdent="invoice-damage-location"
                        >
                            {serviceCase?.damage?.location?.address?.formattedAddress || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.damage_location_in_area')}
                            qaIdent="invoice-damage-location-in-area"
                            contentClassName={cx({
                                'global!ace-u-typography--color-warning': typeof (invoice?.damageLocationWithinArea) !== 'undefined' && !invoice.damageLocationWithinArea,
                            })}
                        >
                            {typeof (invoice?.damageLocationWithinArea) === 'undefined'
                                ? '-'
                                : translate(`global.damage_location_within_area.${invoice.damageLocationWithinArea}`)
                            }
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.towing_destination_name')}
                            qaIdent="invoice-towing-destination-name"
                        >
                            {finalTowingLocation?.name || towingLocation?.name || '-'}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.towing_destination_address')}
                            qaIdent="invoice-towing-destination-address"
                        >
                            {
                                finalTowingLocationAddress
                                || towingLocationAddress
                                || '-'
                            }
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.towing_destination_ecs')}
                            qaIdent="invoice-towing-destination-ecs"
                        >
                            {towingLocationAddress || '-' }
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.towing_destination_in_area')}
                            qaIdent="invoice-towing-destination-in-area"
                            contentClassName={cx({
                                'global!ace-u-typography--color-warning': typeof (invoice?.towingDestinationWithinArea) !== 'undefined' && !invoice.towingDestinationWithinArea,
                            })}
                        >
                            {typeof (invoice?.towingDestinationWithinArea) === 'undefined'
                                ? '-'
                                : translate(`global.towing_location_within_area.${invoice.towingDestinationWithinArea}`)
                            }
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.distance_provider_damage_towing_provider')}
                            qaIdent="invoice-distance-provider-damage-towing-provider"
                        >
                            {invoice.providerDamageTowingProviderDistance
                                ? `${Math.round(invoice.providerDamageTowingProviderDistance)} ${translate('global.unit.km')}`
                                : '-'
                            }
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.member_address')}
                            qaIdent="invoice-member-address"
                        >
                            {memberAddress}
                        </DataRow>
                        <DataRow
                            label={translateTab('data_row_label.residence_to_damage_location_distance')}
                            qaIdent="invoice-residence-to-damage-location-distance"
                        >
                            {serviceCase.residenceToDamageLocationDistance
                                ? `${Math.round(serviceCase.residenceToDamageLocationDistance)} ${translate('global.unit.km')}`
                                : '-'
                            }
                        </DataRow>
                        {invoiceLineLocations && invoiceLineLocations.map((invoiceLineLocation, idx) => {
                            return (
                                <DataRow
                                    key={invoiceLineLocation.id}
                                    label={translateTab('data_row_label.destination')}
                                    qaIndent={`invoice-invoice-line-destination-${idx}`}
                                >
                                    {invoiceLineLocation?.address?.formattedAddress}
                                </DataRow>
                            );
                        })}
                    </div>
                </ContentItem>
            </ContentBlock>
        </Panel>
    );
};

InvoiceRelevantLocationsMap.propTypes = {
    invoice: PropTypes.object,
    serviceCase: PropTypes.object,
};

InvoiceRelevantLocationsMap.defaultProps = {
    invoice: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    const invoiceSelector = invoiceSelectors.createInvoicesSelector();

    return {
        invoice: invoiceSelector(state, props),
        serviceCase: serviceCaseSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(InvoiceRelevantLocationsMap));
