import {take, put, fork, select} from 'redux-saga/effects';
import {alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';

const loadEllaUnreadMessages = function* loadEllaUnreadMessages() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('messageChannel', alfMessageChannelTypes.ELLA);
    searchQueryParams.append('messageType', alfMessageTypes.INCOMING);
    searchQueryParams.append('isRead', 'false');

    yield fork(fetchRequest,
        communicationActionTypes.FETCH_MESSAGES_REQUEST,
        leaAssignmentFlowService.getMessages,
        {searchQueryParams});

    const fetchCaseMessagesAction = yield take([
        communicationActionTypes.FETCH_MESSAGES_REQUEST_SUCCEEDED,
        communicationActionTypes.FETCH_MESSAGES_REQUEST_FAILED,
    ]);

    if (!fetchCaseMessagesAction.error) {
        const {response} = fetchCaseMessagesAction.payload;
        const {messageDTOs} = response;

        yield put({
            type: communicationActionTypes.STORE_ELLA_UNREAD_MESSAGES,
            payload: {messageDTOs},
        });
    }
};

export default loadEllaUnreadMessages;
