import {all, fork, takeLatest} from 'redux-saga/effects';
import batchUpdateInvoiceFlow from './sagas/batchUpdateInvoiceFlow';
import createInvoiceFlow from './sagas/createInvoiceFlow';
import updateInvoiceBasicDataFlow from './sagas/updateInvoiceBasicDataFlow';
import invoiceContactSelectionFlow from './sagas/invoiceContactSelectionFlow';
import invoiceDocumentUploadFlow from './sagas/invoiceDocumentUploadFlow';
import invoiceDocumentEditFlow from './sagas/invoiceDocumentEditFlow';
import invoiceDocumentDeleteFlow from './sagas/invoiceDocumentDeleteFlow';
import initiateCreateInvoiceLineFlow from './sagas/initiateCreateInvoiceLineFlow';
import createInvoiceLine from './sagas/createInvoiceLine';
import downloadInvoiceDocument from './sagas/downloadInvoiceDocument';
import searchInvoicePartnerLocationGeolocation from './sagas/searchInvoicePartnerLocationGeolocation';
import searchInvoices from './sagas/searchInvoices';
import checkINARulesFlow from './sagas/checkINARulesFlow';
import initiateManualInvoiceStatusUpdateFlow from './sagas/initiateManualInvoiceStatusUpdateFlow';
import batchUpdateInvoiceServiceLineFlow from './sagas/batchUpdateInvoiceServiceLineFlow';
import updateInvoiceServiceLinesFlow from './sagas/updateInvoiceServiceLinesFlow';
import invoiceForwardingFlow from './sagas/invoiceForwardingFlow';
import redirectInvoiceScreen from './sagas/redirectInvoiceScreen';
import createInternalNoteFlow from './sagas/createInternalNoteFlow';
import invoiceLineDeleteFlow from './sagas/invoiceLineDeleteFlow';
import initiateUpdateInvoiceStatusFlow from './sagas/inititateUpdateInvoiceStatusFlow';
import initiateInvoiceDeclineFlow from './sagas/initiateInvoiceDeclineFlow';
import invoiceLineEditFlow from './sagas/invoiceLineEditFlow';
import createInvoiceForAssignmentFlow from './sagas/createInvoiceForAssignmentFlow';
import initiateInvoiceApprovalFlow from './sagas/initiateInvoiceApprovalFlow';
import invoiceCorrectionFlow from './sagas/invoiceCorrectionFlow';
import invoiceEstimationFlow from './sagas/invoiceEstimationFlow';
import loadAccountPartyOptions from './sagas/loadAccountPartyOptions';
import invoiceDeleteFlow from './sagas/invoiceDeleteFlow';
import * as invoiceActionTypes from './invoiceActionTypes';

/**
 *  Invoice watcher saga
 */
const invoiceWatcher = function* invoiceWatcher() {
    yield all([
        fork(batchUpdateInvoiceFlow),
        fork(createInvoiceFlow),
        fork(updateInvoiceBasicDataFlow),
        fork(invoiceDocumentUploadFlow),
        fork(invoiceDocumentEditFlow),
        fork(invoiceDocumentDeleteFlow),
        fork(initiateCreateInvoiceLineFlow),
        fork(downloadInvoiceDocument),
        fork(checkINARulesFlow),
        fork(initiateManualInvoiceStatusUpdateFlow),
        fork(batchUpdateInvoiceServiceLineFlow),
        fork(updateInvoiceServiceLinesFlow),
        fork(invoiceForwardingFlow),
        fork(createInternalNoteFlow),
        fork(invoiceLineDeleteFlow),
        fork(invoiceLineEditFlow),
        fork(initiateUpdateInvoiceStatusFlow),
        fork(initiateInvoiceDeclineFlow),
        fork(createInvoiceForAssignmentFlow),
        fork(invoiceCorrectionFlow),
        fork(invoiceEstimationFlow),
        fork(invoiceDeleteFlow),
        takeLatest(
            invoiceActionTypes.SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION,
            searchInvoicePartnerLocationGeolocation,
        ),
        takeLatest(
            invoiceActionTypes.SEARCH_INVOICES,
            searchInvoices,
        ),
        takeLatest(
            invoiceActionTypes.CREATE_INVOICE_LINE,
            createInvoiceLine,
        ),
        takeLatest(
            invoiceActionTypes.INITIATE_INVOICE_SCREEN_REDIRECTION,
            redirectInvoiceScreen,
        ),
        takeLatest(
            invoiceActionTypes.INITIATE_INVOICE_APPROVAL_FLOW,
            initiateInvoiceApprovalFlow,
        ),
        takeLatest(
            invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS,
            loadAccountPartyOptions,
        ),
        takeLatest(
            invoiceActionTypes.INITIATE_CONTACT_SELECTION_FLOW,
            invoiceContactSelectionFlow,
        ),
    ]);
};

export default invoiceWatcher;
