import {take, select, fork, put, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {LeistContact} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import contactDataModalTypes from '../modals/contactDataModalTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';
import * as contactActionTypes from '../contactActionTypes';
import routePaths from '../../routePaths';

// todo rewrite saga and find suitable change for delay after modal closure
//  (needed for route unmount effect on invoice screen)
const contactCreationFlow = function* contactCreationFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryContactCreation = false;
    let retryContactCreationData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_CREATE_CONTACT_FLOW);
            payloadData = payload;
        }

        const {client} = payloadData;
        let chosenModalOption;

        if (!shouldRetryContactCreation && client) {
            yield* openModal(modalIds.CONTACT_DATA, {
                client,
                type: contactDataModalTypes.CREATE_CONTACT,
            });

            chosenModalOption = yield take([
                contactActionTypes.DECLINE_CREATE_CONTACT,
                contactActionTypes.CONFIRM_CREATE_CONTACT,
            ]);
        }

        yield* closeModal(modalIds.CONTACT_DATA, {
            client,
            type: contactDataModalTypes.CREATE_CONTACT,
        });
        yield delay(100);

        if (shouldRetryContactCreation
            || chosenModalOption.type === contactActionTypes.CONFIRM_CREATE_CONTACT) {
            const contactData = !shouldRetryContactCreation && chosenModalOption?.payload?.contactData
                ? chosenModalOption?.payload?.contactData : retryContactCreationData?.contactData;

            const invoiceId = !shouldRetryContactCreation && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryContactCreationData?.invoiceId;

            const client = !shouldRetryContactCreation && chosenModalOption?.payload?.client
                ? chosenModalOption?.payload?.client : retryContactCreationData?.client;

            yield* openModal(modalIds.LOADER, {type: contactDataModalTypes.CREATE_CONTACT});

            yield fork(fetchRequest,
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST,
                leaAssignmentFlowService.createContact,
                {
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contactData),
                    },
                });

            const responseAction = yield take([
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST_SUCCEEDED,
                contactActionTypes.CREATE_NEW_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: contactDataModalTypes.CREATE_CONTACT});
            yield delay(100);
            shouldRetryContactCreation = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;
                const contactDTOs = [contactDTO];

                yield put({
                    type: contactActionTypes.STORE_CONTACTS,
                    payload: {contactDTOs},
                });

                shouldWaitForAction = true;
                const queryParams = new URLSearchParams();
                queryParams.append('ids', contactDTO.id);
                queryParams.append('client', client);
                yield put(replace(resolveRoute(routePaths.CONTACTS, {}, {search: queryParams.toString()})));
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === 503) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                yield* openModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.CREATE_CONTACT});

                const nextAction = yield take([
                    contactActionTypes.RETRY_CONTACT_CREATION,
                    contactActionTypes.CANCEL_ACTION,
                ]);

                yield* closeModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.CREATE_CONTACT});
                yield delay(100);

                if (nextAction.type === contactActionTypes.RETRY_CONTACT_CREATION) {
                    shouldRetryContactCreation = true;
                    shouldWaitForAction = false;
                    retryContactCreationData = {
                        contactData,
                        invoiceId,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryContactCreation = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        yield* closeModal(modalIds.CONTACT_DATA, {
            client,
            type: contactDataModalTypes.CREATE_CONTACT,
        });
    }
};

export default contactCreationFlow;
