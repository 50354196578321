import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Vehicle} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import updateServiceCase from './updateServiceCase';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import * as memberActionTypes from '../../members/memberActionTypes';

/**
 * Service case vehicle create flow (uses modal)
 * creates a new vehicle on the AMP & patches the vehicle snapshot on a case
 */
const createServiceCaseVehicleFlow = function* createServiceCaseVehicleFlow() {
    const {serviceManager} = yield select(state => state.application);
    const membersService = serviceManager.loadService('membersService');

    while (true) {
        yield take(serviceCaseActionTypes.INITIATE_SERVICE_CASE_VEHICLE_CREATION_FLOW);

        yield* openModal(modalIds.VEHICLE_DATA_MODAL);

        const chosenModalOption = yield take([
            serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_CREATION,
            serviceCaseActionTypes.DECLINE_SERVICE_CASE_VEHICLE_CREATION,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_CREATION) {
            const {payload} = chosenModalOption;
            const {membershipNo, memberVehicle, serviceCaseData, serviceCaseId} = payload;

            yield fork(
                fetchRequest,
                memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST,
                membersService.createMemberVehicle,
                {
                    memberVehicleDTO: Vehicle.objectToDTO(memberVehicle),
                },
            );
            const responseAction = yield take([
                memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST_SUCCEEDED,
                memberActionTypes.CREATE_MEMBER_VEHICLE_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {memberVehicleDTO} = response;

                yield put({
                    type: memberActionTypes.STORE_MEMBER_VEHICLE,
                    payload: {membershipNo, memberVehicleDTO},
                });

                if (memberVehicleDTO.id) {
                    yield* updateServiceCase({
                        caller: serviceCaseActionTypes.CONFIRM_SERVICE_CASE_VEHICLE_CREATION,
                        serviceCaseId,
                        serviceCaseData: {
                            ...serviceCaseData,
                            vehicle: {
                                ...serviceCaseData.vehicle,
                                id: memberVehicleDTO.id,
                            },
                        },
                    });
                }
            }
        }

        yield* closeModal(modalIds.VEHICLE_DATA_MODAL);
    }
};

export default createServiceCaseVehicleFlow;
