import {fork, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as communicationActionTypes from '../communicationActionTypes';

const downloadMessageAttachments = function* downloadMessageAttachments() {
    const {serviceManager} = yield select(state => state.application);
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        const {payload} = yield take(communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW);
        const {attachments} = payload;

        yield fork(
            fetchRequest,
            communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST,
            alfFileAssetsService.downloadAllFiles,
            {attachments},
        );

        yield take([
            communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_SUCCEEDED,
            communicationActionTypes.DOWNLOAD_MESSAGE_ATTACHMENTS_REQUEST_FAILED,
        ]);
    }
};

export default downloadMessageAttachments;
