import {fork, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import modalIds from '../../modalIds';
import * as communicationActionTypes from '../communicationActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';

const initiateUploadReplyFlow = function* serviceCaseReplyUploadFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take([communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW]);
        const invoiceId = payload?.invoiceId;

        yield* openModal(modalIds.UPLOAD_REPLY_MODAL);

        const chosenModalOption = yield take([
            communicationActionTypes.DECLINE_UPLOAD_REPLY,
            communicationActionTypes.CONFIRM_UPLOAD_REPLY,
        ]);

        if (chosenModalOption.type === communicationActionTypes.CONFIRM_UPLOAD_REPLY) {
            const {payload} = chosenModalOption;
            const {messageData} = payload;

            if (invoiceId) messageData.invoiceId = invoiceId;

            yield fork(
                fetchRequest,
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST,
                leaAssignmentFlowService.sendMessage,
                {messageData},
            );

            yield take([
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST_SUCCEEDED,
                communicationActionTypes.UPLOAD_REPLY_MESSAGE_REQUEST_FAILED,
            ]);
        }

        yield* closeModal(modalIds.UPLOAD_REPLY_MODAL);
    }
};

export default initiateUploadReplyFlow;
