import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {Panel, Pill, Paginator} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as taskActionTypes from '../../tasks/taskActionTypes';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import routePaths from '../../routePaths';
import config from '../../config';

const pillVariants = {
    [etmTaskStatusTypes.DUE]: 'pending',
    [etmTaskStatusTypes.OVERDUE]: 'negative',
    [etmTaskStatusTypes.DONE]: 'positive',
};

const MyTaskPanel = props => {
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('my_task_panel');
    const {initiateTaskEdit, currentUsersTasks, history, currentUsersTasksCount} = props;
    const queryParams = new URLSearchParams(history.location.search);
    const paginatorCount = Math.ceil(currentUsersTasksCount / config.TASKS_DEFAULT_PAGE_SIZE);

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.TASKS_DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.DASHBOARD, {}, {search: queryParamsString}));
    };

    return (
        <Panel title={translatePanel('panel_title.my_tasks', {amountOfTasks: currentUsersTasksCount})}>
            <Table qaIdent="task-list-results">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="task-due-date-time" colSpan={3}>
                            {translatePanel('table_header.due_date_time')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-case-id" colSpan={2}>
                            {translatePanel('table_header.service_case_id')}
                        </TableCell>
                        <TableCell qaIdentPart="task-description" colSpan={5}>
                            {translatePanel('table_header.description')}
                        </TableCell>
                        <TableCell qaIdentPart="task-type" colSpan={4}>
                            {translatePanel('table_header.type')}
                        </TableCell>
                        <TableCell qaIdentPart="task-status" colSpan={3}>
                            {translatePanel('table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="task-created-by" colSpan={3}>
                            {translatePanel('table_header.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentUsersTasks?.length > 0 && currentUsersTasks.map(task => (
                        <TableRow
                            key={task.id}
                            qaIdentPart={task.id}
                            onClick={() => initiateTaskEdit({taskId: task.id})}
                        >
                            <TableCell
                                qaIdentPart="task-due-date-time"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                {moment(task.dueDate).format('DD.MM.YYYY - HH:mm')}
                            </TableCell>
                            <TableCell
                                qaIdentPart="sc-case-id"
                                qaIdentPartPostfix={task.id}
                                colSpan={2}
                            >
                                {task.assignedCaseId}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-description"
                                qaIdentPartPostfix={task.id}
                                colSpan={5}
                            >
                                {task.description}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-type"
                                qaIdentPartPostfix={task.id}
                                colSpan={4}
                            >
                                {translate(`global.task_category.${task.category.toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-status"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                <Pill type={pillVariants[task.status]}>
                                    {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                </Pill>
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-created-by"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                {task.createdByUser || '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {currentUsersTasks.length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

MyTaskPanel.propTypes = {
    initiateTaskEdit: PropTypes.func.isRequired,
    currentUsersTasks: PropTypes.array,
    currentUsersTasksCount: PropTypes.number,
    history: PropTypes.object,
};

MyTaskPanel.defaultProps = {
    currentUsersTasks: [],
    currentUsersTasksCount: 0,
    history: null,
};

const mapStateToProps = state => {
    return {
        users: userProfileSelectors.getUsers(state),
        currentUsersTasks: state.tasks.taskSearchResults,
        currentUsersTasksCount: state.tasks.taskSearchResultsCount,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateTaskEdit: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_EDIT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTaskPanel));
