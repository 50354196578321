import {select, fork, take, put, call} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';
import updateInvoiceServiceLines from './updateInvoiceServiceLines';

const createInternalNoteFlow = function* createInternalNoteFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.SUBMIT_CREATE_INTERNAL_NOTE);
        const {invoiceId, internalNoteData, invoiceServiceLines} = payload;

        let index = 0;
        while (index < invoiceServiceLines.length) {
            const invoiceServiceLine = invoiceServiceLines[index];
            yield call(updateInvoiceServiceLines, {
                payload: {
                    caller: `${invoiceActionTypes.SUBMIT_CREATE_INTERNAL_NOTE}-${invoiceServiceLine.lineNo}`,
                    invoiceId,
                    invoiceServiceLineData: invoiceServiceLine,
                    lineNo: invoiceServiceLine.lineNo,
                },
            });
            index += 1;
        }

        yield fork(fetchRequest, invoiceActionTypes.CREATE_INTERNAL_NOTE_REQUEST,
            leaAssignmentFlowService.createInternalNote, {
                invoiceId: invoiceId,
                description: internalNoteData,
            });

        const responseAction = yield take([
            invoiceActionTypes.CREATE_INTERNAL_NOTE_REQUEST_SUCCEEDED,
            invoiceActionTypes.CREATE_INTERNAL_NOTE_REQUEST_FAILED,
        ]);

        if (!responseAction.error) {
            const {response} = responseAction.payload;
            const {invoiceDTO} = response;

            yield put({
                type: invoiceActionTypes.STORE_INVOICES,
                payload: {invoiceDTOs: [invoiceDTO]},
            });
        }
    }
};

export default createInternalNoteFlow;
