import {take, select, fork, put, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {alfAccountTypes, AccountParty} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import contactAccountPartyDataModalTypes from '../modals/contactAccountPartyDataModalTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';
import * as contactActionTypes from '../contactActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';

const editAccountPartyFlow = function* editAccountPartyFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryEditAccountParty = false;
    let retryEditAccountPartyData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_EDIT_ACCOUNT_PARTY_FLOW);
            payloadData = payload;
        }

        let chosenModalOption;
        const {client, accountPartyId, accountPartyType} = payloadData;
        const modalType = accountPartyType === alfAccountTypes.CREDITOR
            ? contactAccountPartyDataModalTypes.EDIT_CREDITOR
            : contactAccountPartyDataModalTypes.EDIT_DEBTOR;

        if (!shouldRetryEditAccountParty && client) {
            yield* openModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
                client,
                contactId: accountPartyId,
                type: modalType,
                accountPartyType,
            });

            chosenModalOption = yield take([
                contactActionTypes.DECLINE_EDIT_ACCOUNT_PARTY,
                contactActionTypes.CONFIRM_EDIT_ACCOUNT_PARTY,
            ]);
        }

        if (shouldRetryEditAccountParty || chosenModalOption.type === contactActionTypes.CONFIRM_EDIT_ACCOUNT_PARTY) {
            const accountPartyData = !shouldRetryEditAccountParty && chosenModalOption?.payload?.accountPartyData
                ? chosenModalOption?.payload?.accountPartyData : retryEditAccountPartyData?.accountPartyData;

            const invoiceId = !shouldRetryEditAccountParty && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryEditAccountPartyData?.invoiceId;

            const accountPartyId = accountPartyData?.id;

            yield* openModal(modalIds.LOADER, {type: modalType});

            yield fork(fetchRequest,
                contactActionTypes.UPDATE_ACCOUNT_PARTY_REQUEST,
                leaAssignmentFlowService.updateAccountParty,
                {
                    accountPartyId,
                    accountPartyData: {
                        client,
                        ...AccountParty.objectToDTO(accountPartyData),
                    },
                });

            const responseAction = yield take([
                contactActionTypes.UPDATE_ACCOUNT_PARTY_REQUEST_SUCCEEDED,
                contactActionTypes.UPDATE_ACCOUNT_PARTY_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: modalType});
            shouldRetryEditAccountParty = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {accountPartyDataDTO} = response;

                // TODO: Consider storing only id of the newly created account party
                yield put({
                    type: contactActionTypes.STORE_ACCOUNT_PARTY,
                    payload: {accountPartyDataDTO},
                });

                shouldWaitForAction = true;

                yield* closeModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
                    client,
                    contactId: accountPartyId,
                    type: modalType,
                    accountPartyType,
                });

                if (invoiceId) {
                    yield put({
                        type: invoiceActionTypes.INITIATE_CONTACT_SELECTION_FLOW,
                        payload: {
                            invoiceId,
                            client,
                            contactId: accountPartyDataDTO.id,
                            initiateContactSearch: true,
                        },
                    });
                }
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === 503) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                yield* openModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.EDIT_ACCOUNT_PARTY});

                const nextAction = yield take([
                    contactActionTypes.RETRY_EDIT_ACCOUNT_PARTY,
                    contactActionTypes.CANCEL_ACTION,
                ]);

                yield* closeModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.EDIT_ACCOUNT_PARTY});

                if (nextAction.type === contactActionTypes.RETRY_EDIT_ACCOUNT_PARTY) {
                    shouldRetryEditAccountParty = true;
                    shouldWaitForAction = false;
                    retryEditAccountPartyData = {
                        accountPartyData,
                        invoiceId,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryEditAccountParty = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        yield* closeModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
            client,
            contactId: accountPartyId,
            type: modalType,
            accountPartyType,
        });
    }
};

export default editAccountPartyFlow;
