/**
 * Command action types
 */
export const CREATE_NEW_SERVICE_CASE = '[service-cases] CREATE_SERVICE_CASE';
export const SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION = '[service-cases] SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION';
export const UPDATE_SERVICE_CASE_MANDATORY_DATA = '[service-cases] UPDATE_SERVICE_CASE_MANDATORY_DATA';
export const INITIATE_SERVICE_CASE_DELETE_FLOW = '[service-cases] INITIATE_SERVICE_CASE_DELETE_FLOW';
export const CONFIRM_SERVICE_CASE_DELETE = '[service-cases] CONFIRM_SERVICE_CASE_DELETE';
export const SEARCH_SERVICE_CASES = '[service-cases] SEARCH_SERVICE_CASES';
export const DECLINE_SERVICE_CASE_DOCUMENT_UPLOAD = '[service-cases] DECLINE_SERVICE_CASE_DOCUMENT_UPLOAD';
export const CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD = '[service-cases] CONFIRM_SERVICE_CASE_DOCUMENT_UPLOAD';
export const INITIATE_DOCUMENT_UPLOAD_FLOW = '[service-cases] INITIATE_DOCUMENT_UPLOAD_FLOW';
export const INITIATE_SERVICE_CASE_DOCUMENT_DOWNLOAD_FLOW = '[service-cases] INITIATE_SERVICE_CASE_DOCUMENT_DOWNLOAD_FLOW';
export const SUBMIT_UPLOADED_DOCUMENTS = '[service-cases] SUBMIT_UPLOADED_DOCUMENTS';
export const INITIATE_SERVICE_CASE_VEHICLE_CREATION_FLOW = '[service-cases] INITIATE_SERVICE_CASE_VEHICLE_CREATION_FLOW';
export const CONFIRM_SERVICE_CASE_VEHICLE_CREATION = '[service-cases] CONFIRM_SERVICE_CASE_VEHICLE_CREATION';
export const DECLINE_SERVICE_CASE_VEHICLE_CREATION = '[service-cases] DECLINE_SERVICE_CASE_VEHICLE_CREATION';
export const INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW = '[service-cases] INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW';
export const CONFIRM_EDIT_SERVICE_CASE_VEHICLE = '[service-cases] CONFIRM_EDIT_SERVICE_CASE_VEHICLE';
export const DECLINE_EDIT_SERVICE_CASE_VEHICLE = '[service-cases] DECLINE_EDIT_SERVICE_CASE_VEHICLE';
export const SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM = '[service-cases] SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM';
export const INITIATE_SERVICE_CASE_VEHICLE_SELECTION_FLOW = '[service-cases] INITIATE_SERVICE_CASE_VEHICLE_SELECTION_FLOW';
export const CONFIRM_SERVICE_CASE_VEHICLE_SELECTION = '[service-cases] CONFIRM_SERVICE_CASE_VEHICLE_SELECTION';
export const DECLINE_SERVICE_CASE_VEHICLE_SELECTION = '[service-cases] DECLINE_SERVICE_CASE_VEHICLE_SELECTION';
export const FETCH_VEHICLE_MANUFACTURERS = '[service-cases] FETCH_VEHICLE_MANUFACTURERS';
export const FETCH_VEHICLE_MODELS = '[service-cases] FETCH_VEHICLE_MODELS';
export const FETCH_VEHICLE_VARIANTS = '[service-cases] FETCH_VEHICLE_VARIANTS';
export const SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM = '[service-cases] SUBMIT_SERVICE_CASE_TRAILER_DATA_FORM';
export const INITIATE_SERVICE_CASE_SCREEN_REDIRECTION = '[service-cases] INITIATE_SERVICE_CASE_SCREEN_REDIRECTION';

/**
 * Event action types
 */
export const BATCH_UPDATE_SERVICE_CASE = '[service-cases] BATCH_UPDATE_SERVICE_CASE';
export const BATCH_UPDATE_SERVICE_CASE_COMPLETED = '[service-cases] BATCH_UPDATE_SERVICE_CASE_COMPLETED';

export const CREATE_NEW_SERVICE_CASE_REQUEST = '[service-cases] CREATE_NEW_SERVICE_CASE_REQUEST';
export const CREATE_NEW_SERVICE_CASE_REQUEST_SENT = '[service-cases] CREATE_NEW_SERVICE_CASE_REQUEST_SENT';
export const CREATE_NEW_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] CREATE_NEW_SERVICE_CASE_REQUEST_SUCCEEDED';
export const CREATE_NEW_SERVICE_CASE_REQUEST_FAILED = '[service-cases] CREATE_NEW_SERVICE_CASE_REQUEST_FAILED';

export const SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_REQUEST = '[service-cases] FETCH_SERVICE_CASE_REQUEST';
export const FETCH_SERVICE_CASE_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_REQUEST_SENT';
export const FETCH_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_REQUEST_FAILED';

export const UPDATE_SERVICE_CASE_REQUEST = '[service-cases] UPDATE_SERVICE_CASE_REQUEST';
export const UPDATE_SERVICE_CASE_REQUEST_SENT = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_SENT';
export const UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_SUCCEEDED';
export const UPDATE_SERVICE_CASE_REQUEST_FAILED = '[service-cases] UPDATE_SERVICE_CASE_REQUEST_FAILED';

export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SENT';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_SUCCEEDED';
export const FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED = '[service-cases] FETCH_RESIDENCE_TO_DAMAGE_LOCATION_DISTANCE_REQUEST_FAILED';

export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST = '[service-cases] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SENT';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_MEMBER_ADDRESS_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_INVOICES_REQUEST = '[service-cases] FETCH_SERVICE_CASE_INVOICES_REQUEST';
export const FETCH_SERVICE_CASE_INVOICES_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_INVOICES_REQUEST_SENT';
export const FETCH_SERVICE_CASE_INVOICES_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_INVOICES_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_INVOICES_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_INVOICES_REQUEST_FAILED';

export const DELETE_SERVICE_CASE_REQUEST = '[service-cases] DELETE_SERVICE_CASE_REQUEST';
export const DELETE_SERVICE_CASE_REQUEST_SENT = '[service-cases] DELETE_SERVICE_CASE_REQUEST_SENT';
export const DELETE_SERVICE_CASE_REQUEST_SUCCEEDED = '[service-cases] DELETE_SERVICE_CASE_REQUEST_SUCCEEDED';
export const DELETE_SERVICE_CASE_REQUEST_FAILED = '[service-cases] DELETE_SERVICE_CASE_REQUEST_FAILED';

export const SEARCH_SERVICE_CASES_REQUEST = '[service-cases] SEARCH_SERVICE_CASES_REQUEST';
export const SEARCH_SERVICE_CASES_REQUEST_SENT = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_SENT';
export const SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_SUCCEEDED';
export const SEARCH_SERVICE_CASES_REQUEST_FAILED = '[service-cases] SEARCH_SERVICE_CASES_REQUEST_FAILED';

export const DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST = '[service-cases] DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST';
export const DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_SENT = '[service-cases] DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_SUCCEEDED = '[service-cases] DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_FAILED = '[service-cases] DOWNLOAD_SERVICE_CASE_DOCUMENT_REQUEST_FAILED';

export const FETCH_SERVICE_CASE_DOCUMENTS_REQUEST = '[service-cases] FETCH_SERVICE_CASE_DOCUMENTS_REQUEST';
export const FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SENT = '[service-cases] FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SENT';
export const FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SUCCEEDED';
export const FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_FAILED = '[service-cases] FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_FAILED';

export const FETCH_VEHICLE_MANUFACTURERS_REQUEST = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_SENT';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_MANUFACTURERS_REQUEST_FAILED';

export const FETCH_VEHICLE_MODELS_REQUEST = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST';
export const FETCH_VEHICLE_MODELS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_SENT';
export const FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_MODELS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_MODELS_REQUEST_FAILED';

export const FETCH_VEHICLE_VARIANTS_REQUEST = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST';
export const FETCH_VEHICLE_VARIANTS_REQUEST_SENT = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_SENT';
export const FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_SUCCEEDED';
export const FETCH_VEHICLE_VARIANTS_REQUEST_FAILED = '[service-cases] FETCH_VEHICLE_VARIANTS_REQUEST_FAILED';

/**
 * Store action types
 */
export const STORE_SERVICE_CASES = '[service-cases] STORE_SERVICE_CASES';
export const SET_SERVICE_CASE_DAMAGE_LOCATION_CANDIDATES = '[service-cases] SET_SERVICE_CASE_DAMAGE_LOCATION_CANDIDATES';
export const SET_SERVICE_CASE_PERSISTENCE_STATE = '[service-cases] SET_SERVICE_CASE_PERSISTENCE_STATE';
export const SET_INVOICES = '[service-cases] SET_INVOICES';
export const STORE_SERVICE_CASE_SEARCH_RESULTS = '[service-cases] STORE_SERVICE_CASE_SEARCH_RESULTS';
export const STORE_SERVICE_CASE_DOCUMENTS = '[service-cases] STORE_SERVICE_CASE_DOCUMENTS';
export const STORE_VEHICLE_MANUFACTURERS = '[service-cases] STORE_VEHICLE_MANUFACTURERS';
export const STORE_VEHICLE_MODELS = '[service-cases] STORE_VEHICLE_MODELS';
export const STORE_VEHICLE_VARIANTS = '[service-cases] STORE_VEHICLE_VARIANTS';
export const STORE_ACCOUNT_PARTY_OPTIONS_IDS = '[service-cases] STORE_ACCOUNT_PARTY_OPTIONS_IDS';
