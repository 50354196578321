import {select, call, fork, take, put} from 'redux-saga/effects';
import {alfRelevantLocationTypes} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const relevantLocationsCombinations = {
    [alfRelevantLocationTypes.DAMAGE]: [
        alfRelevantLocationTypes.ACE_PARTNER,
        alfRelevantLocationTypes.MEMBER,
        alfRelevantLocationTypes.TOWING,
        alfRelevantLocationTypes.FINAL_TOWING,
    ],
    [alfRelevantLocationTypes.SERVICE]: [
        alfRelevantLocationTypes.ACE_PARTNER,
        alfRelevantLocationTypes.MEMBER,
        alfRelevantLocationTypes.TOWING,
        alfRelevantLocationTypes.FINAL_TOWING,
    ],
    [alfRelevantLocationTypes.TOWING]: [alfRelevantLocationTypes.MEMBER, alfRelevantLocationTypes.ACE_PARTNER],
    [alfRelevantLocationTypes.FINAL_TOWING]: [alfRelevantLocationTypes.MEMBER, alfRelevantLocationTypes.ACE_PARTNER],
    [alfRelevantLocationTypes.ACE_PARTNER]: [alfRelevantLocationTypes.MEMBER],
};

const combinePins = (locations, relevantLocationType, alternativeTypeName) => {
    if (!locations || !relevantLocationType) return null;
    const referentialPoint = locations.find(location => relevantLocationType === location.type);
    if (!referentialPoint) return null;
    const combinedWith = [];
    const combinedLocations = [];
    locations.forEach(location => {
        if (relevantLocationsCombinations[referentialPoint.type].includes(location.type)
            && referentialPoint.coordinates?.longitude === location.coordinates?.longitude
            && referentialPoint.coordinates?.latitude === location.coordinates?.latitude
        ) {
            combinedWith.push(location.type);
            combinedLocations.push({
                ...referentialPoint,
                type: `COMBINED_${alternativeTypeName || relevantLocationType}_AND_${location.type}`,
            });
        }
    });
    if (combinedWith.length > 0) {
        combinedWith.push(relevantLocationType);
        return {
            combinedWith,
            combinedLocations,
        };
    }
    return null;
};

const loadInvoiceRelevantLocations = function* loadRelevantInvoiceLocatins({payload}) {
    const {match} = payload;
    const {invoiceId} = match.params;

    const {serviceManager} = yield select(state => state.application);
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const {invoices} = yield select(state => state.invoices);
    const {serviceCases} = yield select(state => state.serviceCases);
    const invoice = invoices[invoiceId];
    const serviceCase = serviceCases[invoice.serviceCaseId];
    const {member, damage} = serviceCase;
    const arcGISMap = yield call(arcGISMapService.getMap, 'invoice-relevant-locations-map');
    const {locations, lines} = invoice;
    const additionalLocationDetails = {};
    const destinationLocations = lines.map(line => {
        return line.destination;
    }).filter(destinationLocation => !!destinationLocation);

    let hasPolygon = false;

    if (!invoice || !arcGISMap) return;

    const invoiceRelevantLocationsServiceAreas = yield call(arcGISMap.getLayer, 'invoice-relevant-locations-service-areas');
    const spacialRelevantLocations = locations.length > 0
        ? [...locations, ...destinationLocations].filter(location => location.type !== alfRelevantLocationTypes.MEMBER)
        : [
            {
                address: member.personalDetails?.address || null,
                coordinates: member.personalDetails?.coordinates || null,
                type: alfRelevantLocationTypes.MEMBER,
            },
            ...(damage && damage.location ? [{
                address: damage.location.address || null,
                coordinates: damage.location.coordinates || null,
                type: alfRelevantLocationTypes.DAMAGE,
            }] : []),
        ];
    const latitudes = spacialRelevantLocations.map(location => {
        return location.coordinates?.latitude;
    });
    const longitudes = spacialRelevantLocations.map(location => {
        return location.coordinates?.longitude;
    });

    const spatialReference = invoiceRelevantLocationsServiceAreas.getServiceFeatureLayer().spatialReference;
    const sortedLatitudes = latitudes.sort((a, b) => a - b);
    const sortedLongitudes = longitudes.sort((a, b) => a - b);
    additionalLocationDetails.relevantLocationMapExtent = arcGISMap.createMapExtentFromLocations(
        sortedLongitudes[0],
        sortedLatitudes[0],
        sortedLongitudes[sortedLongitudes.length - 1],
        sortedLatitudes[sortedLatitudes.length - 1],
        spatialReference,
    );

    if (locations.length === 0) {
        const memberAndDamageLocations = [
            {
                address: member.personalDetails?.address || null,
                coordinates: member.personalDetails?.coordinates || null,
                type: alfRelevantLocationTypes.MEMBER,
            },
            ...(damage ? [{
                address: damage.location?.address || null,
                coordinates: damage.location?.coordinates || null,
                type: alfRelevantLocationTypes.DAMAGE,
            }] : []),
        ];
        const combinedDamageLocations = combinePins(memberAndDamageLocations, alfRelevantLocationTypes.DAMAGE);

        yield put({
            type: invoiceActionTypes.SET_MEMBER_LOCATION,
            payload: {
                invoiceId: invoiceId,
                memberLocations: combinedDamageLocations
                    ? combinedDamageLocations.combinedLocations
                    : memberAndDamageLocations,
            },
        });
        yield put({
            type: invoiceActionTypes.STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS,
            payload: {invoiceId, additionalLocationDetails},
        });
        return;
    }

    const providerLocation = locations.find(location => location.type === alfRelevantLocationTypes.ACE_PARTNER);
    const towingLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.FINAL_TOWING
            || location.type === alfRelevantLocationTypes.TOWING;
    });
    const damageLocation = locations.find(location => {
        return location.type === alfRelevantLocationTypes.DAMAGE
            || location.type === alfRelevantLocationTypes.SERVICE;
    });

    // Calculate round trip of provider
    if (providerLocation) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST,
            arcGISRESTService.getMultipleStopsRoute,
            {
                stops: [
                    [providerLocation.coordinates.longitude, providerLocation.coordinates.latitude],
                    ...(damageLocation
                        ? [[damageLocation.coordinates.longitude, damageLocation.coordinates.latitude]]
                        : []
                    ),
                    ...(towingLocation
                        ? [[towingLocation.coordinates.longitude, towingLocation.coordinates.latitude]]
                        : []
                    ),
                    [providerLocation.coordinates.longitude, providerLocation.coordinates.latitude],
                ],
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            },
        );

        const routeCalculationActionResponse = yield take([
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SUCCEEDED,
            invoiceActionTypes.GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_FAILED,
        ]);

        if (!routeCalculationActionResponse.error) {
            const {response} = routeCalculationActionResponse.payload;
            const {arcGISRouteDTO} = response;
            additionalLocationDetails.providerDamageTowingProviderDistance = arcGISRouteDTO.totalKilometers;
        }
    }

    if (damageLocation && providerLocation?.externalId) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST,
            invoiceRelevantLocationsServiceAreas.filterFeaturesByAttribute,
            {
                referentialPoint: damageLocation.coordinates,
                where: `contractPa = '${providerLocation.externalId}'`,
                travelMode: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_FAILED,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            const {featureDTOs} = response;

            if (featureDTOs.length > 0) {
                hasPolygon = true;
                additionalLocationDetails.damageLocationWithinArea = featureDTOs[0].containsDamageLocation;
            }
        }
    }
    if (towingLocation && providerLocation?.externalId) {
        yield fork(
            fetchRequest,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST,
            invoiceRelevantLocationsServiceAreas.filterFeaturesByAttribute,
            {
                referentialPoint: towingLocation.coordinates,
                where: `contractPa = '${providerLocation.externalId}'`,
                travelMode: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_FAILED,
            invoiceActionTypes.FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            const {featureDTOs} = response;

            if (featureDTOs.length > 0) {
                hasPolygon = true;
                additionalLocationDetails.towingDestinationWithinArea = featureDTOs[0].containsDamageLocation;
            }
        }
    }

    if (hasPolygon) {
        yield call(invoiceRelevantLocationsServiceAreas.selectFeatureByAttribute, {
            where: `contractPa = '${providerLocation.externalId}'`,
        });
        invoiceRelevantLocationsServiceAreas.show();
    }

    const locationsPool = !locations.find(location => location.type === alfRelevantLocationTypes.MEMBER)
        ? [
            ...locations,
            {
                id: member.id,
                address: member.personalDetails?.address || null,
                coordinates: member.personalDetails?.coordinates || null,
                type: alfRelevantLocationTypes.MEMBER,
            },
        ]
        : [...locations];

    const combinedDamageLocations = combinePins(locationsPool, alfRelevantLocationTypes.DAMAGE);
    const combinedServiceLocations = combinePins(
        locationsPool,
        alfRelevantLocationTypes.SERVICE,
        alfRelevantLocationTypes.DAMAGE,
    );
    const combinedTowingLocations = combinePins(locationsPool, alfRelevantLocationTypes.TOWING);
    const combinedFinalTowingLocations = combinePins(
        locationsPool,
        alfRelevantLocationTypes.FINAL_TOWING,
        alfRelevantLocationTypes.TOWING,
    );
    const combinedAcePartnerLocations = combinePins(locationsPool, alfRelevantLocationTypes.ACE_PARTNER);

    const combinedAndRegularLocations = [...locationsPool, ...destinationLocations,
        ...(combinedDamageLocations ? combinedDamageLocations.combinedLocations : []),
        ...(combinedServiceLocations ? combinedServiceLocations.combinedLocations : []),
        ...(combinedTowingLocations ? combinedTowingLocations.combinedLocations : []),
        ...(combinedFinalTowingLocations ? combinedFinalTowingLocations.combinedLocations : []),
        ...(combinedAcePartnerLocations ? combinedAcePartnerLocations.combinedLocations : [])];

    const combinedExcludedPins = [
        ...(combinedDamageLocations ? combinedDamageLocations.combinedWith : []),
        ...(combinedServiceLocations ? combinedServiceLocations.combinedWith : []),
        ...(combinedTowingLocations ? combinedTowingLocations.combinedWith : []),
        ...(combinedFinalTowingLocations ? combinedFinalTowingLocations.combinedWith : []),
        ...(combinedAcePartnerLocations ? combinedAcePartnerLocations.combinedWith : []),
    ];

    // If we merged some pins or if the member location was missing
    if (combinedExcludedPins.length > 0 || combinedAndRegularLocations.length > locations.length) {
        yield put({
            type: invoiceActionTypes.SET_MEMBER_LOCATION,
            payload: {
                invoiceId: invoiceId,
                memberLocations: combinedAndRegularLocations.filter(location => {
                    return !combinedExcludedPins.includes(location.type);
                }),
            },
        });
    }

    yield put({
        type: invoiceActionTypes.STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS,
        payload: {invoiceId, additionalLocationDetails},
    });
};

export default loadInvoiceRelevantLocations;
