import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import scOverviewScreenTabs from '../scOverviewScreenTabs';

const loadServiceCaseDocuments = function* loadServiceCaseDocuments({payload}) {
    const {match, location} = payload;
    const {serviceCaseId} = match.params;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!serviceCaseId || location.query.activeTab !== scOverviewScreenTabs.DOCUMENT) return;

    yield fork(
        fetchRequest,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST,
        leaAssignmentFlowService.getServiceCaseDocuments,
        {serviceCaseId},
    );

    const responseAction = yield take([
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_SUCCEEDED,
        serviceCaseActionTypes.FETCH_SERVICE_CASE_DOCUMENTS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {serviceCaseDocumentsDTOs} = responseAction.payload.response;

        yield put({
            type: serviceCaseActionTypes.STORE_SERVICE_CASE_DOCUMENTS,
            payload: {
                serviceCaseDocumentsDTOs,
            },
        });
    }
};

export default loadServiceCaseDocuments;
