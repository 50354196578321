import React, {useState, Fragment, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, prepareSearchQueryParams} from '@computerrock/formation-router';
import {Modal, useStyles, DataRow, AutocompleteField, Button} from '@ace-de/ui-components';
import {eupUserRoleTypes, etmLeistTaskCategoryTypes, etmTaskTypes, etmTaskStatusTypes} from '@ace-de/eua-entity-types';
import {SelectField, Option, TextAreaField, Checkbox, Form, ButtonPrimary, TimeField, DateField} from '@ace-de/ui-components/form';
import {InteractiveIcon, Icon, calendarIcon, closeIcon, linkIcon, saveIcon, memberIcon, checkmarkIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import routePaths from '../../routePaths';
import * as taskActionTypes from '../taskActionTypes';
import config from '../../config';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as taskSelectors from '../taskSelectors';
import taskDataModalTypes from './taskDataModalTypes';
import scOverviewScreenTabs from '../../service-cases/scOverviewScreenTabs';

const assignedTypes = {
    ROLE: 'ROLE',
    USER: 'USER',
};

const TaskDataModal = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('task_data_modal');
    const {hasBackdrop, serviceCase, leaUsers, invoice, location, task} = props;
    const {declineTaskCreation, confirmTaskCreation, declineTaskEdit, confirmTaskEdit, completeTask} = props;
    const [taskData, setTaskData] = useState({
        ...(task && {...task}),
        assigneeType: task
            ? task.assignedToRole
                ? assignedTypes.ROLE
                : assignedTypes.USER
            : '',
        dueTime: task?.dueDate ? moment(task.dueDate).format('HH:mm') : '',
    });
    const [description, setDescription] = useState(task?.description || '');
    const isDueDateSet = useRef(false);
    const assignedToOptions = {
        [assignedTypes.ROLE]: [eupUserRoleTypes.AUDITOR],
        [assignedTypes.USER]: Object.values(leaUsers),
    };
    const modalType = location?.query?.type;

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') {
            if (modalType === taskDataModalTypes.CREATE_TASK) {
                declineTaskCreation();
                return;
            }
            declineTaskEdit();
        }
    }, [declineTaskCreation, declineTaskEdit, modalType]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    useEffect(() => {
        if (taskData?.immediatelyDue && isDueDateSet.current === false) {
            setTaskData({
                ...taskData,
                dueDate: moment().add(5, 'minutes'),
                dueTime: moment().add(5 - (moment().minute() % 5), 'minutes').format('HH:mm'),
            });
            isDueDateSet.current = true;
        } else {
            isDueDateSet.current = false;
        }
    }, [taskData]);

    const redirectToServiceCaseOverview = serviceCaseId => {
        if (serviceCaseId) {
            const {pathname, search} = resolveRoute(routePaths.SERVICE_CASES_OVERVIEW,
                {serviceCaseId: serviceCaseId},
                {search: prepareSearchQueryParams({activeTab: scOverviewScreenTabs.OVERVIEW})});
            window.open(pathname + search, '_blank');
        }
    };

    const handleOnChange = formValues => {
        if (!formValues || isDueDateSet.current) return;

        setTaskData({
            ...formValues,
            assignedToRole: formValues.assigneeType
                ? formValues.assigneeType === assignedTypes.ROLE
                : taskData?.assignedToRole,
            assignedTo: taskData?.assigneeType && formValues.assigneeType !== taskData?.assigneeType
                ? ''
                : formValues.assignedTo,
        });
    };

    const handleOnSubmit = formValues => {
        const {category, assignedTo, dueDate, dueTime, assigneeType, immediatelyDue} = formValues;

        const taskData = {
            assignedTo,
            category,
            description,
            dueDate: immediatelyDue
                ? dueDate
                : moment(`${moment(dueDate).format('YYYY-MM-DD')}T${dueTime}`),
            assignedCaseId: serviceCase?.id || task?.assignedCaseId,
            memberName: serviceCase?.member.personalDetails.displayName || task?.memberName,
            assignedToRole: assigneeType === assignedTypes.ROLE,
            assignedToUser: (assigneeType === assignedTypes.USER && assignedTo) ? assignedTo : null,
            taskType: etmTaskTypes.LEA,
            ...(invoice && {invoiceId: invoice.id}),
        };

        if (modalType === taskDataModalTypes.EDIT_TASK) confirmTaskEdit({taskData});

        confirmTaskCreation({taskData});
    };

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={modalType === taskDataModalTypes.CREATE_TASK ? declineTaskCreation : declineTaskEdit}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            title={translateModal(`modal_title.${modalType.toLowerCase()}`)}
            contentClassName={cx('global!ace-u-modal-content-size--m')}
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateModal('task_data_modal_row_label.service_case_id')}
                        qaIdent="service-case-id"
                    >
                        {(serviceCase?.id || task?.assignedCaseId) && (
                            <InteractiveIcon
                                className={cx([
                                    'ace-c-interactive-icon--highlight',
                                    'ace-c-interactive-icon--primary',
                                ])}
                                icon={linkIcon}
                                onClick={() => redirectToServiceCaseOverview(serviceCase?.id || task.assignedCaseId)}
                            >
                                { serviceCase?.id
                                    ? `${serviceCase.prefix}-${serviceCase.id}`
                                    : task.assignedCaseId}
                            </InteractiveIcon>
                        )}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateModal('task_data_modal_row_label.member')}
                        qaIdent="member"
                    >
                        {serviceCase?.member?.personalDetails?.displayName || task?.memberName || '-'}
                    </DataRow>
                </div>
            </div>
            <div className={cx('global!ace-u-full-width')}>
                <Form name="taskDataForm" onChange={handleOnChange} onSubmit={handleOnSubmit}>
                    {formValues => {
                        return (
                            <Fragment>
                                <div className={cx('global!ace-u-two-column-layout', 'global!ace-u-margin--top-32')}>
                                    <div>
                                        <SelectField
                                            name="category"
                                            value={taskData?.category || ''}
                                            label={`${translateModal('select_field_label.category')}*`}
                                            placeholder={translate('global.select.placeholder')}
                                            className={cx('global!ace-u-full-width')}
                                        >
                                            {Object.values(etmLeistTaskCategoryTypes).map(categoryType => (
                                                <Option
                                                    key={categoryType}
                                                    name={categoryType}
                                                    value={categoryType}
                                                >
                                                    {translate(`global.task_category.${categoryType.toLowerCase()}`)}
                                                </Option>
                                            ))}
                                        </SelectField>
                                        <div className={cx('global!ace-u-margin--top-48')}>
                                            <div className={cx('global!ace-u-grid')}>
                                                <SelectField
                                                    name="assigneeType"
                                                    value={taskData?.assigneeType || ''}
                                                    label={`${translateModal('input_label.assignee_type')}*`}
                                                    placeholder={translate('global.select.placeholder')}
                                                    className={cx('global!ace-u-full-width', 'global!ace-u-grid-column--span-6')}
                                                >
                                                    <Option
                                                        key={assignedTypes.ROLE}
                                                        name="assigneeTypeOptionRole"
                                                        value={assignedTypes.ROLE}
                                                    >
                                                        {translateModal('assignee_type_input_option.role')}
                                                    </Option>
                                                    <Option
                                                        key={assignedTypes.USER}
                                                        name="assigneeTypeOptionUser"
                                                        value={assignedTypes.USER}
                                                    >
                                                        {translateModal('assignee_type_input_option.user')}
                                                    </Option>
                                                </SelectField>
                                                <AutocompleteField
                                                    name="assignedTo"
                                                    value={taskData?.assignedTo || ''}
                                                    placeholder={translate('global.select.placeholder')}
                                                    className={cx('global!ace-u-grid-column--span-6', 'global!ace-u-flex--align-self-flex-end')}
                                                    icon={memberIcon}
                                                >
                                                    {assignedToOptions[formValues.assigneeType]
                                                        ? assignedToOptions[formValues.assigneeType]
                                                            .map((option, index) => (
                                                                <Option
                                                                    key={`${option}-${index}`}
                                                                    name={`${option}-${index}`}
                                                                    value={formValues.assigneeType === 'ROLE' ? option : option.id}
                                                                >
                                                                    {formValues.assigneeType === 'ROLE'
                                                                        ? translate(`global.user_shift_role.${option.toLowerCase()}`)
                                                                        : option.displayName || option.userName || ''}
                                                                </Option>
                                                            )) : null}
                                                </AutocompleteField>
                                            </div>
                                            <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-48')}>
                                                <DateField
                                                    name="dueDate"
                                                    className={cx('global!ace-u-full-width', 'global!ace-u-grid-column--span-6')}
                                                    label={`${translateModal('input_label.due_date')}*`}
                                                    value={taskData?.dueDate || ''}
                                                    icon={calendarIcon}
                                                    locale={activeLocale}
                                                    isDisabled={formValues.immediatelyDue}
                                                />
                                                <TimeField
                                                    name="dueTime"
                                                    label={`${translateModal('input_label.due_time')}*`}
                                                    className={cx('global!ace-u-full-width', 'global!ace-u-grid-column--span-6')}
                                                    value={taskData?.dueTime || ''}
                                                    isDisabled={formValues.immediatelyDue}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-margin--top-24',
                                                'global!ace-u-width--full',
                                                'global!ace-u-flex--justify-space-between',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        />
                                    </div>
                                    <div>
                                        <TextAreaField
                                            name="description"
                                            label={`${translateModal('text_area_label.description')}*`}
                                            placeholder={translateModal('input_placeholder.description')}
                                            className={cx('global!ace-u-full-width')}
                                            value={description || ''}
                                            onChange={setDescription}
                                            maxLength={config.TASK_DESCRIPTION_MAX_LENGTH}
                                            isResizable={false}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx([
                                        'global!ace-u-two-column-layout',
                                    ])}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-margin--top-24',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-flex--align-center',
                                        ])}
                                    >
                                        <Checkbox
                                            name="immediatelyDue"
                                            value={true}
                                        >
                                            {translateModal('input_label.immediately_due')}
                                        </Checkbox>
                                        {task && (
                                            <p className={cx('global!ace-u-typography--variant-body')}>
                                                {translateModal('information_text.last_update', {updatedAt: moment(task.updatedAt).format('DD.MM.YYYY'),
                                                    updatedBy: leaUsers[task.updatedBy]
                                                        ? `${leaUsers[task.updatedBy]?.firstName || ''} ${leaUsers[task.updatedBy]?.lastName || ''}`
                                                        : '-'})
                                                }
                                            </p>
                                        )}
                                    </div>
                                    <div
                                        className={cx([
                                            'global!ace-u-margin--top-24',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-flex-end',
                                        ])}
                                    >
                                        {(task && task.status !== etmTaskStatusTypes.DONE) && (
                                            <Button
                                                name="completeTask"
                                                onClick={completeTask}
                                            >
                                                <Icon
                                                    className={cx([
                                                        'ace-c-icon--color-highlight',
                                                        'global!ace-u-margin--right-8',
                                                    ])}
                                                    icon={checkmarkIcon}
                                                />
                                                {translateModal('button_label.complete')}
                                            </Button>
                                        )}
                                        <ButtonPrimary
                                            name="createTask"
                                            type="submit"
                                            className={cx('global!ace-u-margin--left-24')}
                                            isDisabled={!taskData?.category || !taskData?.dueDate || !taskData?.dueTime
                                            || !description || !(taskData?.assignedTo && taskData?.assigneeType)}
                                        >
                                            <Icon
                                                icon={saveIcon}
                                                className={cx([
                                                    'global!ace-c-icon--color-contrast',
                                                    'global!ace-u-margin--right-8',
                                                ])}
                                            />
                                            {translateModal('button_label.save')}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </Form>
            </div>
        </Modal>
    );
};

TaskDataModal.propTypes = {
    serviceCase: PropTypes.object,
    leaUsers: PropTypes.object,
    invoice: PropTypes.object,
    hasBackdrop: PropTypes.bool,
    declineTaskCreation: PropTypes.func.isRequired,
    confirmTaskCreation: PropTypes.func.isRequired,
    declineTaskEdit: PropTypes.func.isRequired,
    confirmTaskEdit: PropTypes.func.isRequired,
    completeTask: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    task: PropTypes.object,
};

TaskDataModal.defaultProps = {
    serviceCase: null,
    leaUsers: null,
    invoice: null,
    hasBackdrop: false,
    task: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getTask = taskSelectors.createQueryParamsTaskSelector();

    return {
        serviceCase: getServiceCase(state, props),
        leaUsers: userProfileSelectors.getLeaUsers(state),
        invoice: getInvoice(state, props),
        task: getTask(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    declineTaskCreation: payload => dispatch({
        type: taskActionTypes.DECLINE_TASK_CREATION,
        payload,
    }),
    confirmTaskCreation: payload => dispatch({
        type: taskActionTypes.CONFIRM_TASK_CREATION,
        payload,
    }),
    declineTaskEdit: payload => dispatch({
        type: taskActionTypes.DECLINE_EDIT_TASK,
        payload,
    }),
    confirmTaskEdit: payload => dispatch({
        type: taskActionTypes.CONFIRM_EDIT_TASK,
        payload,
    }),
    completeTask: () => dispatch({
        type: taskActionTypes.COMPLETE_TASK,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDataModal);
