import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {camelCase, snakeCase} from 'change-case';
import {resolveRoute, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {etmLeistTaskCategoryTypes, etmTaskStatusTypes, eupUserRoleTypes} from '@ace-de/eua-entity-types';
import {ButtonIcon, NoResultsBlock, Paginator, Panel, Pill, useStyles} from '@ace-de/ui-components';
import {Form, InputField, SelectField, Option, Checkbox, CheckboxGroup} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {searchIcon, findCaseIcon, resetIcon, InteractiveIcon, Icon} from '@ace-de/ui-components/icons';
import * as userProfileSelectors from '../user-profiles/userProfileSelectors';
import config from '../config';
import routePaths from '../routePaths';
import * as taskActionTypes from './taskActionTypes';
import {leaFeatures, leaFeatureActions} from '../application/leaFeatures';
import leaAccessControl from '../leaAccessControl';
import {pillVariants} from './taskPillVariants';

const initialTaskFilteringParams = {
    searchTerm: '',
    category: [],
    assignedToRole: '',
    assignedToUser: [],
    status: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
};

const TaskSearchScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('task_search_screen');
    const {taskSearchResults, users, history, initiateTaskEdit, taskSearchResultsCount} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState({
        searchTerm: queryParams.get('searchTerm') || initialTaskFilteringParams.searchTerm,
        category: queryParams.getAll('category').length > 0
            ? queryParams.getAll('category')[0].split(',')
            : initialTaskFilteringParams.category,
        assignedToRole: queryParams.getAll('assignedTo')[0]?.split(',').find(assignedToParam => (
            [eupUserRoleTypes.AUDITOR].includes(assignedToParam)
        )) || initialTaskFilteringParams.assignedToRole,
        assignedToUser: queryParams.getAll('assignedTo')[0]?.split(',').filter(assignedToQueryParam => (
            ![eupUserRoleTypes.AUDITOR].includes(assignedToQueryParam)
        )) || initialTaskFilteringParams.assignedToUser,
        status: queryParams.getAll('status').length > 0
            ? queryParams.getAll('status')
            : initialTaskFilteringParams.status,
    });
    const paginatorCount = Math.ceil(taskSearchResultsCount / config.DEFAULT_PAGE_SIZE);
    const didComponentMountRef = useRef(true);
    const isTaskEditingAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.TASKS,
        leaFeatureActions.UPDATE,
    );

    useEffect(() => {
        if (didComponentMountRef.current && !queryParams.get('status')) {
            queryParams.append('status', etmTaskStatusTypes.OVERDUE);
            queryParams.append('status', etmTaskStatusTypes.DUE);

            history.replace(resolveRoute(routePaths.TASKS_SEARCH, {}, {search: queryParams.toString()}));
            didComponentMountRef.current = false;
        }
    });

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const apiQueryParams = new URLSearchParams();
        const assignedToValues = [];
        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                if (formField !== 'status' && formField !== 'assignedToUser' && formField !== 'assignedToRole' && formField !== 'category') {
                    apiQueryParams.append(`${formField}`, formData[formField]);
                }

                if (formField === 'status' && Array.isArray(formData[formField]) && formData[formField].length > 0) {
                    formData[formField].forEach(taskStatus => {
                        if (taskStatus !== '') apiQueryParams.append('status', taskStatus);
                    });
                }

                if (formField === 'category' && Array.isArray(formData[formField]) && formData[formField].length > 0) {
                    formData[formField].forEach(category => {
                        if (category !== '') apiQueryParams.append('category', category);
                    });
                }

                if (formField === 'assignedToUser' && formData[formField].length > 0) {
                    formData[formField].forEach(formValue => {
                        assignedToValues.push(formValue);
                    });
                }

                if (formField === 'assignedToRole' && !!formData[formField]) {
                    assignedToValues.push(formData[formField]);
                }
            }
        });

        if (assignedToValues.length > 0) apiQueryParams.append('assignedTo', assignedToValues);
        return apiQueryParams;
    };

    const handleOnChange = (key, value) => {
        setFilterFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleOnSubmit = () => {
        if (!filterFormData) {
            return;
        }
        const apiQueryParams = formatQueryParams(filterFormData);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.push(resolveRoute(routePaths.TASKS_SEARCH, {}, {search: queryParamsString}));
    };

    const handleOnKeyDown = event => {
        if (event.key !== 'Enter' || (filterFormData.searchTerm.length > 0 && filterFormData.searchTerm.length < config.MINIMUM_SEARCH_QUERY_LENGTH)) return;
        event.preventDefault();
        handleOnSubmit();
    };

    const handleResetFilter = () => {
        setFilterFormData(initialTaskFilteringParams);
        const apiQueryParams = formatQueryParams(initialTaskFilteringParams);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(routePaths.TASKS_SEARCH, {}, {search: queryParamsString}));
    };

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(routePaths.TASKS_SEARCH, {}, {search: queryParamsString}));
    };

    return (
        <Panel
            title={(
                <p className={cx('global!ace-u-margin--24')}>
                    {translateScreen('panel_title.tasks')}
                </p>
            )}
            className={cx('ace-c-panel--full-bleed')}
        >
            <div className={cx('global!ace-u-margin--0-24-24')}>
                <Form name="taskSearchForm">
                    <div
                        className={cx([
                            'global!ace-u-margin--top-24',
                            'global!ace-u-grid',
                        ])}
                        onKeyDown={handleOnKeyDown}
                    >
                        <InputField
                            name="searchTerm"
                            className={cx('global!ace-u-grid-column--span-5')}
                            label={translateScreen('input_field_label.free_text_search')}
                            value={filterFormData.searchTerm}
                            onChange={value => handleOnChange('searchTerm', value)}
                        />
                        <SelectField
                            name="category"
                            label={translateScreen('select_field_label.category')}
                            className={cx('global!ace-u-grid-column--span-2')}
                            isMultipleChoice={true}
                            value={filterFormData.category}
                            onChange={value => handleOnChange('category', value)}
                        >
                            {Object.keys(etmLeistTaskCategoryTypes).map(etmTaskCategoryType => {
                                return (
                                    <Option
                                        name={`option${camelCase(etmTaskCategoryType)}`}
                                        value={etmTaskCategoryType}
                                        key={etmTaskCategoryType}
                                    >
                                        {translate(`global.task_category.${etmTaskCategoryType.toLowerCase()}`)}
                                    </Option>
                                );
                            })}
                        </SelectField>
                        <SelectField
                            name="assignedToRole"
                            label={translateScreen('select_field_label.assigned_to_role')}
                            className={cx('global!ace-u-grid-column--span-2')}
                            value={filterFormData.assignedToRole}
                            onChange={value => handleOnChange('assignedToRole', value)}
                        >
                            {[eupUserRoleTypes.AUDITOR].map(eupUserRoleType => {
                                return (
                                    <Option
                                        name={`option${camelCase(eupUserRoleType)}`}
                                        value={eupUserRoleType}
                                        key={eupUserRoleType}
                                    >
                                        {translate(`global.user_shift_role.${eupUserRoleType.toLowerCase()}`)}
                                    </Option>
                                );
                            })}
                        </SelectField>
                        <SelectField
                            name="assignedToUser"
                            label={translateScreen('select_field_label.assigned_to_user')}
                            className={cx('global!ace-u-grid-column--span-2')}
                            isMultipleChoice={true}
                            value={filterFormData.assignedToUser}
                            onChange={value => handleOnChange('assignedToUser', value)}
                        >
                            {Object.values(users).map(user => {
                                return (
                                    <Option
                                        name={`option-${user.id}`}
                                        value={user.id}
                                        key={user.id}
                                    >
                                        {`${user.firstName} ${user.lastName}`}
                                    </Option>
                                );
                            })}
                        </SelectField>
                        <div className={cx('global!ace-u-grid-column--span-1', 'global!ace-u-margin--top-24')}>
                            <ButtonIcon
                                name="searchButton"
                                onClick={handleOnSubmit}
                                icon={searchIcon}
                                className={cx([
                                    'global!ace-u-flex--basis-5',
                                    'global!ace-u-width--64',
                                    'global!ace-u-height--48',
                                ])}
                                isDisabled={filterFormData.searchTerm.length > 0
                                    && filterFormData.searchTerm.length < config.MINIMUM_SEARCH_QUERY_LENGTH}
                            />
                        </div>
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-margin--top-32',
                        ])}
                    >
                        <CheckboxGroup
                            name="status"
                            value={filterFormData.status}
                            onChange={value => handleOnChange('status', value)}
                        >
                            {Object.keys(etmTaskStatusTypes).map((statusType, idx) => (
                                <Checkbox
                                    className={cx({
                                        'global!ace-u-margin--right-48': idx !== Object.keys(etmTaskStatusTypes).length - 1,
                                    })}
                                    key={statusType}
                                    name={statusType}
                                    value={statusType}
                                >
                                    {translate(`global.task_status.${snakeCase(statusType)}`)}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                        <InteractiveIcon
                            icon={resetIcon}
                            className={cx([
                                'ace-c-interactive-icon--reverse',
                                'ace-c-interactive-icon--highlight',
                                'global!ace-u-margin--0-32-0-24',
                            ])}
                            onClick={handleResetFilter}
                        >
                            {translateScreen('interactive_icon_label.reset_filter')}
                        </InteractiveIcon>
                    </div>
                </Form>
            </div>
            <p
                className={cx([
                    'global!ace-u-margin--0-24-24',
                    'global!ace-u-typography--variant-h3',
                ])}
            >
                {translateScreen('table_header_title.count', {count: Number(taskSearchResultsCount)})}
            </p>
            <Table qaIdent="task-search-results">
                {taskSearchResults.length === 0 && (
                    <TableCaption>
                        <NoResultsBlock
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-disabled')}
                                    icon={findCaseIcon}
                                />
                            )}
                            message={translateScreen('no_results.message')}
                            description={translateScreen('no_results.description')}
                        />
                    </TableCaption>
                )}
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="task-due-date-time">
                            {translateScreen('table_header.due_date_time')}
                        </TableCell>
                        <TableCell qaIdentPart="sc-case-id">
                            {translateScreen('table_header.case_id')}
                        </TableCell>
                        <TableCell qaIdentPart="task-description">
                            {translateScreen('table_header.description')}
                        </TableCell>
                        <TableCell qaIdentPart="task-category">
                            {translateScreen('table_header.category')}
                        </TableCell>
                        <TableCell qaIdentPart="task-status">
                            {translateScreen('table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="task-assigned-to">
                            {translateScreen('table_header.assigned_to')}
                        </TableCell>
                        <TableCell qaIdentPart="task-created-by">
                            {translateScreen('table_header.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {taskSearchResults.map(task => (
                        <TableRow
                            key={task.id}
                            qaIdentPart={task.id}
                            onClick={() => {
                                if (isTaskEditingAllowed) initiateTaskEdit({taskId: task.id});
                            }}
                        >
                            <TableCell
                                qaIdentPart="task-due-date-time"
                                qaIdentPartPostfix={task.id}
                            >
                                {moment(task.dueDate).format('DD.MM.YYYY - HH:mm')}
                            </TableCell>
                            <TableCell
                                qaIdentPart="sc-case-id"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.assignedCaseId}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-description"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.description}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-category"
                                qaIdentPartPostfix={task.id}
                            >
                                {translate(`global.task_category.${task.category.toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-status"
                                qaIdentPartPostfix={task.id}
                            >
                                <Pill type={pillVariants[task.status]}>
                                    {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                </Pill>
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-assigned-to"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.assignedToRole
                                    ? translate(`global.user_shift_role.${task.assignedTo.toLowerCase()}`)
                                    : users[task.assignedTo]
                                        ? `${users[task.assignedTo]?.firstName || ''} ${users[task.assignedTo]?.lastName || ''}`
                                        : '-'
                                }
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-created-by"
                                qaIdentPartPostfix={task.id}
                            >
                                {task.createdByUser || '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {taskSearchResultsCount > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

TaskSearchScreen.propTypes = {
    taskSearchResults: PropTypes.array,
    users: PropTypes.object,
    initiateTaskEdit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    taskSearchResultsCount: PropTypes.number,
};

TaskSearchScreen.defaultProps = {
    taskSearchResults: [],
    taskSearchResultsCount: 0,
    users: null,
};

const mapStateToProps = state => ({
    taskSearchResults: state.tasks.taskSearchResults,
    taskSearchResultsCount: state.tasks.taskSearchResultsCount,
    users: userProfileSelectors.getUsers(state),
});

const mapDispatchToProps = dispatch => ({
    initiateTaskEdit: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_EDIT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskSearchScreen));
