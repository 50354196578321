import {put, select, take, fork} from 'redux-saga/effects';
import {etmTaskStatusTypes, etmTaskTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../../tasks/taskActionTypes';

const loadServiceCaseTasks = function* loadServiceCaseTasks({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;

    if (!serviceCaseId) return;

    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('assignedCaseId', serviceCaseId);
    searchQueryParams.append('taskType', etmTaskTypes.LEA);
    searchQueryParams.append('status', etmTaskStatusTypes.DUE);
    searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);

    yield fork(
        fetchRequest,
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST,
        ecsTaskManagementService.getTasks,
        {searchQueryParams},
    );

    const responseAction = yield take([
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST_SUCCEEDED,
        taskActionTypes.FETCH_SERVICE_CASE_TASKS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {totalElements} = response;

        yield put({
            type: taskActionTypes.SET_TASK_COUNT_INDICATOR,
            payload: {totalElements},
        });
    }

    if (responseAction.error) {
        yield put({type: taskActionTypes.RESET_TASK_COUNT_INDICATOR});
    }
};

export default loadServiceCaseTasks;
