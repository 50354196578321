import {take, select, fork, put, delay} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {LeistContact} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import contactDataModalTypes from '../modals/contactDataModalTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';
import * as contactActionTypes from '../contactActionTypes';
import routePaths from '../../routePaths';

// todo rewrite saga and find suitable change for delay after modal closure
//  (needed for route unmount effect on invoice screen)
const editContactFlow = function* editContactFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryEditContact = false;
    let retryEditContactData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_EDIT_CONTACT_FLOW);
            payloadData = payload;
        }

        const {client, selectedContact} = payloadData;
        let chosenModalOption;

        if (selectedContact) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });
        }

        if (!shouldRetryEditContact && client) {
            yield* openModal(modalIds.CONTACT_DATA, {
                client,
                contactId: selectedContact.id,
                type: contactDataModalTypes.EDIT_CONTACT,
            });

            chosenModalOption = yield take([
                contactActionTypes.DECLINE_EDIT_CONTACT,
                contactActionTypes.CONFIRM_EDIT_CONTACT,
            ]);
        }

        yield* closeModal(modalIds.CONTACT_DATA, {
            client,
            contactId: selectedContact.id,
            type: contactDataModalTypes.EDIT_CONTACT,
        });
        yield delay(100);

        if (shouldRetryEditContact || chosenModalOption.type === contactActionTypes.CONFIRM_EDIT_CONTACT) {
            const contactId = !shouldRetryEditContact && chosenModalOption?.payload?.contactId
                ? chosenModalOption?.payload?.contactId : retryEditContactData?.contactId;

            const contactData = !shouldRetryEditContact && chosenModalOption?.payload?.contactData
                ? chosenModalOption?.payload?.contactData : retryEditContactData?.contactData;

            const invoiceId = !shouldRetryEditContact && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryEditContactData?.invoiceId;

            const client = !shouldRetryEditContact && chosenModalOption?.payload?.client
                ? chosenModalOption?.payload?.client : retryEditContactData?.client;

            yield* openModal(modalIds.LOADER, {type: 'EDIT_CONTACT'});

            yield fork(fetchRequest,
                contactActionTypes.UPDATE_CONTACT_REQUEST,
                leaAssignmentFlowService.updateContact,
                {
                    contactId,
                    contactData: {
                        client,
                        ...LeistContact.objectToDTO(contactData),
                    },
                });

            const responseAction = yield take([
                contactActionTypes.UPDATE_CONTACT_REQUEST_SUCCEEDED,
                contactActionTypes.UPDATE_CONTACT_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: 'EDIT_CONTACT'});
            yield delay(100);
            shouldRetryEditContact = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {contactDTO} = response;
                const contactDTOs = [contactDTO];

                yield put({
                    type: contactActionTypes.STORE_CONTACTS,
                    payload: {contactDTOs},
                });

                shouldWaitForAction = true;
                const queryParams = new URLSearchParams();
                queryParams.append('ids', contactDTO.id);
                queryParams.append('client', client);
                yield put(replace(resolveRoute(routePaths.CONTACTS, {}, {search: queryParams.toString()})));
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === 503) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                yield* openModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.EDIT_CONTACT});

                const nextAction = yield take([
                    contactActionTypes.RETRY_EDIT_CONTACT,
                    contactActionTypes.CANCEL_ACTION,
                ]);

                yield* closeModal(modalIds.BC_ACTION_FAILED_MODAL, {type: bcActionFailedModalTypes.EDIT_CONTACT});
                yield delay(100);

                if (nextAction.type === contactActionTypes.RETRY_EDIT_CONTACT) {
                    shouldRetryEditContact = true;
                    shouldWaitForAction = false;
                    retryEditContactData = {
                        contactData,
                        contactId,
                        invoiceId,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryEditContact = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        yield* closeModal(modalIds.CONTACT_DATA, {
            client,
            contactId: selectedContact.id,
            type: contactDataModalTypes.EDIT_CONTACT,
        });
    }
};

export default editContactFlow;
