import {eupUserRoleTypes, clientTypes} from '@ace-de/eua-entity-types';
import {leaFeatureActions, leaFeatures} from './leaFeatures';
// import routePaths from '../routePaths';

/**
 * LEA system available roles
 */
const leaAuthorizationRoles = {
    [eupUserRoleTypes.AUDITOR]: {
        name: eupUserRoleTypes.AUDITOR,
        features: {
            [leaFeatureActions.CREATE]: [
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.TASKS,
                leaFeatures.MESSAGE,
                leaFeatures.DOCUMENT,
            ],
            [leaFeatureActions.READ]: [
                leaFeatures.MEMBER_DATA,
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.MESSAGE,
                leaFeatures.DOCUMENT,
                leaFeatures.TASKS,
            ],
            [leaFeatureActions.UPDATE]: [
                leaFeatures.MEMBER_DATA,
                leaFeatures.CASE_STATUS,
                leaFeatures.INVOICE_STATUS,
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.TASKS,
            ],
            [leaFeatureActions.DELETE]: [
                leaFeatures.SERVICE_CASE,
                leaFeatures.INVOICE,
                leaFeatures.DOCUMENT,
            ],
        },
        systems: [clientTypes.LEA_CLIENT],
        forbiddenRoutes: [],
        allowedRoutes: [],
    },
};

export default leaAuthorizationRoles;
