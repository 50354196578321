import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';

const invoiceStatusPillColorMap = {
    [alfInvoiceStatusTypes.IN_CREATION]: 'information',
    [alfInvoiceStatusTypes.OPEN]: 'information',
    [alfInvoiceStatusTypes.CLOSED]: 'information',
    [alfInvoiceStatusTypes.DECLINED]: 'information',
    [alfInvoiceStatusTypes.APPROVED]: 'positive',
    [alfInvoiceStatusTypes.MANUAL_CHECK]: 'negative',
};
export default invoiceStatusPillColorMap;
