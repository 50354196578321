import {put, fork, select, take} from 'redux-saga/effects';
import * as dashboardActionTypes from '../dashboardActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import dashboardScreenTabs from '../dashboardScreenTabs';

export const loadReporterInvoicesAmount = function* loadReporterInvoicesAmount({payload}) {
    const {location} = payload;
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (location.query.activeTab === dashboardScreenTabs.DASHBOARD) return;

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_REPORTER_INVOICES_AMOUNT_REQUEST,
        leaAssignmentFlowService.getReporterInvoicesCount,
    );
    const responseAction = yield take([
        dashboardActionTypes.FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_REPORTER_INVOICES_AMOUNT_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {reporterInvoiceAmounts} = response;

        yield put({
            type: dashboardActionTypes.STORE_REPORTER_INVOICES_AMOUNT,
            payload: {reporterInvoiceAmounts},
        });
    }
};

export default loadReporterInvoicesAmount;
