import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter, resolveRoute} from '@computerrock/formation-router';
import {Panel, useStyles, Form, ButtonPrimary, RadioButton, downloadIcon, RadioButtonGroup, CheckboxGroup, List, Checkbox, InteractiveIcon, resetIcon} from '@ace-de/ui-components';
import {ehmCaseLogCategoryTypes, sortingOptions} from '@ace-de/eua-entity-types';
import caseLogFilterCategories from './caseLogFilterCategories';
import * as caseLogActionTypes from './caseLogActionTypes';
import * as serviceCaseSelectors from '../service-cases/serviceCaseSelectors';
import invoiceOverviewScreenTabs from '../invoices/invoiceOverviewScreenTabs';
import routePaths from '../routePaths';
import * as invoiceSelectors from '../invoices/invoiceSelectors';
import * as caseLogSelectors from './caseLogSelectors';
import AssignmentTypeOption from './ui-elements/AssignmentTypeOption';

const initialCategories = [
    ehmCaseLogCategoryTypes.NOTE,
    ehmCaseLogCategoryTypes.ASSIGNMENT,
    ehmCaseLogCategoryTypes.MESSAGE,
    ehmCaseLogCategoryTypes.INVOICE,
];
const categories = Object.keys(caseLogFilterCategories);

const FilterPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('filter_panel');
    const {history, exportCaseLogPDF, serviceCase, invoice, caseLogServiceTypes} = props;

    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));
    const [filterFormData, setFilterFormData] = useState({
        category: queryParams.getAll('category').length ? queryParams.getAll('category') : [],
        serviceTypes: queryParams.getAll('serviceTypes').length
            ? [...queryParams.getAll('serviceTypes'), queryParams.get('includeCaseLogs') ? 'SERVICE_CASE' : '']
            : queryParams.get('includeCaseLogs') ? ['SERVICE_CASE'] : [],
        sort: queryParams.get('sort'),
    });
    const [selectedTypes, setSelectedTypes] = useState(queryParams.getAll('serviceTypes').length
        ? [...queryParams.getAll('serviceTypes'), queryParams.get('includeCaseLogs') ? 'SERVICE_CASE' : '']
        : queryParams.get('includeCaseLogs') ? ['SERVICE_CASE'] : []);

    const resetRef = useRef(false);
    const submitRef = useRef(false);

    const formatQueryParams = useCallback(formData => {
        const apiQueryParams = new URLSearchParams();
        apiQueryParams.append('activeTab', invoiceOverviewScreenTabs.LOG);

        Object.keys(formData).forEach(formField => {
            const formFieldValue = formData[formField];
            if (formFieldValue) {
                switch (formField) {
                    case 'category': {
                        if (!Array.isArray(formFieldValue) || !formFieldValue.length > 0) break;
                        // check if service type list contains at least one of assignment service types
                        const hasServiceType = formData.serviceTypes.length > 0
                            && formData.serviceTypes.some(serviceType => caseLogServiceTypes.includes(serviceType));

                        // create a combined list of categories based on selection
                        const categoryParams = formFieldValue.map(category => {
                            if (!category) return [];
                            const categoryValues = caseLogFilterCategories[category];
                            if (category === 'STATUS_CHANGE') {
                                return hasServiceType
                                    ? categoryValues
                                    : categoryValues.filter(category => (
                                        category !== ehmCaseLogCategoryTypes.ASSIGNMENT_STATUS_CHANGE));
                            }
                            return caseLogFilterCategories[category];
                        }).flat();

                        // append category values
                        categoryParams.forEach(categoryParam => {
                            if (categoryParam !== undefined) apiQueryParams.append('category', categoryParam);
                        });
                        break;
                    }
                    case 'serviceTypes': {
                        if (!Array.isArray(formFieldValue) || !formFieldValue.length > 0) break;

                        apiQueryParams.append('includeCaseLogs', formFieldValue.includes('SERVICE_CASE'));

                        formFieldValue.forEach(serviceType => {
                            if (serviceType !== 'SERVICE_CASE') {
                                apiQueryParams.append('serviceTypes', serviceType);
                            }
                        });
                        break;
                    }
                    case 'sort': {
                        apiQueryParams.append(formField, formData[formField]);
                        apiQueryParams.append(
                            formField,
                            formData[formField].includes('asc') ? 'eventTime,asc' : 'eventTime,desc',
                        );
                        break;
                    }
                    default:
                        break;
                }
            }
        });

        return apiQueryParams;
    }, [caseLogServiceTypes]);

    const resetCaseLogFilteringParams = useCallback(() => {
        const initialCaseLogFilteringParams = {
            category: initialCategories,
            serviceTypes: [],
            sort: 'eventDate,asc',
            page: '0',
        };
        setFilterFormData(initialCaseLogFilteringParams);
        setSelectedTypes([]);
        const apiQueryParams = formatQueryParams(initialCaseLogFilteringParams);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(invoice ? routePaths.INVOICE_OVERVIEW : routePaths.SERVICE_CASES_OVERVIEW, {
            serviceCaseId: serviceCase.id,
            ...(invoice ? {invoiceId: invoice.id} : {}),
        }, {search: queryParamsString}));
    }, [history, serviceCase, formatQueryParams, invoice]);

    useEffect(() => {
        if (queryParams.get('activeTab') === invoiceOverviewScreenTabs.LOG
            && !queryParams.get('category')
            && !queryParams.get('serviceTypes')
            && !queryParams.get('sort')
            && !queryParams.get('includeCaseLogs')) {
            resetCaseLogFilteringParams();
        }
    }, [history.location.search, queryParams, resetCaseLogFilteringParams]);

    useEffect(() => {
        if (typeof history.location.search === 'string') {
            const newQueryParams = new URLSearchParams(history.location.search);
            if (newQueryParams.toString() !== queryParams.toString()) {
                setQueryParams(newQueryParams);
            }
        }
    }, [history.location.search, queryParams]);

    const filterCaseLogs = formValues => {
        if (!formValues) {
            return;
        }
        const apiQueryParams = formatQueryParams(formValues);
        apiQueryParams.set('page', 0);
        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(invoice ? routePaths.INVOICE_OVERVIEW : routePaths.SERVICE_CASES_OVERVIEW, {
            serviceCaseId: serviceCase.id,
            ...(invoice ? {invoiceId: invoice.id} : {}),
        }, {search: queryParamsString}));
    };

    const handleOnChange = filterData => {
        if (submitRef.current) return;
        if (resetRef.current) {
            resetRef.current = false;
            return;
        }

        setFilterFormData(filterData);
    };

    const handleOnResetFilter = () => {
        resetRef.current = true;
        submitRef.current = false;
        resetCaseLogFilteringParams();
    };

    const handleOnExportPDF = () => {
        const searchParams = history.location.search;
        const searchQueryParams = new URLSearchParams(searchParams);
        searchQueryParams.delete('page');
        exportCaseLogPDF({serviceCaseId: serviceCase.id, searchQueryParams});
    };

    return (
        <Panel
            title={translatePanel('panel_title.filter')}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-padding--0-32-24',
                ])}
            >
                <Form name="filterCaseLogs" onChange={handleOnChange} onSubmit={filterCaseLogs}>
                    <Fragment>
                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            >
                                {translatePanel('sorting_label.sorting')}
                            </p>
                            <RadioButtonGroup
                                name="sort"
                                value={queryParams.get('sort') || filterFormData?.sort}
                            >
                                {Object.values(sortingOptions).map((sortOption, id) => (
                                    <RadioButton
                                        className={cx('global!ace-u-margin--bottom-24')}
                                        key={`${sortOption}-${id}`}
                                        name={sortOption}
                                        value={sortOption === sortingOptions.OLDEST_TO_NEWEST
                                            ? 'eventDate,asc' : 'eventDate,desc'}
                                    >
                                        {translatePanel(`sorting_option.${sortOption.toLowerCase()}`)}
                                    </RadioButton>
                                ))}
                            </RadioButtonGroup>
                        </div>
                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-24',
                                ])}
                            >
                                {translatePanel('category_label.category')}
                            </p>
                            <CheckboxGroup
                                name="category"
                                value={filterFormData.category}
                            >
                                <div
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-flex--direction-column',
                                    ])}
                                >
                                    {categories.map((category, id) => (
                                        <Checkbox
                                            className={cx('global!ace-u-margin--bottom-24')}
                                            key={`${category}-${id}`}
                                            name={category}
                                            value={category}
                                        >
                                            {translate(`global.case_log_category.${category.toLowerCase()}`)}
                                        </Checkbox>
                                    ))}
                                </div>
                            </CheckboxGroup>
                        </div>
                        <div className={cx('global!ace-u-margin--bottom-48')}>
                            <p
                                className={cx([
                                    'global!ace-u-typography--variant-body',
                                    'global!ace-u-margin--bottom-32',
                                ])}
                            >
                                {translatePanel('assignment_label.assignment')}
                            </p>
                            <List
                                className={cx('global!ace-u-full-width')}
                                name="serviceTypes"
                                isMultipleChoice={true}
                                value={filterFormData.serviceTypes}
                                onChange={setSelectedTypes}
                            >
                                {(caseLogServiceTypes || []).map(serviceType => {
                                    return (
                                        <AssignmentTypeOption
                                            value={serviceType}
                                            isSelected={selectedTypes && selectedTypes.includes(serviceType)}
                                            key={`${serviceType}Key`}
                                            label={translate(`global.service_type.${serviceType.toLowerCase()}`)}
                                        />
                                    );
                                })}
                                <AssignmentTypeOption
                                    value="SERVICE_CASE"
                                    isSelected={selectedTypes && selectedTypes.includes('SERVICE_CASE')}
                                    key="serviceCaseKey"
                                    label={translatePanel('service_option.service_case')}
                                />
                            </List>
                        </div>
                        <ButtonPrimary
                            className={cx('global!ace-u-full-width')}
                            type="submit"
                            name="filterButton"
                        >
                            {translatePanel('button_label.filter')}
                        </ButtonPrimary>
                    </Fragment>
                </Form>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-center',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-margin--top-16',
                    ])}
                >
                    <InteractiveIcon
                        icon={resetIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                        ])}
                        onClick={handleOnResetFilter}
                    >
                        {translatePanel('interactive_icon_label.reset_filter')}
                    </InteractiveIcon>
                    <InteractiveIcon
                        icon={downloadIcon}
                        className={cx([
                            'ace-c-interactive-icon--reverse',
                            'ace-c-interactive-icon--highlight',
                            'global!ace-u-margin--top-16',
                        ])}
                        onClick={handleOnExportPDF}
                    >
                        {translatePanel('interactive_icon_label.export_case_log')}
                    </InteractiveIcon>
                </div>
            </div>
        </Panel>
    );
};

FilterPanel.propTypes = {
    serviceCase: PropTypes.object,
    invoice: PropTypes.object,
    caseLogServiceTypes: PropTypes.array,
    history: PropTypes.object,
    exportCaseLogPDF: PropTypes.func.isRequired,
};

FilterPanel.defaultProps = {
    caseLogServiceTypes: null,
    history: null,
    serviceCase: null,
    invoice: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const getInvoice = invoiceSelectors.createInvoicesSelector();

    return {
        serviceCase: getServiceCase(state, props),
        invoice: getInvoice(state, props),
        caseLogServiceTypes: caseLogSelectors.getCaseLogServices(state),
    };
};

const mapDispatchToProps = dispatch => {
    return ({
        exportCaseLogPDF: payload => dispatch({type: caseLogActionTypes.SUBMIT_EXPORT_CASE_LOG_PDF, payload}),
    });
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterPanel));
