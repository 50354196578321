import modalIds from './modalIds';
import InvoiceContactSearchModal from './invoices/modals/InvoiceContactSearchModal';
import InvoiceDocumentModal from './invoices/modals/InvoiceDocumentModal';
import DeleteModal from './application/modals/DeleteModal';
import InvoiceLineDataModal from './invoices/modals/InvoiceLineDataModal';
import InvoiceSuccessfulActionModal from './invoices/modals/InvoiceSuccessfulActionModal';
import INACheckLoaderModal from './invoices/modals/INACheckLoaderModal';
import INACheckResponseModal from './invoices/modals/INACheckResponseModal';
import ContactDataModal from './contacts/modals/ContactDataModal';
import ContactAccountPartyDataModal from './contacts/modals/ContactAccountPartyDataModal';
import Loader from './application/modals/Loader';
import BCActionFailedModal from './contacts/modals/BCActionFailedModal';
import VKRInvoiceCreationModal from './invoices/modals/VKRInvoiceCreationModal';
import InvoiceDeclineModal from './invoices/modals/InvoiceDeclineModal';
import SendMessageModal from './communication/modals/SendMessageModal';
import InvoiceApprovalReasonModal from './invoices/modals/InvoiceApprovalReasonModal';
import SCDocumentUploadModal from './service-cases/modals/SCDocumentUploadModal';
import UploadReplyModal from './communication/modals/UploadReplyModal';
import TaskDataModal from './tasks/modals/TaskDataModal';
import InvoiceCorrectionModal from './invoices/modals/InvoiceCorrectionModal';
import BCContactModal from './contacts/modals/BCContactModal';
import ConfirmVehicleSelectionModal from './service-cases/modals/ConfirmVehicleSelectionModal';
import VehicleDataModal from './service-cases/modals/VehicleDataModal';

/**
* Modal configuration
*/
export default [
    {
        id: modalIds.INVOICE_CONTACT_SEARCH,
        component: InvoiceContactSearchModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_DOCUMENT_MODAL,
        component: InvoiceDocumentModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.DELETE_MODAL,
        component: DeleteModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_LINE_DATA,
        component: InvoiceLineDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_SUCCESS_MODAL,
        component: InvoiceSuccessfulActionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INA_CHECK_LOADER_MODAL,
        component: INACheckLoaderModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INA_CHECK_RESPONSE_MODAL,
        component: INACheckResponseModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONTACT_DATA,
        component: ContactDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.LOADER,
        component: Loader,
        hasBackdrop: true,
    },
    {
        id: modalIds.BC_ACTION_FAILED_MODAL,
        component: BCActionFailedModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.VKR_INVOICE_CREATION_MODAL,
        component: VKRInvoiceCreationModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONTACT_ACCOUNT_PARTY_DATA,
        component: ContactAccountPartyDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_DECLINE_MODAL,
        component: InvoiceDeclineModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SEND_MESSAGE_MODAL,
        component: SendMessageModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_APPROVAL_REASON_MODAL,
        component: InvoiceApprovalReasonModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.SERVICE_CASE_DOCUMENT_UPLOAD_MODAL,
        component: SCDocumentUploadModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.UPLOAD_REPLY_MODAL,
        component: UploadReplyModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.TASK_DATA_MODAL,
        component: TaskDataModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.INVOICE_CORRECTION_MODAL,
        component: InvoiceCorrectionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.BC_CONTACT_MODAL,
        component: BCContactModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.CONFIRM_VEHICLE_SELECTION_MODAL,
        component: ConfirmVehicleSelectionModal,
        hasBackdrop: true,
    },
    {
        id: modalIds.VEHICLE_DATA_MODAL,
        component: VehicleDataModal,
        hasBackdrop: true,
    },
];
