import {take, put, fork, delay, select} from 'redux-saga/effects';
import {closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {alfAccountTypes, AccountParty} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as contactActionTypes from '../contactActionTypes';
import * as invoiceActionTypes from '../../invoices/invoiceActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import contactAccountPartyDataModalTypes from '../modals/contactAccountPartyDataModalTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';
import bcModalContentTypes from '../modals/bcModalContentTypes';

const accountPartyCreationModalFlow = function* accountPartyCreationModalFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryAccountPartyCreation = false;
    let retryAccountPartyCreationData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_CREATE_ACCOUNT_PARTY_MODAL_FLOW);
            payloadData = payload;
        }
        const {location} = yield select(state => state.router);

        let chosenModalOption;
        const {client, accountPartyType, selectedContact} = payloadData;
        const modalType = accountPartyType === alfAccountTypes.CREDITOR
            ? contactAccountPartyDataModalTypes.CREATE_CREDITOR
            : contactAccountPartyDataModalTypes.CREATE_DEBTOR;

        if (!shouldRetryAccountPartyCreation && client && accountPartyType && selectedContact) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });

            const searchParams = new URLSearchParams(location.search);
            searchParams.set('contentType', bcModalContentTypes.CONTACT_ACCOUNT_PARTY_DATA);
            searchParams.set('client', client);
            searchParams.set('accountPartyType', accountPartyType);
            searchParams.set('type', modalType);
            searchParams.set('contactId', selectedContact.id);

            yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

            chosenModalOption = yield take([
                contactActionTypes.RETURN_TO_CONTACT_SEARCH,
                contactActionTypes.CONFIRM_CREATE_ACCOUNT_PARTY,
                contactActionTypes.RELOAD_CREATE_ACCOUNT_MODAL_FLOW,
            ]);
        }

        if (chosenModalOption?.type === contactActionTypes.RELOAD_CREATE_ACCOUNT_MODAL_FLOW) {
            shouldWaitForAction = true;
            continue;
        }

        if (shouldRetryAccountPartyCreation
            || chosenModalOption.type === contactActionTypes.CONFIRM_CREATE_ACCOUNT_PARTY) {
            const accountPartyData = !shouldRetryAccountPartyCreation && chosenModalOption?.payload?.accountPartyData
                ? chosenModalOption?.payload?.accountPartyData : retryAccountPartyCreationData?.accountPartyData;

            const invoiceId = !shouldRetryAccountPartyCreation && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryAccountPartyCreationData?.invoiceId;

            const loaderSearchParams = new URLSearchParams(location.search);
            loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
            loaderSearchParams.set('type', modalType);

            yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

            yield fork(fetchRequest,
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST,
                leaAssignmentFlowService.createAccountParty,
                {
                    accountPartyData: {
                        ...AccountParty.objectToDTO(accountPartyData),
                        client,
                    },
                });

            const responseAction = yield take([
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST_SUCCEEDED,
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST_FAILED,
            ]);

            yield delay(100);
            shouldRetryAccountPartyCreation = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {accountPartyData} = response;

                // TODO: Consider storing only id of the newly created account party
                yield put({
                    type: contactActionTypes.STORE_ACCOUNT_PARTY,
                    payload: {accountPartyDTO: accountPartyData},
                });

                shouldWaitForAction = true;
                yield* closeModal(modalIds.BC_CONTACT_MODAL, {contentType: '', type: '', client: '', contactId: '', accountPartyType: ''});
                yield delay(100);
                yield put({
                    type: invoiceActionTypes.INITIATE_CONTACT_SELECTION_FLOW,
                    payload: {
                        invoiceId,
                        client,
                        contactId: selectedContact.id,
                        initiateContactSearch: true,
                    },
                });
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === 503) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                const failedQueryParams = new URLSearchParams(location.search);
                failedQueryParams.set('contentType', bcModalContentTypes.BC_ACTION_FAILED);
                failedQueryParams.set('type', bcActionFailedModalTypes.CREATE_ACCOUNT_PARTY);
                yield put(replace(resolveRoute(location.pathname, {}, {search: failedQueryParams.toString()})));

                const nextAction = yield take([
                    contactActionTypes.RETRY_ACCOUNT_PARTY_CREATION,
                    contactActionTypes.CANCEL_ACTION,
                    contactActionTypes.RETURN_TO_CONTACT_SEARCH,
                ]);

                if (nextAction.type === contactActionTypes.RETRY_ACCOUNT_PARTY_CREATION) {
                    shouldRetryAccountPartyCreation = true;
                    shouldWaitForAction = false;
                    retryAccountPartyCreationData = {
                        accountPartyData,
                        invoiceId,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryAccountPartyCreation = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('contentType', bcModalContentTypes.INVOICE_CONTACT_SEARCH);
        searchParams.set('client', client);

        yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));
    }
};

export default accountPartyCreationModalFlow;
