/**
 * Command action types
 */
export const CREATE_NEW_INVOICE = '[service-cases] CREATE_NEW_INVOICE';
export const SUBMIT_INVOICE_BASIC_DATA_FORM = '[service-cases] SUBMIT_INVOICE_BASIC_DATA_FORM';
export const INITIATE_CONTACT_SELECTION_FLOW = '[service-cases] INITIATE_CONTACT_SELECTION_FLOW';
export const DECLINE_INVOICE_CONTACT_SEARCH = '[service-cases] DECLINE_INVOICE_CONTACT_SEARCH';
export const SELECT_INVOICE_CONTACT = '[service-cases] SELECT_INVOICE_CONTACT';
export const INITIATE_INVOICE_DOCUMENT_UPLOAD_FLOW = '[service-cases] INITIATE_INVOICE_DOCUMENT_UPLOAD_FLOW';
export const DECLINE_INVOICE_DOCUMENT_UPLOAD = '[service-cases] DECLINE_INVOICE_DOCUMENT_UPLOAD';
export const CONFIRM_INVOICE_DOCUMENT_UPLOAD = '[service-cases] CONFIRM_INVOICE_DOCUMENT_UPLOAD';
export const INITIATE_INVOICE_DOCUMENT_EDIT_FLOW = '[service-cases] INITIATE_INVOICE_DOCUMENT_EDIT_FLOW';
export const DECLINE_INVOICE_DOCUMENT_EDIT = '[service-cases] DECLINE_INVOICE_DOCUMENT_EDIT';
export const CONFIRM_INVOICE_DOCUMENT_EDIT = '[service-cases] CONFIRM_INVOICE_DOCUMENT_EDIT';
export const INITIATE_INVOICE_DOCUMENT_DELETE_FLOW = '[service-cases] INITIATE_INVOICE_DOCUMENT_DELETE_FLOW';
export const DECLINE_DELETE = '[service-cases] DECLINE_DELETE';
export const CONFIRM_INVOICE_DOCUMENT_DELETE = '[service-cases] CONFIRM_INVOICE_DOCUMENT_DELETE';
export const INITIATE_INVOICE_DOCUMENT_DOWNLOAD_FLOW = '[service-cases] INITIATE_INVOICE_DOCUMENT_DOWNLOAD_FLOW';
export const INITIATE_CREATE_INVOICE_LINE_FLOW = '[service-cases] INITIATE_CREATE_INVOICE_LINE_FLOW';
export const DECLINE_CREATE_INVOICE_LINE = '[service-cases] DECLINE_CREATE_INVOICE_LINE';
export const CONFIRM_CREATE_INVOICE_LINE = '[service-cases] CONFIRM_CREATE_INVOICE_LINE';
export const SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION = '[service-cases] SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION';
export const REDIRECT_TO_DASHBOARD = '[service-cases] REDIRECT_TO_DASHBOARD';
export const REDIRECT_TO_CASE_OVERVIEW = '[service-cases] REDIRECT_TO_CASE_OVERVIEW';
export const SEARCH_INVOICES = '[service-cases] SEARCH_INVOICES';
export const CREATE_INVOICE_LINE = '[service-cases] CREATE_INVOICE_LINE';
export const SUBMIT_INVOICE_UPLOADED_DOCUMENTS = '[service-cases] SUBMIT_INVOICE_UPLOADED_DOCUMENTS';
export const DELETE_INVOICE_UPLOADED_DOCUMENTS = '[service-cases] DELETE_INVOICE_UPLOADED_DOCUMENTS';
export const SUBMIT_INVOICE_SERVICE_LINES = '[service-cases] SUBMIT_INVOICE_SERVICE_LINES';
export const INITIATE_INA_CHECK = '[service-cases] INITIATE_INA_CHECK';
export const REDIRECT_AFTER_INA_CHECK = '[service-cases] REDIRECT_AFTER_INA_CHECK';
export const RETRY_INA_CHECK = '[service-cases] RETRY_INA_CHECK';
export const DECLINE_INA_CHECK = '[service-cases] DECLINE_INA_CHECK';
export const INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW = '[service-cases] INITIATE_MANUAL_INVOICE_STATUS_UPDATE_FLOW';
export const INITIATE_INVOICE_FORWARD_FLOW = '[service-cases] INITIATE_INVOICE_FORWARD_FLOW';
export const DECLINE_VKR_INVOICE_CREATION = '[service-cases] DECLINE_VKR_INVOICE_CREATION';
export const CONFIRM_VKR_INVOICE_CREATION = '[service-cases] CONFIRM_VKR_INVOICE_CREATION';
export const INITIATE_INVOICE_SCREEN_REDIRECTION = '[service-cases] INITIATE_INVOICE_SCREEN_REDIRECTION';
export const SUBMIT_CREATE_INTERNAL_NOTE = '[service-cases] SUBMIT_CREATE_INTERNAL_NOTE';
export const INITIATE_INVOICE_LINE_DELETE_FLOW = '[service-cases] INITIATE_INVOICE_LINE_DELETE_FLOW';
export const CONFIRM_INVOICE_LINE_DELETE = '[service-cases] CONFIRM_INVOICE_LINE_DELETE';
export const INITIATE_INVOICE_LINE_EDIT_FLOW = '[service-cases] INITIATE_INVOICE_LINE_EDIT_FLOW';
export const CONFIRM_EDIT_INVOICE_LINE = '[service-cases] CONFIRM_EDIT_INVOICE_LINE';
export const DECLINE_EDIT_INVOICE_LINE = '[service-cases] DECLINE_EDIT_INVOICE_LINE';
export const INITIATE_INVOICE_DECLINE_FLOW = '[service-cases] INITIATE_INVOICE_DECLINE_FLOW';
export const CONFIRM_INVOICE_DECLINE_FLOW = '[service-cases] CONFIRM_INVOICE_DECLINE_FLOW';
export const DECLINE_INVOICE_DECLINE_FLOW = '[service-cases] DECLINE_INVOICE_DECLINE_FLOW';
export const UPDATE_INVOICE_STATUS = '[service-cases] UPDATE_INVOICE_STATUS';
export const CREATE_INVOICE_FOR_ASSIGNMENT = '[service-cases] CREATE_INVOICE_FOR_ASSIGNMENT';
export const INITIATE_INVOICE_APPROVAL_FLOW = '[service-cases] INITIATE_INVOICE_APPROVAL_FLOW';
export const DECLINE_INVOICE_APPROVAL = '[service-cases] DECLINE_INVOICE_APPROVAL';
export const CONFIRM_INVOICE_APPROVAL = '[service-cases] CONFIRM_INVOICE_APPROVAL';
export const INITIATE_INVOICE_CORRECTION_FLOW = '[service-cases] INITIATE_INVOICE_CORRECTION_FLOW';
export const CONFIRM_INVOICE_CORRECTION = '[service-cases] CONFIRM_INVOICE_CORRECTION';
export const DECLINE_INVOICE_CORRECTION = '[service-cases] DECLINE_INVOICE_CORRECTION';
export const INITIATE_INVOICE_ESTIMATION_FLOW = '[service-cases] INITIATE_INVOICE_ESTIMATION_FLOW';
export const UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK = '[service-cases] UPDATE_INVOICE_STATUS_TO_MANUAL_CHECK';
export const FETCH_ACCOUNT_PARTY_OPTIONS = '[service-cases] FETCH_ACCOUNT_PARTY_OPTIONS';
export const INITIATE_INVOICE_DELETE_FLOW = '[service-cases] INITIATE_INVOICE_DELETE_FLOW';
export const CONFIRM_INVOICE_DELETE = '[service-cases] CONFIRM_INVOICE_DELETE';

/**
 * Event action types
 */
export const BATCH_UPDATE_INVOICE = '[service-cases] BATCH_UPDATE_INVOICE';
export const BATCH_UPDATE_INVOICE_COMPLETED = '[service-cases] BATCH_UPDATE_INVOICE_COMPLETED';

export const BATCH_UPDATE_INVOICE_SERVICE_LINE = '[service-cases] BATCH_UPDATE_INVOICE_SERVICE_LINE';
export const BATCH_UPDATE_INVOICE_SERVICE_LINE_COMPLETED = '[service-cases] BATCH_UPDATE_INVOICE_SERVICE_LINE_COMPLETED';

export const CREATE_NEW_INVOICE_REQUEST = '[service-cases] CREATE_NEW_INVOICE_REQUEST';
export const CREATE_NEW_INVOICE_REQUEST_SENT = '[service-cases] CREATE_NEW_INVOICE_REQUEST_SENT';
export const CREATE_NEW_INVOICE_REQUEST_SUCCEEDED = '[service-cases] CREATE_NEW_INVOICE_REQUEST_SUCCEEDED';
export const CREATE_NEW_INVOICE_REQUEST_FAILED = '[service-cases] CREATE_NEW_INVOICE_REQUEST_FAILED';

export const UPDATE_INVOICE_REQUEST = '[service-cases] UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_REQUEST_REQUEST_SENT = '[service-cases] UPDATE_INVOICE_REQUEST_REQUEST_SENT';
export const UPDATE_INVOICE_REQUEST_SUCCEEDED = '[service-cases] UPDATE_INVOICE_REQUEST_SUCCEEDED';
export const UPDATE_INVOICE_REQUEST_FAILED = '[service-cases] UPDATE_INVOICE_REQUEST_FAILED';

export const FETCH_INVOICE_REQUEST = '[service-cases] FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_REQUEST_SENT = '[service-cases] FETCH_INVOICE_REQUEST_SENT';
export const FETCH_INVOICE_REQUEST_FAILED = '[service-cases] FETCH_INVOICE_REQUEST_FAILED';
export const FETCH_INVOICE_REQUEST_SUCCEEDED = '[service-cases] FETCH_INVOICE_REQUEST_SUCCEEDED';

export const SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST = '[service-cases] SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST';
export const SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SENT = '[service-cases] SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SENT';
export const SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED = '[service-cases] SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_SUCCEEDED';
export const SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED = '[service-cases] SEARCH_INVOICE_PARTNER_LOCATION_GEOLOCATION_REQUEST_FAILED';

export const FETCH_SERVICES_REQUEST = '[service-cases] FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_REQUEST_SENT = '[service-cases] FETCH_SERVICES_REQUEST_SENT';
export const FETCH_SERVICES_REQUEST_FAILED = '[service-cases] FETCH_SERVICES_REQUEST_FAILED';
export const FETCH_SERVICES_REQUEST_SUCCEEDED = '[service-cases] FETCH_SERVICES_REQUEST_SUCCEEDED';

export const SEARCH_INVOICES_REQUEST = '[service-cases] SEARCH_INVOICES_REQUEST';
export const SEARCH_INVOICES_REQUEST_SENT = '[service-cases] SEARCH_INVOICES_REQUEST_SENT';
export const SEARCH_INVOICES_REQUEST_SUCCEEDED = '[service-cases] SEARCH_INVOICES_REQUEST_SUCCEEDED';
export const SEARCH_INVOICES_REQUEST_FAILED = '[service-cases] SEARCH_INVOICES_REQUEST_FAILED';

export const CREATE_INVOICE_LINE_REQUEST = '[service-cases] CREATE_INVOICE_LINE_REQUEST';
export const CREATE_INVOICE_LINE_REQUEST_SENT = '[service-cases] CREATE_INVOICE_LINE_REQUEST_SENT';
export const CREATE_INVOICE_LINE_REQUEST_FAILED = '[service-cases] CREATE_INVOICE_LINE_REQUEST_FAILED';
export const CREATE_INVOICE_LINE_REQUEST_SUCCEEDED = '[service-cases] CREATE_INVOICE_LINE_REQUEST_SUCCEEDED';

export const DOWNLOAD_INVOICE_DOCUMENT_REQUEST = '[service-cases] DOWNLOAD_INVOICE_DOCUMENT_REQUEST';
export const DOWNLOAD_INVOICE_DOCUMENT_REQUEST_SENT = '[service-cases] DOWNLOAD_INVOICE_DOCUMENT_REQUEST_SENT';
export const DOWNLOAD_INVOICE_DOCUMENT_REQUEST_SUCCEEDED = '[service-cases] DOWNLOAD_INVOICE_DOCUMENT_REQUEST_SUCCEEDED';
export const DOWNLOAD_INVOICE_DOCUMENT_REQUEST_FAILED = '[service-cases] DOWNLOAD_INVOICE_DOCUMENT_REQUEST_FAILED';

export const UPDATE_INVOICE_STATUS_REQUEST = '[service-cases] UPDATE_INVOICE_STATUS_REQUEST';
export const UPDATE_INVOICE_STATUS_REQUEST_SENT = '[service-cases] UPDATE_INVOICE_STATUS_REQUEST_SENT';
export const UPDATE_INVOICE_STATUS_REQUEST_SUCCEEDED = '[service-cases] UPDATE_INVOICE_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_INVOICE_STATUS_REQUEST_FAILED = '[service-cases] UPDATE_INVOICE_STATUS_REQUEST_FAILED';

export const UPDATE_INVOICE_SERVICE_LINES_REQUEST = '[service-cases] UPDATE_INVOICE_SERVICE_LINES_REQUEST';
export const UPDATE_INVOICE_SERVICE_LINES_REQUEST_SENT = '[service-cases] UPDATE_INVOICE_SERVICE_LINES_REQUEST_SENT';
export const UPDATE_INVOICE_SERVICE_LINES_REQUEST_SUCCEEDED = '[service-cases] UPDATE_INVOICE_SERVICE_LINES_REQUEST_SUCCEEDED';
export const UPDATE_INVOICE_SERVICE_LINES_REQUEST_FAILED = '[service-cases] UPDATE_INVOICE_SERVICE_LINES_REQUEST_FAILED';

export const CHECK_INA_RULES_REQUEST = '[service-cases] CHECK_INA_RULES_REQUEST';
export const CHECK_INA_RULES_REQUEST_SENT = '[service-cases] CHECK_INA_RULES_REQUEST_SENT';
export const CHECK_INA_RULES_REQUEST_SUCCEEDED = '[service-cases] CHECK_INA_RULES_REQUEST_SUCCEEDED';
export const CHECK_INA_RULES_REQUEST_FAILED = '[service-cases] CHECK_INA_RULES_REQUEST_FAILED';

export const CREATE_VKR_INVOICE_REQUEST = '[service-cases] CREATE_VKR_INVOICE_REQUEST';
export const CREATE_VKR_INVOICE_REQUEST_SENT = '[service-cases] CREATE_VKR_INVOICE_REQUEST_SENT';
export const CREATE_VKR_INVOICE_REQUEST_SUCCEEDED = '[service-cases] CREATE_VKR_INVOICE_REQUEST_SUCCEEDED';
export const CREATE_VKR_INVOICE_REQUEST_FAILED = '[service-cases] CREATE_VKR_INVOICE_REQUEST_FAILED';

export const CREATE_INTERNAL_NOTE_REQUEST = '[service-cases] CREATE_INTERNAL_NOTE_REQUEST';
export const CREATE_INTERNAL_NOTE_REQUEST_SENT = '[service-cases] CREATE_INTERNAL_NOTE_REQUEST_SENT';
export const CREATE_INTERNAL_NOTE_REQUEST_SUCCEEDED = '[service-cases] CREATE_INTERNAL_NOTE_REQUEST_SUCCEEDED';
export const CREATE_INTERNAL_NOTE_REQUEST_FAILED = '[service-cases] CREATE_INTERNAL_NOTE_REQUEST_FAILED';

export const DELETE_INVOICE_LINE_REQUEST = '[service-cases] DELETE_INVOICE_LINE_REQUEST';
export const DELETE_INVOICE_LINE_REQUEST_SENT = '[service-cases] DELETE_INVOICE_LINE_REQUEST_SENT';
export const DELETE_INVOICE_LINE_REQUEST_SUCCEEDED = '[service-cases] DELETE_INVOICE_LINE_REQUEST_SUCCEEDED';
export const DELETE_INVOICE_LINE_REQUEST_FAILED = '[service-cases] DELETE_INVOICE_LINE_REQUEST_FAILED';

export const CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST = '[service-cases] CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST';
export const CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_SENT = '[service-cases] CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_SENT';
export const CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_FAILED = '[service-cases] CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_FAILED';
export const CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_SUCCEEDED = '[service-cases] CREATE_INVOICE_FOR_ASSIGNMENT_REQUEST_SUCCEEDED';

export const GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST = '[service-cases] GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST';
export const GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SENT = '[service-cases] GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SENT';
export const GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SUCCEEDED = '[service-cases] GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_SUCCEEDED';
export const GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_FAILED = '[service-cases] GET_INVOICE_RELEVANT_LOCATIONS_DISTANCES_REQUEST_FAILED';

export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_SENT = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_SENT';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_SUCCEEDED = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_SUCCEEDED';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_FAILED = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_DAMAGE_LOCATION_REQUEST_FAILED';

export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_SENT = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_SENT';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_SUCCEEDED = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_SUCCEEDED';
export const FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_FAILED = '[service-cases] FILTER_CONTRACT_PARTNER_SERVICE_AREAS_BY_TOWING_LOCATION_REQUEST_FAILED';

export const BATCH_UPDATE_SERVICE_LINES_REQUEST = '[service-cases] BATCH_UPDATE_SERVICE_LINES_REQUEST';
export const BATCH_UPDATE_SERVICE_LINES_REQUEST_SENT = '[service-cases] BATCH_UPDATE_SERVICE_LINES_REQUEST_SENT';
export const BATCH_UPDATE_SERVICE_LINES_REQUEST_FAILED = '[service-cases] BATCH_UPDATE_SERVICE_LINES_REQUEST_FAILED';
export const BATCH_UPDATE_SERVICE_LINES_REQUEST_SUCCEEDED = '[service-cases] BATCH_UPDATE_SERVICE_LINES_REQUEST_SUCCEEDED';

export const FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST = '[service-cases] FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST';
export const FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_SENT = '[service-cases] FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_SENT';
export const FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_SUCCEEDED = '[service-cases] FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_SUCCEEDED';
export const FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_FAILED = '[service-cases] FETCH_INVOICE_READ_ONLY_APPROVAL_DATA_REQUEST_FAILED';

export const CREATE_INVOICE_CORRECTION_REQUEST = '[service-cases] CREATE_INVOICE_CORRECTION_REQUEST';
export const CREATE_INVOICE_CORRECTION_REQUEST_SENT = '[service-cases] CREATE_INVOICE_CORRECTION_REQUEST_SENT';
export const CREATE_INVOICE_CORRECTION_REQUEST_SUCCEEDED = '[service-cases] CREATE_INVOICE_CORRECTION_REQUEST_SUCCEEDED';
export const CREATE_INVOICE_CORRECTION_REQUEST_FAILED = '[service-cases] CREATE_INVOICE_CORRECTION_REQUEST_FAILED';

export const CREATE_INVOICE_ESTIMATION_REQUEST = '[service-cases] CREATE_INVOICE_ESTIMATION_REQUEST';
export const CREATE_INVOICE_ESTIMATION_REQUEST_SENT = '[service-cases] CREATE_INVOICE_ESTIMATION_REQUEST_SENT';
export const CREATE_INVOICE_ESTIMATION_REQUEST_SUCCEEDED = '[service-cases] CREATE_INVOICE_ESTIMATION_REQUEST_SUCCEEDED';
export const CREATE_INVOICE_ESTIMATION_REQUEST_FAILED = '[service-cases] CREATE_INVOICE_ESTIMATION_REQUEST_FAILED';

export const FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST = '[service-cases] FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST';
export const FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SENT = '[service-cases] FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SENT';
export const FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SUCCEEDED = '[service-cases] FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SUCCEEDED';
export const FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_FAILED = '[service-cases] FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_FAILED';

export const DELETE_INVOICE_REQUEST = '[service-cases] DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_REQUEST_SENT = '[service-cases] DELETE_INVOICE_REQUEST_SENT';
export const DELETE_INVOICE_REQUEST_SUCCEEDED = '[service-cases] DELETE_INVOICE_REQUEST_SUCCEEDED';
export const DELETE_INVOICE_REQUEST_FAILED = '[service-cases] DELETE_INVOICE_REQUEST_FAILED';

/**
 * Store action types
 */
export const SET_INVOICE_PERSISTENCE_STATE = '[service-cases] SET_INVOICE_PERSISTENCE_STATE';
export const STORE_INVOICES = '[service-cases] STORE_INVOICES';
export const STORE_INVOICE_LINES = '[service-cases] STORE_INVOICE_LINES';
export const STORE_SELECTED_INVOICE_UPLOADED_DOCUMENT = '[service-cases] STORE_SELECTED_INVOICE_UPLOADED_DOCUMENT';
export const SET_INVOICE_PARTNER_LOCATION_CANDIDATES = '[service-cases] SET_INVOICE_PARTNER_LOCATION_CANDIDATES';
export const STORE_SERVICES = '[service-cases] STORE_SERVICES';
export const STORE_INVOICES_SEARCH_RESULTS = '[service-cases] STORE_INVOICES_SEARCH_RESULTS';
export const STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS = '[service-cases] STORE_INVOICE_RELEVANT_LOCATIONS_DETAILS';
export const STORE_INVOICE_READ_ONLY_APPROVAL_DATA = '[service-cases] STORE_INVOICE_READ_ONLY_APPROVAL_DATA';
export const STORE_ACCOUNT_PARTY_OPTIONS = '[service-cases] STORE_ACCOUNT_PARTY_OPTIONS';
export const REMOVE_DELETED_INVOICE = '[service-cases] REMOVE_DELETED_INVOICE';
export const SET_MEMBER_LOCATION = '[service-cases] SET_MEMBER_LOCATION';
