import {put, fork, select, take} from 'redux-saga/effects';
import {etmTaskStatusTypes, etmTaskTypes} from '@ace-de/eua-entity-types';
import * as dashboardActionTypes from '../dashboardActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import dashboardScreenTabs from '../dashboardScreenTabs';

export const loadReporterTasksAmount = function* loadReporterTasksAmount({payload}) {
    const {location} = payload;
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const searchQueryParams = new URLSearchParams();

    if (location.query.activeTab === dashboardScreenTabs.DASHBOARD) return;

    searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);
    searchQueryParams.append('taskType', etmTaskTypes.LEA);

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_REPORTER_TASK_AMOUNT_REQUEST,
        ecsTaskManagementService.getTasks,
        {searchQueryParams},
    );
    const responseAction = yield take([
        dashboardActionTypes.FETCH_REPORTER_TASK_AMOUNT_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_REPORTER_TASK_AMOUNT_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskDTOs} = response;

        yield put({
            type: dashboardActionTypes.STORE_REPORTER_TASKS_AMOUNT,
            payload: {reporterTasksAmount: taskDTOs.length},
        });
    }
};

export default loadReporterTasksAmount;
