import {produce} from 'immer';
import {LeistContact, AccountParty} from '@ace-de/eua-entity-types';
import * as contactActionTypes from './contactActionTypes';

const initialState = {
    isContactSearchPerformed: false,
    contactsSearchResults: [],
    contactsSearchResultsCount: 0,
    isContactsSearchError: false,
    contacts: [],
    accountParty: {},
    selectedInvoiceContact: {},
    errorDescription: '',
};

/**
 * Contact reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const contactReducer = produce((draft, action) => {
    switch (action.type) {
        case contactActionTypes.SEARCH_CONTACTS: {
            draft.isContactSearchPerformed = true;
            break;
        }

        case contactActionTypes.RESET_CONTACT_SEARCH: {
            draft.isContactSearchPerformed = false;
            draft.contactsSearchResults = [];
            draft.contactsSearchResultsCount = 0;
            break;
        }

        case contactActionTypes.STORE_CONTACTS_SEARCH_RESULTS: {
            const {contactDTOs, totalCount} = action.payload;
            draft.contactsSearchResults = contactDTOs.map(contactDTO => (
                new LeistContact().fromDTO(contactDTO)
            ));
            draft.contactsSearchResultsCount = typeof totalCount === 'number' ? totalCount : 0;
            break;
        }

        case contactActionTypes.STORE_CONTACTS_SEARCH_ERROR: {
            const {isContactsSearchError} = action.payload;
            draft.isContactsSearchError = isContactsSearchError;
            break;
        }

        case contactActionTypes.STORE_CONTACTS: {
            const {contactDTOs} = action.payload;
            draft.contacts = contactDTOs.map(contactDTO => (
                new LeistContact().fromDTO(contactDTO)
            ));
            break;
        }

        case contactActionTypes.STORE_ACCOUNT_PARTY: {
            const {accountPartyDTO} = action.payload;
            draft.accountParty = new AccountParty().fromDTO(accountPartyDTO);
            break;
        }

        case contactActionTypes.SET_SELECTED_CONTACT: {
            const {contactDTOs} = action.payload;
            draft.selectedInvoiceContact = new LeistContact().fromDTO(contactDTOs[0]);
            break;
        }

        case contactActionTypes.SET_BC_ERROR_MESSAGE: {
            const {errorDescription} = action.payload;
            const exceptionMessage = errorDescription['exception_message'];
            const jsonString = exceptionMessage.match(/({[^}]+})/)[0] + '}';
            const parsedData = JSON.parse(jsonString);
            draft.errorDescription = parsedData.error.message;
            break;
        }

        default:
        //  no-op
    }
    return draft;
}, initialState);

export default contactReducer;
