import {createSelector} from 'reselect';
import moment from 'moment';
import {alfInvoiceStatusTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';

/**
 * General state selectors
 */
export const getInvoices = state => state.invoices.invoices;
export const getServices = state => state.invoices.services;

/**
 * Returns selector for Invoice matched from route
 */
export const createInvoicesSelector = () => createSelector([
    getInvoices,
    (state, props) => {
        const {match, location} = props;
        return match?.params['invoiceId'] || location?.query?.invoiceId;
    },
], (invoices, invoiceId) => {
    if (!invoices || !invoiceId) return null;
    return invoices[invoiceId];
});

/**
 * Return all invoices for service case from route
 */
export const createServiceCaseInvoicesSelector = () => createSelector([
    getInvoices,
    (state, props) => {
        const {match} = props;
        return (match?.params['serviceCaseId']);
    },
], (invoices, serviceCaseId) => {
    if (!invoices || !serviceCaseId) return [];

    return Object.values(invoices).filter(invoice => {
        return invoice.serviceCaseId === serviceCaseId;
    });
});

/**
 * Return all invoices with EKR type in statuses PAID and APPROVED per service case
 */
export const createInvoicesForForwardingSelector = () => createSelector([
    getInvoices,
    (state, props) => {
        const {match} = props;
        return (match?.params['serviceCaseId']);
    },
], (invoices, serviceCaseId) => {
    if (!invoices || !serviceCaseId) return [];

    return Object.values(invoices).filter(invoice => (
        invoice.serviceCaseId === serviceCaseId
        && invoice.type === alfInvoiceTypes.EKR
        && (invoice.status === alfInvoiceStatusTypes.APPROVED || invoice.status === alfInvoiceStatusTypes.PAID)
    ));
});

/**
 * Return invoice for service case from route and invoiceId query param
 */
export const createServiceCaseInvoiceSelector = () => createSelector([
    getInvoices,
    (state, props) => {
        const {match} = props;
        return (match?.params['serviceCaseId']);
    },
    (state, props) => {
        const {location} = props;
        return (location?.query?.['invoiceId']);
    },
], (invoices, serviceCaseId, invoiceId) => {
    if (!invoices || !serviceCaseId || !invoiceId) return null;

    return Object.values(invoices).filter(invoice => {
        return invoice.serviceCaseId === serviceCaseId;
    }).find(invoice => invoice.id === invoiceId);
});


/**
 * Return all invoices for service case from route grouped by account party
 */
export const createGroupedInvoicesByAccountPartySelector = () => createSelector([
    getInvoices,
    (state, props) => {
        const {match} = props;
        return (match?.params['serviceCaseId']);
    },
], (invoices, serviceCaseId) => {
    if (!invoices || !serviceCaseId) return [];

    // group invoices by accountPartyId
    const groupedInvoices = Object.values(invoices).filter(invoice => invoice.serviceCaseId === serviceCaseId)
        .reduce((acc, curr) => {
            (acc[curr.accountParty?.id] = acc[curr.accountParty?.id] || []).push(curr);
            return acc;
        }, {});

    // sort invoices inside the group
    for (const key in groupedInvoices) {
        if (groupedInvoices.hasOwnProperty(key) && groupedInvoices[key].length > 1) {
            groupedInvoices[key].sort((invoiceA, invoiceB) => {
                const createdAtA = moment(invoiceA.createdAt, 'DD.MM.YYYY').format('YYYY-MM-DD');
                const createdAtB = moment(invoiceB.createdAt, 'DD.MM.YYYY').format('YYYY-MM-DD');
                return moment(createdAtA).isSameOrAfter(moment(createdAtB)) ? 1 : -1;
            });
        }
    }

    // sort groups
    return Object.entries(groupedInvoices).sort(([, invoiceGroupA], [, invoiceGroupB]) => {
        const createdAtA = moment(invoiceGroupA[0].createdAt, 'DD.MM.YYYY').format('YYYY-MM-DD');
        const createdAtB = moment(invoiceGroupB[0].createdAt, 'DD.MM.YYYY').format('YYYY-MM-DD');
        return moment(createdAtA).isSameOrAfter(moment(createdAtB)) ? 1 : -1;
    });
});
