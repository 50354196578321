import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {useHistory} from '@computerrock/formation-router';
import {europeanCountries, alfClientTypes, alfAccountTypes, alfInvoiceTypes} from '@ace-de/eua-entity-types';
import {Modal, NoResultsBlock, ToggleTab, ToggleTabSwitch, useStyles, Paginator} from '@ace-de/ui-components';
import {Button, ButtonIcon, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {Form, InputField} from '@ace-de/ui-components/form';
import {Table, TableCaption, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {infoAlertIcon, Icon, InteractiveIcon, closeIcon, plusIcon, searchIcon, checkmarkIcon, editIcon} from '@ace-de/ui-components/icons';
import * as invoiceActionTypes from '../invoiceActionTypes';
import * as invoiceSelectors from '../invoiceSelectors';
import modalIds from '../../modalIds';
import * as contactActionTypes from '../../contacts/contactActionTypes';
import config from '../../config';

const initialInvoiceContactSearchParams = {
    client: alfClientTypes.ACE,
    ids: '',
    name: '',
    street: '',
    postCode: '',
    city: '',
};

// todo check if we still need this modal and remove it if it's redundant
const InvoiceContactSearchModal = props => {
    const {cx} = useStyles();
    const history = useHistory();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('invoice_contact_search_modal');
    const {hasBackdrop, invoiceContactsSearchResults, initiateInvoiceContactCreation, invoice} = props;
    const {isInvoiceContactsSearchError, isInvoiceContactSearchPerformed, initiateInvoiceAccountPartyCreation} = props;
    const {declineInvoiceContactSelection, searchInvoiceContacts, initiateInvoiceContactSelection} = props;
    const {invoiceContactsSearchResultsCount, resetContactsSearchResults, initiateEditContact} = props;

    const [formData, setFormData] = useState(initialInvoiceContactSearchParams);
    const [savedFormDataForSearchResults, setSavedFormDataForSearchResults] = useState(formData);
    const [selectedContact, setSelectedContact] = useState(null);
    const [queryParams, setQueryParams] = useState(new URLSearchParams(history.location.search));

    const isVKRInvoice = invoice?.type === alfInvoiceTypes.VKR;
    const paginatorCount = Math.ceil(invoiceContactsSearchResultsCount / config.DEFAULT_PAGE_SIZE);

    useEffect(() => () => resetContactsSearchResults(), [resetContactsSearchResults]);

    const formatQueryParams = formData => {
        if (!formData) return;
        const searchQueryParams = new URLSearchParams();

        Object.keys(formData).forEach(formField => {
            if (formData[formField] !== undefined && formData[formField] !== '') {
                searchQueryParams.append(`${formField}`, formData[formField]);
            }
        });

        return searchQueryParams;
    };

    const handleOnChange = (key, value) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleOnToggleSwitch = value => {
        handleOnChange('client', value);
        let isFormEmpty = true;
        Object.keys(savedFormDataForSearchResults).forEach(formField => {
            if (formField !== 'client' && !!savedFormDataForSearchResults[formField]) isFormEmpty = false;
        });
        if (isFormEmpty) return;

        const searchQueryParams = formatQueryParams({
            ...savedFormDataForSearchResults,
            client: value,
            modal: modalIds.INVOICE_CONTACT_SEARCH,
        });

        searchInvoiceContacts({searchQueryParams});

        setSelectedContact(null);
    };

    const handleOnSubmit = () => {
        const searchQueryParams = formatQueryParams({
            ...formData,
            modal: modalIds.INVOICE_CONTACT_SEARCH,
        });

        if (searchQueryParams.size === 2 && searchQueryParams.has('modal') && searchQueryParams.has('client')) return;

        searchInvoiceContacts({searchQueryParams});
        setSelectedContact(null);
        setSavedFormDataForSearchResults(formData);
    };

    const handleContactSelection = () => {
        initiateInvoiceContactSelection({selectedContact, isVKRInvoice});
    };

    const handleContactCreation = async () => {
        await declineInvoiceContactSelection();
        initiateInvoiceContactCreation({
            client: formData.client,
        });
    };

    const handleAccountPartyCreation = async selectedContact => {
        await declineInvoiceContactSelection();
        initiateInvoiceAccountPartyCreation({
            client: formData.client,
            accountPartyType: isVKRInvoice ? alfAccountTypes.DEBTOR : alfAccountTypes.CREDITOR,
            selectedContact,
        });
    };

    const handleEditContact = async selectedContact => {
        await declineInvoiceContactSelection();
        initiateEditContact({
            client: formData.client,
            selectedContact,
        });
    };

    const handlePaginationPage = page => {
        const searchQueryParams = formatQueryParams({
            ...formData,
            modal: modalIds.INVOICE_CONTACT_SEARCH,
        });
        searchQueryParams.set('page', `${page}`);
        searchQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        searchInvoiceContacts({searchQueryParams});
        setSelectedContact(null);
        setQueryParams(searchQueryParams);
    };

    const handleOnKeyDown = useCallback(event => {
        if (event.key === 'Escape') declineInvoiceContactSelection();
    }, [declineInvoiceContactSelection]);

    useEffect(() => {
        document.addEventListener('keydown', handleOnKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleOnKeyDown, true);
        };
    }, [handleOnKeyDown]);

    const hasAccountPartyId = selectedContact && !!(isVKRInvoice
        ? selectedContact.debtorId
        : selectedContact.creditorId
    );

    return (
        <Modal
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineInvoiceContactSelection}
                />
            )}
            hasBackdrop={hasBackdrop}
            hasColoredHeader={true}
            hasContentPadding={false}
            title={translateModal('modal_title.contact_search')}
            contentClassName={cx(['ace-c-modal__content--scrollable', 'global!ace-u-padding-0'])}
        >
            <div
                className={cx([
                    'global!ace-u-padding--32-32-0',
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-space-between',
                    'global!ace-u-flex--align-center',
                ])}
            >
                <ToggleTabSwitch
                    name="clientBCSearch"
                    className={cx('global!ace-u-margin--bottom-24')}
                    onChange={value => handleOnToggleSwitch(value)}
                    value={queryParams.get('client') || formData.client}
                >
                    {Object.values(alfClientTypes).map(tab => (
                        <ToggleTab
                            key={tab}
                            name={`${tab.toLowerCase()}Tab`}
                            value={tab}
                        >
                            {tab}
                        </ToggleTab>
                    ))}
                </ToggleTabSwitch>
                <Button onClick={handleContactCreation}>
                    <Icon
                        className={cx([
                            'global!ace-c-icon--color-highlight',
                            'global!ace-c-icon--s',
                            'global!ace-u-margin--right-8',
                        ])}
                        icon={plusIcon}
                    />
                    {translateModal('button.create_contact')}
                </Button>
            </div>
            <div className={cx(['global!ace-u-padding--0-32', 'global!ace-u-width--full'])}>
                <Form name="invoiceContactSearch" onSubmit={handleOnSubmit}>
                    <p
                        className={cx([
                            'global!ace-u-margin--24-0',
                            'global!ace-u-typography--variant-body-bold',
                        ])}
                    >
                        {translateModal('form_title.contact_data')}
                    </p>
                    <div
                        className={cx([
                            'global!ace-u-margin--bottom-40',
                            'global!ace-u-width--full',
                            'global!ace-u-grid',
                            'global!ace-u-flex--align-flex-end',
                            'global!ace-u-flex--justify-space-between',
                        ])}
                        onKeyDown={event => {
                            if (event.key === 'Enter') handleOnSubmit();
                        }}
                    >
                        {Object.keys(formData).map(formField => {
                            if (formField === 'client') {
                                return null;
                            }
                            return (
                                <div key={formField} className={cx('global!ace-u-grid-column--span-2')}>
                                    <InputField
                                        name={formField}
                                        label={translateModal(`input_label.${formField}`)}
                                        value={queryParams.get(formField) || formData[formField]}
                                        className={cx([
                                            'global!ace-u-width--full',
                                        ])}
                                        onChange={value => handleOnChange(formField, value)}
                                    />
                                </div>
                            );
                        })}
                        <ButtonIcon
                            name="searchButton"
                            icon={searchIcon}
                            type="submit"
                            className={cx([
                                'global!ace-u-flex--basis-5',
                                'global!ace-u-width--64',
                                'global!ace-u-height--48',
                            ])}
                        />
                    </div>
                </Form>
            </div>
            <div className={cx('global!ace-u-flex', 'global!ace-u-flex--align-center', 'global!ace-u-flex--direction-column')}>
                <Table qaIdent="invoice-contacts-search-results" className={cx('global!ace-u-padding--24')}>
                    {isInvoiceContactSearchPerformed
                    && (isInvoiceContactsSearchError || invoiceContactsSearchResults.length === 0)
                    && (
                        <TableCaption>
                            <NoResultsBlock
                                colorVariant={!isInvoiceContactsSearchError ? 'primary-highlight' : 'negative'}
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl', {
                                            'global!ace-c-icon--color-highlight': !isInvoiceContactsSearchError,
                                            'global!ace-c-icon--color-warning': isInvoiceContactsSearchError,
                                        })}
                                        icon={infoAlertIcon}
                                    />
                                )}
                                description={!isInvoiceContactsSearchError
                                    ? translateModal('no_results.description')
                                    : translateModal('results_error.description')
                                }
                                message={!isInvoiceContactsSearchError
                                    ? translateModal('no_results.message')
                                    : translateModal('results_error.message')
                                }
                            />
                        </TableCaption>
                    )}
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-id">
                                {translateModal('table_data_row.contact_id')}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPart="invoice-contact-type">
                                {translateModal('table_data_row.type')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-business-relations">
                                {translateModal('table_data_row.businessRelation')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-name">
                                {translateModal('table_data_row.name')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-address-street">
                                {translateModal('table_data_row.address')}
                            </TableCell>
                            <TableCell colSpan={1} qaIdentPart="invoice-contact-address-post-code">
                                {translateModal('table_data_row.postCode')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-address-city">
                                {translateModal('table_data_row.city')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-address-country">
                                {translateModal('table_data_row.country')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-creditor-id">
                                {translateModal('table_data_row.creditor')}
                            </TableCell>
                            <TableCell colSpan={2} qaIdentPart="invoice-contact-debtor-id">
                                {translateModal('table_data_row.debtor')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isInvoiceContactsSearchError && invoiceContactsSearchResults.map(contact => (
                            <TableRow
                                key={contact.id}
                                onClick={() => setSelectedContact(contact)}
                                className={cx({
                                    'global!ace-u-typography--variant-body-bold': selectedContact?.id === contact.id,
                                })}
                                qaIdentPart={contact.id}
                            >
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-id" qaIdentPartPostfix={contact.id}>
                                    {contact.id || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-contact-type" qaIdentPartPostfix={contact.id}>
                                    {translateModal(`contact_type.${contact.type.toLowerCase()}`)}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-business-relations" qaIdentPartPostfix={contact.id}>
                                    {contact.businessRelations.length > 0
                                        ? contact.businessRelations.map((businessRelation, idx) => (
                                            `${translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)}${idx < (contact.businessRelations.length - 1) ? ', ' : ''}`
                                        ))
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-name" qaIdentPartPostfix={contact.id}>
                                    {contact.name || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-address-street" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.street || '-'}
                                </TableCell>
                                <TableCell colSpan={1} qaIdentPart="invoice-contact-address-post-code" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.postCode || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-address-city" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.city || '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-address-country" qaIdentPartPostfix={contact.id}>
                                    {contact.address?.country
                                        ? Object.values(europeanCountries).find(country => {
                                            return country?.name === contact.address.country;
                                        }) ? translate(
                                                `global.country.${snakeCase(contact.address.country)}`,
                                            ) : translate('global.country.other')
                                        : '-'}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-creditor-id" qaIdentPartPostfix={contact.id}>
                                    {contact.creditorId
                                        ? (
                                            <Icon
                                                className={cx('global!ace-c-icon--color-success')}
                                                icon={checkmarkIcon}
                                            />
                                        )
                                        : <Icon icon={closeIcon} />}
                                </TableCell>
                                <TableCell colSpan={2} qaIdentPart="invoice-contact-debtor-id" qaIdentPartPostfix={contact.id}>
                                    {contact.debtorId
                                        ? (
                                            <Icon
                                                className={cx('global!ace-c-icon--color-success')}
                                                icon={checkmarkIcon}
                                            />
                                        )
                                        : <Icon icon={closeIcon} />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {invoiceContactsSearchResultsCount > 0 && (
                    <Paginator
                        page={+queryParams.get('page')}
                        count={paginatorCount}
                        onClick={page => handlePaginationPage(page)}
                    />
                )}
            </div>
            {(!isInvoiceContactSearchPerformed || invoiceContactsSearchResultsCount > 0) && (
                <div
                    className={cx([
                        'global!ace-u-margin--32',
                        'global!ace-u-width--full',
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--justify-flex-end',
                    ])}
                >
                    <Button isDisabled={!selectedContact} onClick={() => handleEditContact(selectedContact)}>
                        <Icon
                            icon={editIcon}
                            className={cx('global!ace-u-margin--right-8', {
                                'global!ace-c-icon--color-disabled': !selectedContact,
                                'global!ace-c-icon--color-highlight': selectedContact,
                            })}
                        />
                        {translateModal('button_label.edit_contact')}
                    </Button>
                    {!hasAccountPartyId && (
                        <ButtonPrimary
                            isDisabled={!selectedContact}
                            className={cx('global!ace-u-margin--left-24')}
                            onClick={() => handleAccountPartyCreation(selectedContact)}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx('global!ace-c-icon--color-contrast', 'global!ace-u-margin--right-8')}
                            />
                            {translateModal(`button_label.add_${isVKRInvoice ? 'debtor' : 'creditor'}`)}
                        </ButtonPrimary>
                    )}
                    {selectedContact && hasAccountPartyId && (
                        <ButtonPrimary
                            isDisabled={!selectedContact}
                            className={cx('global!ace-u-margin--left-24')}
                            onClick={handleContactSelection}
                        >
                            <Icon
                                icon={plusIcon}
                                className={cx('global!ace-c-icon--color-contrast', 'global!ace-u-margin--right-8')}
                            />
                            {translateModal(`button_label.select_${isVKRInvoice ? 'debtor' : 'creditor'}`)}
                        </ButtonPrimary>
                    )}
                </div>
            )}
        </Modal>
    );
};

InvoiceContactSearchModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    invoice: PropTypes.object,
    declineInvoiceContactSelection: PropTypes.func.isRequired,
    searchInvoiceContacts: PropTypes.func.isRequired,
    initiateInvoiceContactSelection: PropTypes.func.isRequired,
    invoiceContactsSearchResults: PropTypes.array,
    isInvoiceContactsSearchError: PropTypes.bool,
    isInvoiceContactSearchPerformed: PropTypes.bool,
    initiateInvoiceContactCreation: PropTypes.func.isRequired,
    initiateInvoiceAccountPartyCreation: PropTypes.func.isRequired,
    initiateEditContact: PropTypes.func.isRequired,
    resetContactsSearchResults: PropTypes.func.isRequired,
    invoiceContactsSearchResultsCount: PropTypes.number,
};

InvoiceContactSearchModal.defaultProps = {
    hasBackdrop: false,
    invoice: null,
    invoiceContactsSearchResults: [],
    isInvoiceContactsSearchError: false,
    isInvoiceContactSearchPerformed: false,
    invoiceContactsSearchResultsCount: 0,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    return {
        invoice: getInvoice(state, props),
        invoiceContactsSearchResults: state.contacts.contactsSearchResults,
        invoiceContactsSearchResultsCount: state.contacts.contactsSearchResultsCount,
        isInvoiceContactsSearchError: state.contacts.isContactsSearchError,
        isInvoiceContactSearchPerformed: state.contacts.isContactSearchPerformed,
    };
};

const mapDispatchToProps = dispatch => ({
    declineInvoiceContactSelection: () => dispatch({
        type: invoiceActionTypes.DECLINE_INVOICE_CONTACT_SEARCH,
    }),
    searchInvoiceContacts: payload => dispatch({
        type: contactActionTypes.SEARCH_CONTACTS,
        payload,
    }),
    initiateInvoiceContactSelection: payload => dispatch({
        type: invoiceActionTypes.SELECT_INVOICE_CONTACT,
        payload,
    }),
    initiateInvoiceContactCreation: payload => dispatch({
        type: contactActionTypes.INITIATE_CREATE_CONTACT_FLOW,
        payload,
    }),
    initiateInvoiceAccountPartyCreation: payload => dispatch({
        type: contactActionTypes.INITIATE_CREATE_ACCOUNT_PARTY_FLOW,
        payload,
    }),
    resetContactsSearchResults: () => dispatch({
        type: contactActionTypes.RESET_CONTACT_SEARCH,
    }),
    initiateEditContact: payload => dispatch({
        type: contactActionTypes.INITIATE_EDIT_CONTACT_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContactSearchModal);
