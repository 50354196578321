import {take, put, fork, select} from 'redux-saga/effects';
import {closeModal} from '@computerrock/formation-router/sagas';
import {alfMessageChannelTypes} from '@ace-de/eua-entity-types';
import {replace, resolveRoute} from '@computerrock/formation-router';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../contactActionTypes';
import * as communicationActionTypes from '../../communication/communicationActionTypes';
import modalIds from '../../modalIds';
import bcModalContentTypes from '../modals/bcModalContentTypes';

const startCommunicationModalFlow = function* startCommunicationModalFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const alfFileAssetsService = serviceManager.loadService('alfFileAssetsService');

    while (true) {
        yield take(contactActionTypes.INITIATE_CONTACT_COMMUNICATION_MODAL_FLOW);

        const {location} = yield select(state => state.router);
        const currentParams = new URLSearchParams(location.search);
        const searchParams = new URLSearchParams();
        searchParams.set('modal', currentParams.get('modal'));
        searchParams.set('contentType', bcModalContentTypes.COMMUNICATION);
        searchParams.set('contactId', currentParams.get('contactId'));

        yield put(replace(resolveRoute(location.pathname, {}, {search: searchParams.toString()})));

        const modalAction = yield take([
            contactActionTypes.DECLINE_BC_CONTACT_MODAL_FLOW,
            communicationActionTypes.CONFIRM_SEND_MESSAGE,
        ]);

        if (modalAction.type === communicationActionTypes.CONFIRM_SEND_MESSAGE) {
            const {payload} = modalAction;
            if (payload) {
                yield fork(
                    fetchRequest,
                    communicationActionTypes.SEND_MESSAGE_REQUEST,
                    leaAssignmentFlowService.sendMessage,
                    {
                        messageData: payload,
                    },
                );

                const loaderSearchParams = new URLSearchParams(location.search);
                loaderSearchParams.set('contentType', bcModalContentTypes.LOADER);
                loaderSearchParams.set('type', 'CONTACT_CREATION');

                yield put(replace(resolveRoute(location.pathname, {}, {search: loaderSearchParams.toString()})));

                const sendMessageResponseAction = yield take([
                    communicationActionTypes.SEND_MESSAGE_REQUEST_FAILED,
                    communicationActionTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
                ]);
                if (sendMessageResponseAction.error) {
                    // todo handle error
                }

                if (!sendMessageResponseAction.error) {
                    const {response} = sendMessageResponseAction.payload;
                    const {messageDataDTO} = response;

                    if (messageDataDTO.channel === alfMessageChannelTypes.MAIL
                        && messageDataDTO.attachments?.length > 0) {
                        const relevantAttachments = messageDataDTO.attachments.filter(attachment => (
                            !!attachment.isInternal
                        ));
                        const {fileName, url} = relevantAttachments[0];
                        yield fork(
                            fetchRequest,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST,
                            alfFileAssetsService.downloadFile,
                            {
                                fileName,
                                url,
                                shouldOpenFile: true,
                            },
                        );
                        yield take([
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_SUCCEEDED,
                            communicationActionTypes.DOWNLOAD_MESSAGE_PDF_REQUEST_FAILED,
                        ]);
                    }
                    yield put(replace(resolveRoute(location.pathname, {}, {search: currentParams.toString()})));
                    continue;
                }
            }
        }

        yield put({
            type: contactActionTypes.RELOAD_CREATE_ACCOUNT_MODAL_FLOW,
            payload: null,
        });
        yield* closeModal(modalIds.BC_CONTACT_MODAL, {
            contactId: '',
            contentType: '',
        });
    }
};

export default startCommunicationModalFlow;
