import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';
import {snakeCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, SearchBox, useStyles, RadioButton, Button} from '@ace-de/ui-components';
import {Icon, plusIcon, warningAlertIcon, editIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {Table, TableBody, TableHead, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {ampVehicleFuelTypes, ampVehicleSpecialFeatureTypes} from '@ace-de/eua-entity-types';
import * as memberActionTypes from '../../members/memberActionTypes';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import * as serviceCaseActionTypes from '../serviceCaseActionTypes';
import config from '../../config';

const VehicleSelectPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('vehicle_select_panel');
    const {searchVehicles, memberVehicleSearchResults, initiateEditVehicleFlow} = props;
    const {initiateVehicleCreation, submitServiceCaseVehicleDataForm, initiateVehicleSelectionFlow} = props;
    const {serviceCase, selectedVehicle, memberVehicles, isVehicleSelectionMandatory} = props;

    const [searchQueryString, setSearchQueryString] = useState('');
    const searchVehiclesDebounced = useMemo(() => debounce(searchVehicles, 250), [searchVehicles]);

    const handleOnSearchQueryChange = searchValue => {
        setSearchQueryString(searchValue);
        if (searchValue && searchValue.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchVehiclesDebounced({
                searchQueryString: searchValue,
                membershipNo: serviceCase?.member?.membershipNo,
            });
        }
    };

    const handleVehicleSelected = vehicleId => {
        if (vehicleId) {
            const vehicle = memberVehicles.find(vehicle => vehicle.id === vehicleId);
            if (vehicle) {
                const {
                    id,
                    vehicleId,
                    specialFeatures,
                    licensePlateCountryFlag,
                    owner,
                    driver,
                    ...restVehicle
                } = vehicle;

                const serviceCaseData = {
                    vehicle: {
                        ...restVehicle,
                        id: id || vehicleId,
                        specialFeatures: specialFeatures === ampVehicleSpecialFeatureTypes.UNKNOWN
                        || !specialFeatures.length
                            ? [ampVehicleSpecialFeatureTypes.UNKNOWN]
                            : specialFeatures,
                    },
                };

                // if no selected vehicle & no draft, do not show the confirmation modal
                if (!selectedVehicle && !(serviceCase.vehicle && !serviceCase.vehicle?.id)) {
                    submitServiceCaseVehicleDataForm({
                        serviceCaseId: serviceCase.id,
                        serviceCaseData,
                    });
                    return;
                }

                // open confirmation modal
                initiateVehicleSelectionFlow({
                    serviceCaseData,
                    serviceCaseId: serviceCase.id,
                });
            }
        }
    };

    if (!serviceCase) return null;

    const connectedMemberVehicles = searchQueryString
        ? memberVehicleSearchResults
        : memberVehicles?.filter(vehicle => {
            if (selectedVehicle && vehicle.id === selectedVehicle.id) return true;
            return vehicle.isActive;
        });
    const hasDraftVehicle = !!serviceCase.vehicle && !(serviceCase.vehicle?.id);
    const sortedVehicles = connectedMemberVehicles?.length > 1
        ? connectedMemberVehicles.slice().sort((memberVehicleA, memberVehicleB) => {
            return memberVehicleA.licensePlateNumber > memberVehicleB.licensePlateNumber ? 1 : -1;
        })
        : connectedMemberVehicles;

    return (
        <Panel
            className={cx('ace-c-panel--full-bleed-content')}
            title={isVehicleSelectionMandatory
                ? `${translatePanel('title.already_registered_vehicles')}*`
                : translatePanel('title.already_registered_vehicles')}
            actions={(
                <SearchBox
                    value={searchQueryString}
                    onChange={handleOnSearchQueryChange}
                    isDropDownDisabled={true}
                    isButtonDisabled={searchQueryString?.length > 0
                    && searchQueryString?.length < config.MINIMUM_SEARCH_QUERY_LENGTH}
                    placeholder={translatePanel('input_placeholder.search_by_license_plate')}
                />
            )}
        >
            <Table qaIdent="vehicle-select-table">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="vehicle-option" colSpan={1} />
                        <TableCell qaIdentPart="vehicle-manufacturer" colSpan={3}>
                            {translatePanel('table_header.manufacturer')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-model" colSpan={5}>
                            {translatePanel('table_header.model')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-licence-plate-number" colSpan={2}>
                            {translatePanel('table_header.license_plate_no')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-build-year" colSpan={2}>
                            {translatePanel('table_header.build_year')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-fuel-type" colSpan={2}>
                            {translatePanel('table_header.fuel_type')}
                        </TableCell>
                        <TableCell qaIdentPart="vehicle-weight" colSpan={2}>
                            {translatePanel('table_header.weight')}
                        </TableCell>
                        <TableCell colSpan={1} />
                    </TableRow>
                </TableHead>
                {hasDraftVehicle && (
                    <TableBody>
                        <TableRow
                            key={`draft-${serviceCase.vehicle.manufacturer}`}
                            className={cx([
                                'global!ace-u-typography--variant-body-bold',
                                'global!ace-u-typography--color-warning',
                            ])}
                            qaIdentPart={`draft-${serviceCase.vehicle.manufacturer}`}
                        >
                            <TableCell
                                colSpan={1}
                                qaIdentPart="vehicle-option"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                <Icon
                                    icon={warningAlertIcon}
                                    className={cx('ace-c-icon--color-warning')}
                                />
                            </TableCell>
                            <TableCell
                                colSpan={3}
                                qaIdentPart="vehicle-manufacturer"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {serviceCase.vehicle.manufacturer}
                            </TableCell>
                            <TableCell
                                colSpan={5}
                                qaIdentPart="vehicle-model"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {`${serviceCase.vehicle.model} ${serviceCase.vehicle.variant}`}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="vehicle-licence-plate-number"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {serviceCase.vehicle.licensePlateNumber}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="vehicle-build-year"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {serviceCase.vehicle.buildYear}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="vehicle-fuel-type"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {serviceCase.vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                    ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[serviceCase.vehicle.fuelType])}`)
                                    : ''}
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                qaIdentPart="vehicle-weight"
                                qaIdentPartPostfix={`draft-${serviceCase.vehicle.manufacturer}`}
                            >
                                {serviceCase.vehicle.weight?.toLocaleString(activeLocale) || ''}
                            </TableCell>
                            <TableCell colSpan={1} />
                        </TableRow>
                    </TableBody>
                )}
                {sortedVehicles.length > 0 && (
                    <TableBody>
                        {sortedVehicles.map(vehicle => (
                            <TableRow
                                key={vehicle.id}
                                qaIdentPart={vehicle.id}
                                className={cx('', {
                                    'global!ace-u-typography--color-disabled-main': selectedVehicle?.id === vehicle.id && !vehicle.isActive,
                                    'global!ace-u-typography--text-decoration-line-through': selectedVehicle?.id === vehicle.id && !vehicle.isActive,
                                })}
                            >
                                <TableCell
                                    colSpan={1}
                                    qaIdentPart="vehicle-option"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    <RadioButton
                                        name={`vehicle-${vehicle.id}`}
                                        value={vehicle.id}
                                        isSelected={selectedVehicle?.id === vehicle.id}
                                        onChange={() => handleVehicleSelected(vehicle.id)}
                                    />
                                </TableCell>
                                <TableCell
                                    colSpan={3}
                                    qaIdentPart="vehicle-manufacturer"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {vehicle.manufacturer}
                                </TableCell>
                                <TableCell
                                    colSpan={5}
                                    qaIdentPart="vehicle-model"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {`${vehicle.model} ${vehicle.variant}`}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="vehicle-licence-plate-number"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {vehicle.licensePlateNumber}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="vehicle-build-year"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {vehicle.buildYear}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="vehicle-fuel-type"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {vehicle.fuelType !== ampVehicleFuelTypes.UNKNOWN
                                        ? translate(`global.vehicle_fuel_type.${snakeCase(ampVehicleFuelTypes[vehicle.fuelType])}`)
                                        : ''}
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    qaIdentPart="vehicle-weight"
                                    qaIdentPartPostfix={vehicle.id}
                                >
                                    {vehicle.weight?.toLocaleString(activeLocale) || ''}
                                </TableCell>
                                <TableCell colSpan={1}>
                                    <InteractiveIcon
                                        icon={editIcon}
                                        onClick={() => initiateEditVehicleFlow({vehicleId: vehicle.id})}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
            <div
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-row-reverse',
                ])}
            >
                <Button
                    onClick={initiateVehicleCreation}
                    className={cx([
                        'global!ace-u-margin--bottom-32',
                        'global!ace-u-margin--top-32',
                        'global!ace-u-margin--right-32',
                    ])}
                >
                    <Icon
                        icon={hasDraftVehicle ? warningAlertIcon : plusIcon}
                        className={cx([
                            'ace-c-icon--color-highlight',
                            'global!ace-u-margin--right-8',
                        ])}
                    />
                    {hasDraftVehicle
                        ? translatePanel('button_label.complete_vehicle_creation')
                        : translatePanel('button_label.create_new_vehicle')}
                </Button>
            </div>
        </Panel>
    );
};

VehicleSelectPanel.propTypes = {
    serviceCase: PropTypes.object,
    selectedVehicle: PropTypes.object,
    memberVehicleSearchResults: PropTypes.array,
    memberVehicles: PropTypes.array,
    searchVehicles: PropTypes.func.isRequired,
    submitServiceCaseVehicleDataForm: PropTypes.func.isRequired,
    initiateVehicleCreation: PropTypes.func.isRequired,
    initiateVehicleSelectionFlow: PropTypes.func.isRequired,
    initiateEditVehicleFlow: PropTypes.func.isRequired,
    isVehicleSelectionMandatory: PropTypes.bool,
};

VehicleSelectPanel.defaultProps = {
    serviceCase: null,
    selectedVehicle: null,
    memberVehicleSearchResults: [],
    memberVehicles: [],
    isVehicleSelectionMandatory: false,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const memberVehiclesSelector = serviceCaseSelectors.createMemberVehiclesSelector();

    return {
        serviceCase: getServiceCase(state, props),
        memberVehicleSearchResults: state.members.memberVehicleSearchResults,
        memberVehicles: memberVehiclesSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchVehicles: payload => dispatch({type: memberActionTypes.SEARCH_MEMBER_VEHICLES, payload}),
    initiateVehicleCreation: () => dispatch({
        type: serviceCaseActionTypes.INITIATE_SERVICE_CASE_VEHICLE_CREATION_FLOW,
    }),
    submitServiceCaseVehicleDataForm: payload => dispatch({
        type: serviceCaseActionTypes.SUBMIT_SERVICE_CASE_VEHICLE_DATA_FORM,
        payload,
    }),
    initiateVehicleSelectionFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SERVICE_CASE_VEHICLE_SELECTION_FLOW,
        payload,
    }),
    initiateEditVehicleFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_EDIT_SERVICE_CASE_VEHICLE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleSelectPanel));
