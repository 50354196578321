import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {efPersonTypes, Person} from '@ace-de/eua-entity-types';
import {useStyles, Panel} from '@ace-de/ui-components';
import {Form, SelectField, Option, InputField, ToggleSwitch} from '@ace-de/ui-components/form';
import {useTranslate} from '@computerrock/formation-i18n';

const DriverAndOwnerForm = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateComponent = createTranslateShorthand('driver_and_owner_panel');
    const {driverData, ownerData, requiredFields, handleOnChange} = props;

    const [driverFormData, setDriverFormData] = useState({
        ...(driverData || new Person()),
    });
    const [ownerFormData, setOwnerFormData] = useState({
        ...(ownerData || new Person()),
    });

    const handleFormChanging = (formName, formValues) => {
        const updateMethod = formName === 'vehicleOwnerForm' ? setOwnerFormData : setDriverFormData;
        const dataSet = formName === 'vehicleOwnerForm' ? ownerFormData : driverFormData;
        const updatedData = {
            ...formValues,
            ...(!formValues.address?.street
                ? {address: dataSet.address}
                : {}),
        };
        updateMethod(updatedData);

        if (typeof handleOnChange === 'function') {
            handleOnChange(formName, updatedData);
        }
    };

    return (
        <Fragment>
            <Panel title={`${translateComponent('panel_title.owner')}${requiredFields.includes('ownerData') ? '*' : ''}`}>
                <Form name="vehicleOwnerForm" onChange={formValues => handleFormChanging('vehicleOwnerForm', formValues)}>
                    {ownerFormValues => {
                        const allowedTypeOptions = ownerFormValues.isIdenticalToMember
                            ? [efPersonTypes.MEMBER]
                            : Object.keys(efPersonTypes)
                                .filter(type => ![efPersonTypes.UNKNOWN, efPersonTypes.MEMBER].includes(type));
                        return (
                            <div className={cx('global!ace-u-grid')}>
                                <SelectField
                                    name="isIdenticalToMember"
                                    className={cx('global!ace-u-grid-column--span-12')}
                                    label={translateComponent('select_label.is_owner_member')}
                                    placeholder={translateComponent('select_placeholder.please_select')}
                                    value={typeof ownerData.isIdenticalToMember === 'boolean'
                                        ? ownerData.isIdenticalToMember
                                        : ownerData.memberRelationship
                                        && ownerData.memberRelationship === efPersonTypes.UNKNOWN
                                            ? efPersonTypes.UNKNOWN
                                            : ''
                                    }
                                >
                                    <Option
                                        name="isIdenticalToMemberYes"
                                        value={true}
                                    >
                                        {translateComponent('select_option_label.yes')}
                                    </Option>
                                    <Option
                                        name="isIdenticalToMemberNo"
                                        value={false}
                                    >
                                        {translateComponent('select_option_label.no')}
                                    </Option>
                                    <Option
                                        name="isIdenticalToMemberUnknown"
                                        value="UNKNOWN"
                                    >
                                        {translateComponent('select_option_label.unknown')}
                                    </Option>
                                </SelectField>
                                {typeof ownerFormValues.isIdenticalToMember === 'boolean'
                                && !ownerFormValues.isIdenticalToMember
                                && (
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-grid-column--span-12',
                                        ])}
                                    >
                                        <SelectField
                                            className={cx('global!ace-u-grid-column--span-12')}
                                            name="memberRelationship"
                                            value={ownerData.memberRelationship
                                                ? (ownerData.memberRelationship === efPersonTypes.UNKNOWN
                                                    ? ''
                                                    : ownerData.memberRelationship
                                                )
                                                : ''}
                                            label={translateComponent('select_label.relationship_with')}
                                            placeholder={translateComponent('select_placeholder.please_select')}
                                        >
                                            {
                                                allowedTypeOptions.map(personType => {
                                                    const type = personType.toLowerCase();
                                                    return (
                                                        <Option
                                                            name={`relationshipWithMember${type}`}
                                                            value={personType}
                                                            key={`ownersRelationship${type}`}
                                                        >
                                                            {translate(`global.member_relationship_type.${type}`)}
                                                        </Option>
                                                    );
                                                })
                                            }
                                        </SelectField>
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-6')}
                                            name="firstName"
                                            value={ownerData.name?.split(' ')[0] || ''}
                                            label={translateComponent('input_label.first_name')}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-6')}
                                            name="lastName"
                                            label={translateComponent('input_label.last_name')}
                                            value={ownerData.name?.split(' ')[1] || ''}
                                        />
                                        <ToggleSwitch
                                            name="addressIdenticalToMember"
                                            value={true}
                                            isSelected={!!ownerData.addressIdenticalToMember}
                                            className={cx([
                                                'global!ace-u-grid-column--span-12',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-row-reverse',
                                                'global!ace-u-flex--justify-space-between',
                                            ])}
                                        >
                                            {translateComponent('toggle_switch_label.is_address_same')}
                                        </ToggleSwitch>
                                        {!ownerFormValues.addressIdenticalToMember && (
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid',
                                                    'global!ace-u-grid-column--span-12',
                                                ])}
                                            >
                                                <Form name="address">
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-12')}
                                                        name="street"
                                                        value={ownerData.address?.street || ''}
                                                        label={translateComponent('input_label.street_and_housenumber')}
                                                    />
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-4')}
                                                        name="postCode"
                                                        value={ownerData.address?.postCode || ''}
                                                        label={translateComponent('input_label.post_code')}
                                                    />
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-8')}
                                                        name="city"
                                                        value={ownerData.address?.city || ''}
                                                        label={translateComponent('input_label.city')}
                                                    />
                                                </Form>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Form>
            </Panel>
            <Panel title={`${translateComponent('panel_title.driver')}${requiredFields.includes('driverData') ? '*' : ''}`}>
                <Form name="vehicleDriverForm" onChange={formValues => handleFormChanging('vehicleDriverForm', formValues)}>
                    {driverFormValues => {
                        const allowedTypeOptions = driverFormValues.isIdenticalToMember
                            ? [efPersonTypes.MEMBER]
                            : Object.keys(efPersonTypes)
                                .filter(type => ![efPersonTypes.UNKNOWN, efPersonTypes.MEMBER].includes(type));
                        return (
                            <div className={cx('global!ace-u-grid')}>
                                <SelectField
                                    name="isIdenticalToMember"
                                    className={cx('global!ace-u-grid-column--span-12')}
                                    label={translateComponent('select_label.is_driver_member')}
                                    placeholder={translateComponent('select_placeholder.please_select')}
                                    value={typeof driverData.isIdenticalToMember === 'boolean'
                                        ? driverData.isIdenticalToMember
                                        : driverData.memberRelationship
                                        && driverData.memberRelationship === efPersonTypes.UNKNOWN
                                            ? efPersonTypes.UNKNOWN
                                            : ''
                                    }
                                >
                                    <Option
                                        name="isIdenticalToMemberYes"
                                        value={true}
                                    >
                                        {translateComponent('select_option_label.yes')}
                                    </Option>
                                    <Option
                                        name="isIdenticalToMemberNo"
                                        value={false}
                                    >
                                        {translateComponent('select_option_label.no')}
                                    </Option>
                                    <Option
                                        name="isIdenticalToMemberUnknown"
                                        value="UNKNOWN"
                                    >
                                        {translateComponent('select_option_label.unknown')}
                                    </Option>
                                </SelectField>
                                {typeof driverFormValues.isIdenticalToMember === 'boolean'
                                && !driverFormValues.isIdenticalToMember
                                && (
                                    <div
                                        className={cx([
                                            'global!ace-u-grid',
                                            'global!ace-u-grid-column--span-12',
                                        ])}
                                    >
                                        <SelectField
                                            className={cx('global!ace-u-grid-column--span-12')}
                                            name="memberRelationship"
                                            value={driverData.memberRelationship
                                                ? (driverData.memberRelationship === efPersonTypes.UNKNOWN
                                                    ? ''
                                                    : ownerData.memberRelationship
                                                )
                                                : ''}
                                            label={translateComponent('select_label.relationship_with')}
                                            placeholder={translateComponent('select_placeholder.please_select')}
                                        >
                                            {
                                                allowedTypeOptions.map(personType => {
                                                    const type = personType.toLowerCase();
                                                    return (
                                                        <Option
                                                            name={`relationshipWithMember${type}`}
                                                            value={personType}
                                                            key={`ownersRelationship${type}`}
                                                        >
                                                            {translate(`global.member_relationship_type.${type}`)}
                                                        </Option>
                                                    );
                                                })
                                            }
                                        </SelectField>
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-6')}
                                            name="firstName"
                                            value={driverData.name?.split(' ')[0] || ''}
                                            label={translateComponent('input_label.first_name')}
                                        />
                                        <InputField
                                            className={cx('global!ace-u-grid-column--span-6')}
                                            name="lastName"
                                            label={translateComponent('input_label.last_name')}
                                            value={driverData.name?.split(' ')[1] || ''}
                                        />
                                        <ToggleSwitch
                                            name="addressIdenticalToMember"
                                            value={true}
                                            isSelected={!!driverData.addressIdenticalToMember}
                                            className={cx([
                                                'global!ace-u-grid-column--span-12',
                                                'global!ace-u-flex',
                                                'global!ace-u-flex--direction-row-reverse',
                                                'global!ace-u-flex--justify-space-between',
                                            ])}
                                        >
                                            {translateComponent('toggle_switch_label.is_address_same')}
                                        </ToggleSwitch>
                                        {!driverFormValues.addressIdenticalToMember && (
                                            <div
                                                className={cx([
                                                    'global!ace-u-grid',
                                                    'global!ace-u-grid-column--span-12',
                                                ])}
                                            >
                                                <Form name="address">
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-12')}
                                                        name="street"
                                                        value={driverData.address?.street || ''}
                                                        label={translateComponent('input_label.street_and_housenumber')}
                                                    />
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-4')}
                                                        name="postCode"
                                                        value={driverData.address?.postCode || ''}
                                                        label={translateComponent('input_label.post_code')}
                                                    />
                                                    <InputField
                                                        className={cx('global!ace-u-grid-column--span-8')}
                                                        name="city"
                                                        value={driverData.address?.city || ''}
                                                        label={translateComponent('input_label.city')}
                                                    />
                                                </Form>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Form>
            </Panel>
        </Fragment>
    );
};

DriverAndOwnerForm.propTypes = {
    driverData: PropTypes.object.isRequired,
    ownerData: PropTypes.object.isRequired,
    requiredFields: PropTypes.array,
    handleOnChange: PropTypes.func,
};

DriverAndOwnerForm.defaultProps = {
    requiredFields: [],
    handleOnChange: null,
};

export default DriverAndOwnerForm;
