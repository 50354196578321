import {put, take, select, fork} from 'redux-saga/effects';
import {alfInvoiceStatusTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadInvoice = function* loadInvoice({payload}) {
    const {match, location} = payload;
    const {invoiceId} = match.params;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!invoiceId) return;

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_INVOICE_REQUEST,
        leaAssignmentFlowService.getInvoice,
        {invoiceId},
    );

    const invoiceFetchActionResponse = yield take([
        invoiceActionTypes.FETCH_INVOICE_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_INVOICE_REQUEST_FAILED,
    ]);

    if (!invoiceFetchActionResponse.error) {
        const {response} = invoiceFetchActionResponse.payload;
        const {invoiceDTO} = response;

        yield put({
            type: invoiceActionTypes.STORE_INVOICES,
            payload: {invoiceDTOs: [invoiceDTO]},
        });

        yield put({
            type: invoiceActionTypes.INITIATE_INVOICE_SCREEN_REDIRECTION,
            payload: {match, location},
        });

        if (invoiceDTO.status === alfInvoiceStatusTypes.IN_CREATION) {
            const {serviceCases, accountPartyOptionsIds} = yield select(state => state.serviceCases);
            const serviceCase = serviceCases[invoiceDTO.serviceCaseId];
            if (!serviceCase) return;

            yield put({
                type: invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS,
                payload: {
                    accountPartyOptionsIds: [...accountPartyOptionsIds, serviceCase.member?.membershipNo],
                    client: serviceCase.client,
                },
            });
        }
    }
};

export default loadInvoice;
