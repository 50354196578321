import {fork, put, select, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {prepareSearchQueryParams, replace, resolveRoute} from '@computerrock/formation-router';
import * as invoiceActionTypes from '../invoiceActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import scOverviewScreenTabs from '../../service-cases/scOverviewScreenTabs';

const invoiceForwardingFlow = function* invoiceForwardingFlow() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        const {payload} = yield take(invoiceActionTypes.INITIATE_INVOICE_CORRECTION_FLOW);
        const {redirectionPath, invoiceId} = payload;

        yield* openModal(modalIds.INVOICE_CORRECTION_MODAL, {invoiceId});

        const chosenModalAction = yield take([
            invoiceActionTypes.DECLINE_INVOICE_CORRECTION,
            invoiceActionTypes.CONFIRM_INVOICE_CORRECTION,
        ]);

        if (chosenModalAction.type === invoiceActionTypes.CONFIRM_INVOICE_CORRECTION) {
            yield fork(
                fetchRequest,
                invoiceActionTypes.CREATE_INVOICE_CORRECTION_REQUEST,
                leaAssignmentFlowService.createInvoiceCorrection,
                {invoiceId},
            );

            const responseAction = yield take([
                invoiceActionTypes.CREATE_INVOICE_CORRECTION_REQUEST_SUCCEEDED,
                invoiceActionTypes.CREATE_INVOICE_CORRECTION_REQUEST_FAILED,
            ]);

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {invoiceDTO} = response;

                yield put({
                    type: invoiceActionTypes.STORE_INVOICES,
                    payload: {invoiceDTOs: [invoiceDTO]},
                });

                yield put(replace(resolveRoute(redirectionPath, {
                    serviceCaseId: invoiceDTO.serviceCaseId,
                    invoiceId: invoiceDTO.id,
                }, {
                    search: prepareSearchQueryParams({activeTab: scOverviewScreenTabs.OVERVIEW}),
                })));
            }
        }

        yield* closeModal(modalIds.INVOICE_CORRECTION_MODAL, {invoiceId});
    }
};

export default invoiceForwardingFlow;
