import {put, take, fork, select} from 'redux-saga/effects';
import {matchPath} from '@computerrock/formation-router';
import {etmTaskStatusTypes, etmTaskTypes, eupUserRoleTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import * as dashboardActionTypes from '../dashboardActionTypes';
import config from '../../config';
import routePaths from '../../routePaths';
import * as taskActionTypes from '../../tasks/taskActionTypes';

const loadCurrentUsersTasks = function* loadCurrentUsersTasks({payload}) {
    const {location} = payload;
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');
    const dashboardScreenMatch = matchPath(location.pathname, {path: routePaths.DASHBOARD, exact: true});

    if (!dashboardScreenMatch) return;

    const userProfile = yield select(userProfileSelectors.getUser);
    const searchQueryParams = new URLSearchParams(location.search);
    const searchQueryParamsConfig = {
        taskType: etmTaskTypes.LEA,
        assignedTo: [eupUserRoleTypes.AUDITOR, userProfile.id],
        sort: 'dueDate,asc',
        size: `${config.TASKS_DEFAULT_PAGE_SIZE}`,
        status: [etmTaskStatusTypes.DUE, etmTaskStatusTypes.OVERDUE],
        page: '0',
    };

    Object.entries(searchQueryParamsConfig).forEach(([key, value]) => {
        if (['assignedTo', 'status'].includes(key) && value && Array.isArray(value)) {
            value.forEach(param => {
                searchQueryParams.append(key, param);
            });
            return;
        }
        if (key === 'page' && !searchQueryParams.get('page')) {
            searchQueryParams.append(key, value);
            return;
        }
        searchQueryParams.append(key, value);
    });

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST,
        ecsTaskManagementService.getTasks,
        {searchQueryParams},
    );

    const responseAction = yield take([
        dashboardActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_CURRENT_USERS_TASKS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {taskDTOs, totalElements} = response;

        yield put({
            type: taskActionTypes.STORE_TASK_SEARCH_RESULTS,
            payload: {taskDTOs, taskSearchResultsCount: totalElements},
        });
    }
};

export default loadCurrentUsersTasks;
