import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, HighlightCircle} from '@ace-de/ui-components';
import {Button, ButtonPrimary} from '@ace-de/ui-components/buttons';
import {infoAlertIcon, Icon} from '@ace-de/ui-components/icons';
import * as contactActionTypes from '../contactActionTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';

const BCActionFailed = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('bc_action_failed_modal');
    const {retryAccountPartyCreation, retryContactCreation, retryEditContact, retryEditAccountParty} = props;
    const {goBack, location, errorDescription} = props;
    const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
    const modalType = queryParams.get('type');

    useEffect(() => {
        setQueryParams(new URLSearchParams(location.search));
    }, [location.search, setQueryParams]);

    const handleOnRetry = () => {
        if (!modalType) return;

        switch (modalType) {
            case bcActionFailedModalTypes.CREATE_CONTACT: {
                retryContactCreation();
                break;
            }
            case bcActionFailedModalTypes.EDIT_CONTACT: {
                retryEditContact();
                break;
            }
            case bcActionFailedModalTypes.CREATE_ACCOUNT_PARTY: {
                retryAccountPartyCreation();
                break;
            }
            case bcActionFailedModalTypes.EDIT_ACCOUNT_PARTY: {
                retryEditAccountParty();
                break;
            }
        }
    };

    return (
        <div
            className={cx([
                'global!ace-u-flex',
                'global!ace-u-full-width',
                'global!ace-u-flex--direction-column',
                'global!ace-u-flex--align-center',
                'global!ace-u-flex--justify-content-center',
            ])}
        >
            <HighlightCircle
                className={cx([
                    'ace-c-highlight-circle--medium',
                    'ace-c-highlight-circle--primary-highlight',
                ])}
            >
                <Icon
                    icon={infoAlertIcon}
                    className={cx('ace-c-icon--xxl', 'global!ace-c-icon--color-highlight')}
                />
            </HighlightCircle>
            <h1 className={cx('global!ace-u-margin--24-0')}>
                {modalType?.includes('CREATE')
                    ? translateModal('heading.failed_to_create_in_bc')
                    : translateModal('heading.failed_to_edit_in_bc')}
            </h1>
            {errorDescription && typeof errorDescription === 'string' && (
                <p className={cx(['global!ace-u-margin--bottom-24', 'global!ace-u-typography--align-center'])}>
                    {errorDescription}
                </p>
            )}
            <div>
                <Button className={cx('global!ace-u-margin--right-16')} onClick={goBack}>
                    {translateModal('button_label.back')}
                </Button>
                <ButtonPrimary onClick={handleOnRetry}>
                    {translateModal('button_label.try_again')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

BCActionFailed.propTypes = {
    retryContactCreation: PropTypes.func.isRequired,
    retryEditContact: PropTypes.func.isRequired,
    retryAccountPartyCreation: PropTypes.func.isRequired,
    retryEditAccountParty: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    location: PropTypes.object,
    errorDescription: PropTypes.string,
};

BCActionFailed.defaultProps = {
    location: null,
    errorDescription: '',
};

const mapStateToProps = state => {
    return {
        errorDescription: state.contacts.errorDescription,
    };
};

const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch({
        type: contactActionTypes.CANCEL_ACTION,
    }),
    retryContactCreation: () => dispatch({
        type: contactActionTypes.RETRY_CONTACT_CREATION,
    }),
    retryEditContact: () => dispatch({
        type: contactActionTypes.RETRY_EDIT_CONTACT,
    }),
    retryAccountPartyCreation: () => dispatch({
        type: contactActionTypes.RETRY_ACCOUNT_PARTY_CREATION,
    }),
    retryEditAccountParty: () => dispatch({
        type: contactActionTypes.RETRY_EDIT_ACCOUNT_PARTY,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BCActionFailed));
