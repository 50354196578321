import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {NotificationBar, useStyles, Icon, warningOutlinedIcon, DropDownTrigger, PopOver, withDropDownProvider} from '@ace-de/ui-components';
import {alfOverpaymentReasonTypes} from '@ace-de/eua-entity-types';

const InvoiceReadOnlyBanner = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateComponent = createTranslateShorthand('invoice_read_only_banner');
    const {approvedBy, approvedAt, approveReason, approveDescription} = props;
    const translatedApproveReason = translate(`global.invoice_approve_reason.${approveReason?.toLowerCase()}`);

    return (
        <NotificationBar type="information">
            <div
                className={cx([
                    'ace-u-width--full',
                    'ace-u-margin--16-0',
                    'ace-u-flex',
                    'ace-u-flex--justify-center',
                    'ace-u-typography--variant-body',
                ])}
            >
                {approveReason ? (
                    <DropDownTrigger>
                        <div>
                            <Icon
                                className={cx('global!ace-u-margin--right-8')}
                                icon={warningOutlinedIcon}
                            />
                        </div>
                        <PopOver alignment="end" hasBorder={true}>
                            <div className={cx('global!ace-u-padding--24', 'ace-u-typography--variant-body')}>
                                <p>{translateComponent('banner_content.approve_reason', {approveReason: translatedApproveReason})}</p>
                                <p>{translateComponent('banner_content.approve_description', {approveDescription})}</p>
                            </div>
                        </PopOver>
                    </DropDownTrigger>
                ) : (
                    <Icon
                        className={cx('global!ace-u-margin--right-8')}
                        icon={warningOutlinedIcon}
                    />
                )}
                {translateComponent(`banner_content.approved${
                    approveReason
                        ? Object.values(alfOverpaymentReasonTypes).includes(approveReason)
                            ? '_overpayment'
                            : '_partial_payment'
                        : ''
                }`, {approvedBy, approvedAt: approvedAt ? moment(approvedAt).format('DD.MM.YYYY') : ''})}
            </div>
        </NotificationBar>
    );
};

InvoiceReadOnlyBanner.propTypes = {
    approvedBy: PropTypes.string,
    approvedAt: PropTypes.string,
    approveReason: PropTypes.string,
    approveDescription: PropTypes.string,
};

InvoiceReadOnlyBanner.defaultProps = {
    approvedBy: '',
    approvedAt: '',
    approveReason: '',
    approveDescription: '',
};

export default withDropDownProvider(InvoiceReadOnlyBanner);
