import {all, fork} from 'redux-saga/effects';
import contactCreationFlow from './sagas/contactCreationFlow';
import searchContacts from './sagas/searchContacts';
import editContactFlow from './sagas/editContactFlow';
import accountPartyCreationFlow from './sagas/accountPartyCreationFlow';
import editAccountPartyFlow from './sagas/editAccountPartyFlow';
import fetchAccountParty from './sagas/fetchAccountParty';
import contactCreationModalFlow from './sagas/contactCreationModalFlow';
import editContactModalFlow from './sagas/editContactModalFlow';
import accountPartyCreationModalFlow from './sagas/accountPartyCreationModalFlow';
import startCommunicationModalFlow from './sagas/startCommunicationModalFlow';

/**
 *  Contact watcher saga
 */
const contactWatcher = function* contactWatcher() {
    yield all([
        fork(searchContacts),
        fork(contactCreationFlow),
        fork(accountPartyCreationFlow),
        fork(editContactFlow),
        fork(editAccountPartyFlow),
        fork(fetchAccountParty),
        fork(contactCreationModalFlow),
        fork(editContactModalFlow),
        fork(accountPartyCreationModalFlow),
        fork(startCommunicationModalFlow),
    ]);
};

export default contactWatcher;
