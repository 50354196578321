import {take, put, fork, select} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as communicationActionTypes from '../communicationActionTypes';

const loadMessages = function* loadMessages({payload}) {
    const {match} = payload;
    const {serviceCaseId} = match.params;
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (!serviceCaseId) return;
    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('serviceCaseId', serviceCaseId);

    yield fork(fetchRequest,
        communicationActionTypes.FETCH_MESSAGES_REQUEST,
        leaAssignmentFlowService.getMessages,
        {searchQueryParams});

    const fetchCaseMessagesAction = yield take([
        communicationActionTypes.FETCH_MESSAGES_REQUEST_SUCCEEDED,
        communicationActionTypes.FETCH_MESSAGES_REQUEST_FAILED,
    ]);

    if (!fetchCaseMessagesAction.error) {
        const {response} = fetchCaseMessagesAction.payload;
        const {messageDTOs} = response;

        yield put({
            type: communicationActionTypes.STORE_MESSAGES,
            payload: {messageDTOs},
        });
    }
};

export default loadMessages;
