import {all, fork} from 'redux-saga/effects';
import initiateSendMessageFlow from './sagas/initiateSendMessageFlow';
import fetchAccountPartyDetails from './sagas/fetchAccountPartyDetails';
import downloadMessageAttachments from './sagas/downloadMessageAttachments';
import initiateUploadReplyFlow from './sagas/initiateUploadReplyFlow';
import updateMessageAsRead from './sagas/updateMessageAsRead';

/**
 *  Communication watcher saga
 */
const communicationWatcher = function* communicationWatcher() {
    yield all([
        fork(initiateSendMessageFlow),
        fork(downloadMessageAttachments),
        fork(initiateUploadReplyFlow),
        fork(fetchAccountPartyDetails),
        fork(updateMessageAsRead),
    ]);
};

export default communicationWatcher;
