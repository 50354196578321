import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter, resolveRoute, matchPath} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Panel, Button, Pill, Paginator} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Icon, plusIcon} from '@ace-de/ui-components/icons';
import * as taskActionTypes from '../taskActionTypes';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as userProfileSelectors from '../../user-profiles/userProfileSelectors';
import routePaths from '../../routePaths';
import * as serviceCaseSelectors from '../../service-cases/serviceCaseSelectors';
import invoiceOverviewScreenTabs from '../../invoices/invoiceOverviewScreenTabs';
import config from '../../config';
import {leaFeatures, leaFeatureActions} from '../../application/leaFeatures';
import leaAccessControl from '../../leaAccessControl';
import {pillVariants} from '../taskPillVariants';

const TaskListPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translatePanel = createTranslateShorthand('task_list_panel');
    const {invoice, taskListResults, users, history, serviceCase, taskListResultsCount} = props;
    const {initiateTaskCreation, initiateTaskEdit} = props;
    const queryParams = new URLSearchParams(history.location.search);
    const paginatorCount = Math.ceil(taskListResultsCount / config.DEFAULT_PAGE_SIZE);
    const routhPath = matchPath(history.location.pathname, {path: routePaths.SERVICE_CASES_OVERVIEW})
        ? routePaths.SERVICE_CASES_OVERVIEW : routePaths.INVOICE_OVERVIEW;
    const isTaskCreationAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.TASKS,
        leaFeatureActions.CREATE,
    );
    const isTaskEditAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.TASKS,
        leaFeatureActions.UPDATE,
    );

    useEffect(() => {
        if (queryParams.get('activeTab') === invoiceOverviewScreenTabs.TASKS
            && !queryParams.get('size')
            && !queryParams.get('sort')
            && !queryParams.get('assignedCaseId')
        ) {
            queryParams.append('size', `${config.DEFAULT_PAGE_SIZE}`);
            queryParams.append('sort', 'dueDate,asc');
            queryParams.append('assignedCaseId', serviceCase.id);

            history.replace(resolveRoute(routhPath, {
                serviceCaseId: serviceCase.id,
                ...(invoice ? {invoiceId: invoice.id} : {}),
            }, {search: queryParams.toString()}));
        }
    });

    const handlePaginationPage = page => {
        const apiQueryParams = new URLSearchParams(queryParams);
        apiQueryParams.set('page', `${page}`);
        apiQueryParams.set('size', `${config.DEFAULT_PAGE_SIZE}`);

        const queryParamsString = apiQueryParams ? apiQueryParams.toString() : '';
        history.replace(resolveRoute(routhPath, {
            serviceCaseId: serviceCase.id,
            ...(invoice ? {invoiceId: invoice.id} : {}),
        },
        {search: queryParamsString}));
    };

    return (
        <Panel
            title={translatePanel('panel_title.tasks')}
            actions={(
                <Button
                    name="createTaskButton"
                    className={cx('global!ace-u-margin--bottom-24', 'global!ace-u-width--full')}
                    onClick={initiateTaskCreation}
                    isDisabled={!isTaskCreationAllowed}
                >
                    <Icon
                        icon={plusIcon}
                        className={cx('ace-c-icon--color-highlight', 'global!ace-u-margin--right-8')}
                    />
                    {translatePanel('button_label.new_task')}
                </Button>
            )}
        >
            <Table qaIdent="task-list-results">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="task-due-date-time" colSpan={3}>
                            {translatePanel('table_header.due_date_time')}
                        </TableCell>
                        <TableCell qaIdentPart="task-type" colSpan={5}>
                            {translatePanel('table_header.type')}
                        </TableCell>
                        <TableCell qaIdentPart="task-description" colSpan={5}>
                            {translatePanel('table_header.description')}
                        </TableCell>
                        <TableCell qaIdentPart="task-status" colSpan={2}>
                            {translatePanel('table_header.status')}
                        </TableCell>
                        <TableCell qaIdentPart="task-assigned-to" colSpan={3}>
                            {translatePanel('table_header.assigned_to')}
                        </TableCell>
                        <TableCell qaIdentPart="task-created-by" colSpan={3}>
                            {translatePanel('table_header.created_by')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {taskListResults && Object.values(taskListResults).map(task => (
                        <TableRow
                            key={task.id}
                            qaIdentPart={task.id}
                            onClick={() => {
                                if (isTaskEditAllowed) initiateTaskEdit({taskId: task.id});
                            }}
                        >
                            <TableCell
                                qaIdentPart="task-due-date-time"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                {moment(task.dueDate).format('DD.MM.YYYY - HH:mm')}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-type"
                                qaIdentPartPostfix={task.id}
                                colSpan={5}
                            >
                                {translate(`global.task_category.${task.category.toLowerCase()}`)}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-description"
                                qaIdentPartPostfix={task.id}
                                colSpan={5}
                            >
                                {task.description}
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-status"
                                qaIdentPartPostfix={task.id}
                                colSpan={2}
                            >
                                <Pill type={pillVariants[task.status]}>
                                    {translate(`global.task_status.${task.status.toLowerCase()}`)}
                                </Pill>
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-assigned-to"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                {task.assignedToRole
                                    ? translate(`global.user_shift_role.${task.assignedTo.toLowerCase()}`)
                                    : users[task.assignedTo]
                                        ? `${users[task.assignedTo]?.firstName || ''} ${users[task.assignedTo]?.lastName || ''}`
                                        : '-'
                                }
                            </TableCell>
                            <TableCell
                                qaIdentPart="task-created-by"
                                qaIdentPartPostfix={task.id}
                                colSpan={3}
                            >
                                {task.createdByUser || '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {Object.values(taskListResults).length > 0 && (
                <Paginator
                    page={+queryParams.get('page')}
                    count={paginatorCount}
                    onClick={page => handlePaginationPage(page)}
                />
            )}
        </Panel>
    );
};

TaskListPanel.propTypes = {
    initiateTaskCreation: PropTypes.func.isRequired,
    initiateTaskEdit: PropTypes.func.isRequired,
    taskListResults: PropTypes.array,
    taskListResultsCount: PropTypes.number,
    invoice: PropTypes.object,
    users: PropTypes.object,
    serviceCase: PropTypes.object,
    history: PropTypes.object,
};

TaskListPanel.defaultProps = {
    taskListResults: [],
    taskListResultsCount: 0,
    invoice: null,
    users: null,
    serviceCase: null,
    history: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    const getInvoice = invoiceSelectors.createInvoicesSelector();

    return {
        serviceCase: getServiceCase(state, props),
        invoice: getInvoice(state, props),
        users: userProfileSelectors.getUsers(state),
        taskListResults: state.tasks.taskSearchResults,
        taskListResultsCount: state.tasks.taskSearchResultsCount,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateTaskCreation: () => dispatch({
        type: taskActionTypes.INITIATE_TASK_CREATION_FLOW,
    }),
    initiateTaskEdit: payload => dispatch({
        type: taskActionTypes.INITIATE_TASK_EDIT_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskListPanel));
