import React, {Fragment, useState, useMemo, useRef, useEffect, useCallback} from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import moment from 'moment';
import {useRouteUnmountEffect, parseSearchQueryParams} from '@computerrock/formation-router';
import {Person, alfDamageTypes, alfServiceCaseStatusTypes, europeanCountries, Address, LeistServiceCase, efPersonTypes} from '@ace-de/eua-entity-types'; // eslint-disable-line max-len
import {countryCodesMap} from '@ace-de/eua-arcgis-rest-client';
import {useStyles, Panel, ContentBlock, ContentItem, AppLayoutMain, HeaderBar} from '@ace-de/ui-components';
import {Icon, calendarIcon, deleteIcon, saveIcon, searchIcon, locationIcon, arrowDownIcon, communicationIcon} from '@ace-de/ui-components/icons';
import {ButtonPrimary, Button} from '@ace-de/ui-components/buttons';
import {Form, SelectField, Option, DateField, AutosuggestField, AutocompleteField} from '@ace-de/ui-components/form';
import {useTranslate} from '@computerrock/formation-i18n';
import {leaFeatures, leaFeatureActions} from '../application/leaFeatures';
import ServiceCaseHeader from './ui-elements/ServiceCaseHeader';
import DriverAndOwnerForm from './ui-elements/DriverAndOwnerForm';
import VehicleSelectPanel from './ui-elements/VehicleSelectPanel';
import styles from './ui-elements/ServiceCaseHeader.module.scss';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as serviceCaseActionTypes from './serviceCaseActionTypes';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import config from '../config';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';
import serviceManager from '../serviceManager';
import leaAccessControl from '../leaAccessControl';
import VehicleDataPanel from './ui-elements/VehicleDataPanel';
import CreateTrailerPanel from './ui-elements/CreateTrailerPanel';

const mandatoryFieldSets = {
    [alfDamageTypes.VEHICLE]: ['location', 'driverData', 'ownerData'],
    [alfDamageTypes.PERSON]: ['location'],
    [alfDamageTypes.OTHER]: [],
};

const defaultMandatoryFields = ['damageType', 'damageDate', 'country'];

const validateDriverAndOwnerForm = formValues => {
    if (!formValues) return false;
    const {isIdenticalToMember, address, memberRelationship, addressIdenticalToMember} = formValues;
    const {firstName, lastName} = formValues;
    if (isIdenticalToMember) return true;
    if (typeof isIdenticalToMember === 'boolean'
        && !isIdenticalToMember
        && (!firstName || !lastName || !memberRelationship)) {
        return false;
    }
    return !(typeof isIdenticalToMember === 'boolean'
        && !isIdenticalToMember
        && !addressIdenticalToMember
        && (!address || !address.street || !address.postCode || !address.city));
};

const formatDriverAndOwnerData = (vehiclePersonData, serviceCase, vehiclePersonType) => {
    const {vehicle, member} = serviceCase;
    const memberAddress = member.personalDetails?.address;
    const serviceCaseVehiclePersonData = vehicle ? vehicle[vehiclePersonType] : null;
    if (!serviceCaseVehiclePersonData && !vehiclePersonData) {
        return new Person();
    }
    return {...(serviceCaseVehiclePersonData || {}),
        ...(vehiclePersonData
            ? {
                ...(
                    typeof vehiclePersonData.isIdenticalToMember === 'boolean'
                        ? !vehiclePersonData.isIdenticalToMember
                            ? {
                                memberRelationship: vehiclePersonData.memberRelationship,
                                isIdenticalToMember: vehiclePersonData.isIdenticalToMember,
                            }
                            : {isIdenticalToMember: vehiclePersonData.isIdenticalToMember}
                        : {memberRelationship: efPersonTypes.UNKNOWN}
                ),
                addressIdenticalToMember: typeof vehiclePersonData?.isIdenticalToMember === 'boolean'
                    ? vehiclePersonData.isIdenticalToMember ? true : !!vehiclePersonData.addressIdenticalToMember
                    : null,
                name: typeof vehiclePersonData.isIdenticalToMember === 'boolean'
                    ? (vehiclePersonData.isIdenticalToMember
                        ? `${member.personalDetails?.firstName} ${member.personalDetails?.surname}`
                        : `${vehiclePersonData.firstName || ''}${vehiclePersonData.lastName
                            ? ` ${vehiclePersonData.lastName}`
                            : ''}`
                    ) : null,
                address: typeof vehiclePersonData.isIdenticalToMember === 'boolean'
                    ? (vehiclePersonData.isIdenticalToMember
                        ? {...new Address(memberAddress)}
                        : (vehiclePersonData.addressIdenticalToMember
                            ? {...new Address(memberAddress)}
                            : (vehiclePersonData.address
                                ? {...vehiclePersonData.address}
                                : {...new Address()})
                        )
                    ) : {...new Address()},
            }
            : {}
        )};
};

const ServiceCaseScreen = props => {
    const {cx} = useStyles(props, styles);
    const {translate, createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('create_case_screen');
    const {searchDamageLocationGeolocation, initiateDeleteServiceCaseFlow, initiateSendMessage} = props;
    const {initiateSCScreenRedirection, updateServiceCaseData, serviceCase, history, location, match} = props;
    const {isEditModeActive} = parseSearchQueryParams(history.location.search);

    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    const isCaseDeletionDisabled = serviceCase?.status !== alfServiceCaseStatusTypes.NEW
        || !leaAccessControl.grantFeatureAccess(
            leaFeatures.SERVICE_CASE,
            leaFeatureActions.DELETE,
        );
    const isInvoiceCreationAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.INVOICE,
        leaFeatureActions.CREATE,
    );
    const isCommunicationAllowed = leaAccessControl.grantFeatureAccess(
        leaFeatures.MESSAGE,
        leaFeatureActions.CREATE,
    );

    const [serviceCaseFormData, setServiceCaseFormData] = useState(null);
    const [mandatoryFieldList, setMandatoryFieldList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCountryCode, setSelectedCountryCode] = useState(serviceCase?.damage?.country || '');
    const [newLocation, setNewLocation] = useState(serviceCase?.damage?.location || null);
    const [isInvoiceCreationDisabled, setIsInvoiceCreationDisabled] = useState(true);
    const [selectedVehicle, setSelectedVehicle] = useState(serviceCase?.vehicle?.id
        ? serviceCase.vehicle
        : null);
    const [maxDateError, setMaxDateError] = useState('');
    const currentLocationAddress = useRef('');
    const lastLocationSearchQuery = useRef('');

    const sortedCountries = Object.entries({...europeanCountries,
        WD: {
            id: 0,
            name: 'Worldwide',
        }})
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });

    const searchDamageLocationGeolocationDebounced = useMemo(() => {
        if (typeof searchDamageLocationGeolocation === 'function') {
            return debounce(
                searchDamageLocationGeolocation,
                config.ARCGIS_ADDRESS_SUGGEST_GEOLOCATION_DEBOUNCE_TIMER,
            );
        }
        return () => null;
    }, [searchDamageLocationGeolocation]);

    const validateFormFields = useCallback(() => {
        const {damageInformationForm, serviceCaseVehicleId, vehicleDriverForm, vehicleOwnerForm} = serviceCaseFormData;
        const formValidationStates = {};

        // if we don't have damageInformation form, that means that none of the default mandatory fields is populated
        if (!damageInformationForm) {
            if (!isInvoiceCreationDisabled) setIsInvoiceCreationDisabled(true);
            return;
        }

        // if we don't have driver or owner form and those are required, that means tha fields are not populated
        if ((!vehicleOwnerForm && mandatoryFieldList.includes('ownerData'))
            || (!vehicleDriverForm && mandatoryFieldList.includes('driverData'))
        ) {
            if (!isInvoiceCreationDisabled) setIsInvoiceCreationDisabled(true);
            return;
        }
        // if we don't have vehicle creation form and its fields are required, that means tha fields are not populated
        // Or if we have vehicle form, but mandatory fields are not populated
        if (!serviceCaseVehicleId && damageInformationForm['damageType'] === alfDamageTypes.VEHICLE) {
            if (!isInvoiceCreationDisabled) setIsInvoiceCreationDisabled(true);
            return;
        }

        formValidationStates['damageInformation'] = !Object.entries(damageInformationForm)
            .filter(([property, value]) => {
                return mandatoryFieldList.includes(property) && (!value || (property === 'damageDate' && moment(value).isSameOrAfter()));
            }).length > 0;

        formValidationStates['vehicleDriver'] = mandatoryFieldList.includes('driverData')
            ? validateDriverAndOwnerForm(vehicleDriverForm)
            : true;

        formValidationStates['vehicleOwner'] = mandatoryFieldList.includes('ownerData')
            ? validateDriverAndOwnerForm(vehicleOwnerForm)
            : true;

        const newInvoiceCreationDisabledStatus = !Object.values(formValidationStates)
            .reduce((accumulator, currentValue) => {
                return accumulator && currentValue;
            });

        if (newInvoiceCreationDisabledStatus !== isInvoiceCreationDisabled) {
            setIsInvoiceCreationDisabled(newInvoiceCreationDisabledStatus);
        }
    }, [
        serviceCaseFormData,
        setIsInvoiceCreationDisabled,
        isInvoiceCreationDisabled,
        mandatoryFieldList,
    ]);

    useEffect(() => {
        initiateSCScreenRedirection({
            match,
            location,
        });
    }, [initiateSCScreenRedirection, match, location]);

    // Effects
    useEffect(() => {
        serviceCase?.vehicle?.id ? setSelectedVehicle(serviceCase.vehicle) : setSelectedVehicle(null);
        if (serviceCase?.damage?.country) {
            const selectedCountry = sortedCountries.find(([sortedCountryCode, sortedCountry]) => (
                sortedCountryCode === serviceCase.damage.country
            ));
            selectedCountry ? setSelectedCountry(selectedCountry[1].name) : setSelectedCountry('');
        }
    }, [serviceCase, sortedCountries]);

    useEffect(() => {
        const date = serviceCaseFormData?.damageInformationForm?.damageDate || serviceCase?.damage?.date;
        if (date) setMaxDateError(moment(date).isSameOrAfter()
            ? translateScreen('error_message.not_allowed_date')
            : '');
    }, [serviceCaseFormData, serviceCase, translateScreen]);


    useEffect(() => {
        if (serviceCaseFormData && Object.keys(serviceCaseFormData).length > 0) {
            validateFormFields();
        }
    }, [serviceCaseFormData, validateFormFields]);

    useEffect(() => {
        if (serviceCase) {
            const {member} = serviceCase;
            if (!newLocation) {
                const selectedCountry = sortedCountries.find(([sortedCountryCode, sortedCountry]) => (
                    sortedCountryCode === serviceCase.damage?.country
                ));
                setSelectedCountry(selectedCountry ? selectedCountry[1].name : '');
                setNewLocation(serviceCase.damage?.location);
            }

            if (mandatoryFieldList.length === 0) {
                setMandatoryFieldList([...defaultMandatoryFields, ...(serviceCase.damage?.type ? mandatoryFieldSets[serviceCase.damage.type] : [])]); // eslint-disable-line max-len
            }

            if (!serviceCaseFormData) {
                setServiceCaseFormData({
                    ...(serviceCase.damage
                        ? {damageInformationForm: {
                            ...serviceCase.damage,
                            damageDate: serviceCase.damage.date,
                            damageType: serviceCase.damage.type,
                        }}
                        : {}
                    ),
                    ...(serviceCase.vehicle
                        ? {
                            serviceCaseVehicleId: serviceCase.vehicle.id,
                            vehicleDriverForm: serviceCase.vehicle.driver
                                    || Person({...({address: member?.personalDetails?.address} || {})}),
                            vehicleOwnerForm: serviceCase.vehicle.owner
                                    || Person({...({address: member?.personalDetails?.address} || {})}),
                        }
                        : {}
                    ),
                });
            }
        }
    }, [serviceCase, mandatoryFieldList, serviceCaseFormData, newLocation, sortedCountries]);

    useEffect(() => {
        if (serviceCaseFormData && serviceCase?.vehicle?.id && !serviceCaseFormData?.serviceCaseVehicleId) {
            setServiceCaseFormData({
                ...serviceCaseFormData,
                serviceCaseVehicleId: serviceCase.vehicle.id,
            });
        }
    }, [serviceCase?.vehicle, serviceCaseFormData]);

    const handleTabChange = useCallback((serviceCasePatchData, newLocation) => {
        const {damageInformationForm, vehicleDriverForm, vehicleOwnerForm} = serviceCasePatchData;
        const serviceCaseData = {
            type: damageInformationForm?.damageType || null,
            ...(damageInformationForm
                ? {damage: {
                    ...damageInformationForm,
                    ...(damageInformationForm.damageType ? {type: damageInformationForm.damageType} : {}),
                    ...(damageInformationForm.damageDate ? {date: damageInformationForm.damageDate} : {}),
                    ...(damageInformationForm.location ? {location: newLocation} : {}),
                    ...(damageInformationForm.country ? {country: selectedCountryCode} : {}),
                }}
                : {}
            ),
            ...((vehicleDriverForm || vehicleOwnerForm)
                ? {
                    vehicle: {
                        ...serviceCase.vehicle,
                        owner: formatDriverAndOwnerData(vehicleOwnerForm, serviceCase, 'owner'),
                        driver: formatDriverAndOwnerData(vehicleDriverForm, serviceCase, 'driver'),
                    },
                }
                : {}
            ),

        };
        updateServiceCaseData({
            serviceCaseData,
            serviceCaseId: serviceCase.id,
            hasInvoiceCreation: false,
            history,
        });
    }, [
        serviceCase,
        updateServiceCaseData,
        history,
        selectedCountryCode,
    ]);

    // Handlers
    const handleLocationSearchQueryChange = searchQueryString => {
        if (searchQueryString
            && searchQueryString.toLowerCase() !== currentLocationAddress.current.toLowerCase()
            && searchQueryString.length >= config.MINIMUM_SEARCH_QUERY_LENGTH) {
            searchDamageLocationGeolocationDebounced({
                searchQueryString,
                serviceCaseId: `${serviceCase.id}`,
            });
            lastLocationSearchQuery.current = searchQueryString;
        }
    };

    const handleLocationCandidateSelect = locationCandidate => {
        currentLocationAddress.current = locationCandidate.address.formattedAddress;
        setNewLocation(locationCandidate);
        if (!selectedCountry) {
            const selectedCountry = countryCodesMap[locationCandidate.address.countryCode];
            if (selectedCountry) {
                const {id, name} = countryCodesMap[locationCandidate.address.countryCode];
                const countryCode = Object.keys(europeanCountries).find(countryKey => {
                    return europeanCountries[countryKey].name === name && europeanCountries[countryKey].id === id;
                });
                sortedCountries.forEach(([sortedCountryCode, sortedCountry]) => {
                    if (sortedCountryCode === countryCode) {
                        setSelectedCountryCode(sortedCountryCode);
                        setSelectedCountry(sortedCountry.name);
                    }
                });
            }
            if (!selectedCountry) {
                sortedCountries.forEach(([sortedCountryCode, sortedCountry]) => {
                    if (sortedCountryCode === 'WD') {
                        setSelectedCountryCode(sortedCountryCode);
                        setSelectedCountry(sortedCountry.name);
                    }
                });
            }
        }
    };

    const handleOnFormChange = (formName, formValues) => {
        const {damageType} = formValues;

        if (formName === 'damageInformationForm' && (damageType && damageType !== serviceCaseFormData?.damageInformationForm?.damageType)) {
            setMandatoryFieldList([...defaultMandatoryFields, ...(damageType ? mandatoryFieldSets[damageType] : [])]);
        }
        Object.keys(formValues).forEach(key => {
            if (key === 'country') {
                const country = sortedCountries.find(([sortedCountryCode, sortedCountry]) => (
                    formValues[key] === sortedCountry.name
                ));
                setSelectedCountryCode(country ? country[0] : '');
            }
        });

        setServiceCaseFormData({
            ...serviceCaseFormData,
            [formName]: {...formValues},
        });
    };

    const handleOnFormSubmit = () => {
        if (!serviceCaseFormData) return;

        const {damageInformationForm, vehicleDriverForm, vehicleOwnerForm} = serviceCaseFormData;

        const serviceCaseData = {
            type: damageInformationForm?.damageType || '',
            ...(damageInformationForm
                ? {damage: {
                    ...damageInformationForm,
                    ...(damageInformationForm.damageType ? {type: damageInformationForm.damageType} : {}),
                    ...(damageInformationForm.damageDate ? {date: damageInformationForm.damageDate} : {}),
                    ...(damageInformationForm.location ? {location: newLocation} : {}),
                    ...(damageInformationForm.country ? {country: selectedCountryCode} : {}),
                }}
                : {}
            ),
            ...((vehicleDriverForm || vehicleOwnerForm)
                ? {
                    vehicle: {
                        ...serviceCase.vehicle,
                        owner: formatDriverAndOwnerData(vehicleOwnerForm, serviceCase, 'owner'),
                        driver: formatDriverAndOwnerData(vehicleDriverForm, serviceCase, 'driver'),
                    },
                }
                : {}
            ),
        };

        updateServiceCaseData({
            serviceCaseData,
            serviceCaseId: serviceCase.id,
            hasInvoiceCreation: !isEditModeActive,
            history,
        });
    };

    useRouteUnmountEffect(({completeRouteUnmountSideEffect}) => {
        if ((!serviceCaseFormData && !newLocation)
            || (!isEditModeActive && serviceCase?.invoicesInfo?.invoicesCount)
            || isEditModeActive) {
            completeRouteUnmountSideEffect({
                caller: serviceCaseActionTypes.UPDATE_SERVICE_CASE_MANDATORY_DATA,
            });
            return;
        }

        handleTabChange(serviceCaseFormData, newLocation);
    }, [serviceCaseFormData, handleTabChange, newLocation, isEditModeActive, serviceCase?.invoicesInfo?.invoicesCount]);

    useBeforeUnloadEffect(event => {
        if (!serviceCaseFormData) return;

        const {damageInformationForm, vehicleDriverForm, vehicleOwnerForm} = serviceCaseFormData;

        const serviceCaseData = {
            type: damageInformationForm?.damageType || '',
            ...(damageInformationForm
                ? {damage: {
                    ...damageInformationForm,
                    ...(damageInformationForm.damageType ? {type: damageInformationForm.damageType} : {}),
                    ...(damageInformationForm.damageDate ? {date: damageInformationForm.damageDate} : {}),
                    ...(damageInformationForm.location ? {location: newLocation} : {}),
                    ...(damageInformationForm.country ? {country: selectedCountryCode} : {}),
                }}
                : {}
            ),
            ...((vehicleDriverForm || vehicleOwnerForm)
                ? {
                    vehicle: {
                        ...serviceCase.vehicle,
                        owner: formatDriverAndOwnerData(vehicleOwnerForm, serviceCase, 'owner'),
                        driver: formatDriverAndOwnerData(vehicleDriverForm, serviceCase, 'driver'),
                    },
                }
                : {}
            ),

        };
        return {
            request: leaAssignmentFlowService.updateServiceCase,
            requestData: {
                serviceCaseId: serviceCase.id,
                serviceCasePatchDTO: LeistServiceCase.objectToPatchDTO(serviceCaseData),
            },
        };
    });

    if (!serviceCase) return null;

    const {damageLocationSearchQuery, damageLocationCandidates, member} = serviceCase;

    return (
        <Fragment>
            <HeaderBar className={cx('lea-c-service-case-header')}>
                <ServiceCaseHeader />
            </HeaderBar>
            <AppLayoutMain>
                <ContentBlock>
                    <ContentItem className={cx('ace-c-content-item--span-8')}>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-start',
                            ])}
                        >
                            <Button
                                type="button"
                                name="deleteCase"
                                isDisabled={isCaseDeletionDisabled}
                                className={cx('global!ace-u-margin--right-32')}
                                onClick={() => { initiateDeleteServiceCaseFlow({serviceCaseId: serviceCase.id}); }}
                            >
                                <Icon
                                    icon={deleteIcon}
                                    className={cx([
                                        isCaseDeletionDisabled
                                            ? 'ace-c-icon--color-disabled'
                                            : 'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('button_label.delete_case')}
                            </Button>
                            <Button
                                type="button"
                                name="startCommunication"
                                onClick={initiateSendMessage}
                                isDisabled={!isCommunicationAllowed}
                            >
                                <Icon
                                    icon={communicationIcon}
                                    className={cx([
                                        'ace-c-icon--color-highlight',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {translateScreen('button_label.communication')}
                            </Button>
                        </div>
                        <Panel title={translateScreen('damage_panel_title.damage')}>
                            <Form name="damageInformationForm" onChange={formValues => { handleOnFormChange('damageInformationForm', formValues); }}>
                                {damageFormValues => (
                                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--32-0')}>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-grid-column--span-6',
                                            ])}
                                        >
                                            <SelectField
                                                name="damageType"
                                                className={cx('global!ace-u-grid-column--span-12')}
                                                label={`${translateScreen('select_label.damage_type')}*`}
                                                value={serviceCase?.damage?.type || ''}
                                            >
                                                <Option
                                                    name="damageOptionVehicle"
                                                    value={alfDamageTypes.VEHICLE}
                                                >
                                                    {translate('global.alf_damage_type.vehicle')}
                                                </Option>
                                                <Option
                                                    name="damageOptionPerson"
                                                    value={alfDamageTypes.PERSON}
                                                >
                                                    {translate('global.alf_damage_type.person')}
                                                </Option>
                                                <Option
                                                    name="damageOptionOther"
                                                    value={alfDamageTypes.OTHER}
                                                >
                                                    {translate('global.alf_damage_type.other')}
                                                </Option>
                                            </SelectField>
                                            <DateField
                                                label={`${translateScreen('date_field_label.damage_date')}*`}
                                                name="damageDate"
                                                className={cx([
                                                    'global!ace-u-grid-column--span-12',
                                                ])}
                                                icon={calendarIcon}
                                                value={serviceCase?.damage?.date || ''}
                                                maxDate={moment().format()}
                                                errors={maxDateError ? [maxDateError] : []}
                                            />
                                        </div>
                                        <div
                                            className={cx([
                                                'global!ace-u-grid',
                                                'global!ace-u-grid-column--span-6',
                                            ])}
                                        >
                                            <AutosuggestField
                                                label={`${translateScreen('input_label.damage_location')}${mandatoryFieldList.includes('location') ? '*' : ''}`}
                                                name="location"
                                                className={cx([
                                                    'global!ace-u-grid-column--span-12',
                                                ])}
                                                onChange={handleLocationSearchQueryChange}
                                                onOptionSelect={handleLocationCandidateSelect}
                                                optionValueSelector={locationCandidate => {
                                                    return locationCandidate.address.formattedAddress;
                                                }}
                                                icon={searchIcon}
                                                value={newLocation?.address?.formattedAddress || ''}
                                            >
                                                {(damageFormValues['location'] || '').length >= config.MINIMUM_SEARCH_QUERY_LENGTH
                                                && damageLocationSearchQuery === lastLocationSearchQuery.current
                                                    ? damageLocationCandidates
                                                        .slice(0, config.ARCGIS_ADDRESS_GEOLOCATION_RESULTS_COUNT)
                                                        .map((locationCandidate, index) => {
                                                            return (
                                                                <Option
                                                                    key={index}
                                                                    name={`damage-location-candidate-${index}`}
                                                                    value={locationCandidate}
                                                                >
                                                                    <Icon
                                                                        icon={locationIcon}
                                                                        className={cx('global!ace-u-margin--right-16')}
                                                                    />
                                                                    {locationCandidate?.address?.formattedAddress}
                                                                </Option>
                                                            );
                                                        }) : null}
                                            </AutosuggestField>
                                            <AutocompleteField
                                                name="country"
                                                label={`${translateScreen('select_label.damage_country')}${mandatoryFieldList.includes('country') ? '*' : ''}`}
                                                placeholder={translate('global.select.placeholder')}
                                                icon={arrowDownIcon}
                                                className={cx('global!ace-u-grid-column--span-12')}
                                                value={selectedCountry || ''}
                                            >
                                                {sortedCountries.length > 0 && sortedCountries
                                                    .map(([countryCode, country]) => {
                                                        return (
                                                            <Option
                                                                key={country.id}
                                                                name={`country-${country.id}`}
                                                                value={country.name}
                                                            >
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })}
                                            </AutocompleteField>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        </Panel>
                        <VehicleSelectPanel
                            selectedVehicle={selectedVehicle}
                            isVehicleSelectionMandatory={
                                serviceCaseFormData?.damageInformationForm?.damageType === alfDamageTypes.VEHICLE
                            }
                        />
                        {selectedVehicle && <VehicleDataPanel />}
                        <CreateTrailerPanel />
                    </ContentItem>
                    <ContentItem className={cx('ace-c-content-item--span-4')}>
                        <div
                            className={cx([
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-flex-end',
                            ])}
                        >
                            <ButtonPrimary
                                type="button"
                                name="submitButton"
                                onClick={handleOnFormSubmit}
                                isDisabled={isInvoiceCreationDisabled || !isInvoiceCreationAllowed}
                            >
                                <Icon
                                    icon={saveIcon}
                                    className={cx([
                                        'global!ace-c-icon--color-contrast',
                                        'global!ace-u-margin--right-8',
                                    ])}
                                />
                                {isEditModeActive ? translateScreen('button_label.save') : translateScreen('button_label.create')}
                            </ButtonPrimary>
                        </div>
                        <DriverAndOwnerForm
                            requiredFields={mandatoryFieldList}
                            driverData={serviceCase?.vehicle?.driver
                            || new Person({...({address: member?.personalDetails?.address} || {})})}
                            ownerData={serviceCase?.vehicle?.owner
                            || new Person({...({address: member?.personalDetails?.address} || {})})}
                            handleOnChange={handleOnFormChange}
                        />
                    </ContentItem>
                </ContentBlock>
            </AppLayoutMain>
        </Fragment>
    );
};

ServiceCaseScreen.propTypes = {
    serviceCase: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    searchDamageLocationGeolocation: PropTypes.func.isRequired,
    updateServiceCaseData: PropTypes.func.isRequired,
    initiateDeleteServiceCaseFlow: PropTypes.func.isRequired,
    initiateSendMessage: PropTypes.func.isRequired,
    initiateSCScreenRedirection: PropTypes.func.isRequired,
};

ServiceCaseScreen.defaultProps = {
    serviceCase: null,
    history: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    searchDamageLocationGeolocation: payload => dispatch({
        type: serviceCaseActionTypes.SEARCH_SERVICE_CASE_DAMAGE_LOCATION_GEOLOCATION,
        payload,
    }),
    updateServiceCaseData: payload => dispatch({
        type: serviceCaseActionTypes.UPDATE_SERVICE_CASE_MANDATORY_DATA,
        payload,
    }),
    initiateDeleteServiceCaseFlow: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SERVICE_CASE_DELETE_FLOW,
        payload,
    }),
    initiateSendMessage: payload => dispatch({
        type: communicationActionTypes.INITIATE_SEND_MESSAGE_FLOW,
        payload,
    }),
    initiateSCScreenRedirection: payload => dispatch({
        type: serviceCaseActionTypes.INITIATE_SERVICE_CASE_SCREEN_REDIRECTION,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCaseScreen);
