import {take, put, fork, delay, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {replace, resolveRoute} from '@computerrock/formation-router';
import {alfAccountTypes, AccountParty} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import * as contactActionTypes from '../contactActionTypes';
import fetchRequest from '../../application/sagas/fetchRequest';
import contactAccountPartyDataModalTypes from '../modals/contactAccountPartyDataModalTypes';
import bcActionFailedModalTypes from '../modals/bcActionFailedModalTypes';
import routePaths from '../../routePaths';

// todo rewrite saga and find suitable change for delay after modal closure
//  (needed for route unmount effect on invoice screen)
const accountPartyCreationFlow = function* accountPartyCreationFlow() {
    let shouldWaitForAction = true;
    let payloadData = null;
    let shouldRetryAccountPartyCreation = false;
    let retryAccountPartyCreationData = null;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    while (true) {
        if (shouldWaitForAction) {
            const {payload} = yield take(contactActionTypes.INITIATE_CREATE_ACCOUNT_PARTY_FLOW);
            payloadData = payload;
        }

        let chosenModalOption;
        const {client, accountPartyType, selectedContact} = payloadData;
        const modalType = accountPartyType === alfAccountTypes.CREDITOR
            ? contactAccountPartyDataModalTypes.CREATE_CREDITOR
            : contactAccountPartyDataModalTypes.CREATE_DEBTOR;

        if (!shouldRetryAccountPartyCreation && client && accountPartyType && selectedContact) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS,
                payload: {contactDTOs: [selectedContact]},
            });

            yield* openModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
                client,
                accountPartyType,
                contactId: selectedContact.id,
                type: modalType,
            });

            chosenModalOption = yield take([
                contactActionTypes.DECLINE_CREATE_ACCOUNT_PARTY,
                contactActionTypes.CONFIRM_CREATE_ACCOUNT_PARTY,
            ]);
        }

        yield* closeModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
            client,
            accountPartyType,
            contactId: selectedContact.id,
            type: modalType,
        });
        yield delay(100);

        if (shouldRetryAccountPartyCreation
            || chosenModalOption.type === contactActionTypes.CONFIRM_CREATE_ACCOUNT_PARTY) {
            const accountPartyData = !shouldRetryAccountPartyCreation && chosenModalOption?.payload?.accountPartyData
                ? chosenModalOption?.payload?.accountPartyData : retryAccountPartyCreationData?.accountPartyData;

            const invoiceId = !shouldRetryAccountPartyCreation && chosenModalOption?.payload?.invoiceId
                ? chosenModalOption?.payload?.invoiceId : retryAccountPartyCreationData?.invoiceId;

            yield* openModal(modalIds.LOADER, {type: modalType});

            yield fork(fetchRequest,
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST,
                leaAssignmentFlowService.createAccountParty,
                {
                    accountPartyData: {
                        ...AccountParty.objectToDTO(accountPartyData),
                        client,
                    },
                });

            const responseAction = yield take([
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST_SUCCEEDED,
                contactActionTypes.CREATE_NEW_ACCOUNT_PARTY_REQUEST_FAILED,
            ]);

            yield delay(1300);
            yield* closeModal(modalIds.LOADER, {type: modalType});
            yield delay(100);
            shouldRetryAccountPartyCreation = false;

            if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {accountPartyData} = response;

                // TODO: Consider storing only id of the newly created account party
                yield put({
                    type: contactActionTypes.STORE_ACCOUNT_PARTY,
                    payload: {accountPartyDTO: accountPartyData},
                });

                shouldWaitForAction = true;
                const queryParams = new URLSearchParams();
                queryParams.append('ids', selectedContact.id);
                queryParams.append('client', client);
                yield put(replace(resolveRoute(routePaths.CONTACTS, {}, {search: queryParams.toString()})));
                continue;
            }

            if (responseAction.error) {
                if (responseAction.payload?.response?.status === 503) {
                    yield put({
                        type: contactActionTypes.SET_BC_ERROR_MESSAGE,
                        payload: {errorDescription: JSON.parse(responseAction.payload.message)},
                    });
                }

                yield* openModal(modalIds.BC_ACTION_FAILED_MODAL,
                    {type: bcActionFailedModalTypes.CREATE_ACCOUNT_PARTY});

                const nextAction = yield take([
                    contactActionTypes.RETRY_ACCOUNT_PARTY_CREATION,
                    contactActionTypes.CANCEL_ACTION,
                ]);

                yield* closeModal(modalIds.BC_ACTION_FAILED_MODAL,
                    {type: bcActionFailedModalTypes.CREATE_ACCOUNT_PARTY});
                yield delay(100);

                if (nextAction.type === contactActionTypes.RETRY_ACCOUNT_PARTY_CREATION) {
                    shouldRetryAccountPartyCreation = true;
                    shouldWaitForAction = false;
                    retryAccountPartyCreationData = {
                        accountPartyData,
                        invoiceId,
                    };
                    continue;
                }

                if (nextAction.type === contactActionTypes.CANCEL_ACTION) {
                    shouldWaitForAction = false;
                    shouldRetryAccountPartyCreation = false;
                    continue;
                }
            }
        }

        shouldWaitForAction = true;
        yield* closeModal(modalIds.CONTACT_ACCOUNT_PARTY_DATA, {
            client,
            accountPartyType,
            contactId: selectedContact.id,
            type: modalType,
        });
    }
};

export default accountPartyCreationFlow;
