import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {europeanCountries, alfClientTypes, alfBusinessRelationTypes, alfSalutationTypes, alfContactTypes, Address} from '@ace-de/eua-entity-types';
import {useStyles, Form, SelectField, InputField, ButtonPrimary/* , ButtonSecondary */, Divider, Option, AutocompleteField} from '@ace-de/ui-components';
import {Icon, saveIcon, arrowDownIcon/* , communicationIcon */} from '@ace-de/ui-components/icons';
import * as invoiceSelectors from '../../invoices/invoiceSelectors';
import * as contactSelectors from '../contactSelectors';
import * as contactActionTypes from '../contactActionTypes';
import contactDataModalTypes from '../modals/contactDataModalTypes';

const ContactData = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateModal = createTranslateShorthand('contact_data_modal');
    const {location, invoice, selectedContact} = props;
    const searchQueryParams = new URLSearchParams(location?.search || '');
    const {confirmContactCreation} = props;
    const {confirmEditContact} = props;

    const [contactFormData, setContactFormData] = useState({...selectedContact} || null);
    const [client, setClient] = useState(searchQueryParams.get('client') || '');
    const [country, setCountry] = useState(selectedContact?.address?.country || '');

    const sortedCountries = Object.entries(europeanCountries)
        .map(([countryCode, country]) => [
            countryCode,
            {id: country.id, name: translate(`global.country.${snakeCase(country.name)}`)},
        ])
        .sort(([countryCodeA, countryA], [countryCodeB, countryB]) => {
            return countryA.name.localeCompare(countryB.name);
        });

    const handleOnFormChange = formValues => {
        if (!formValues) return;
        const {city, postCode, street, client, ...restContactData} = formValues;

        setContactFormData({
            ...restContactData,
            address: {
                ...new Address({
                    city,
                    country,
                    postCode,
                    street,
                }),
            },
        });
    };

    const handleOnSubmit = () => {
        if (!contactFormData) return;

        const contactData = {
            ...contactFormData,
            type: selectedContact ? undefined : contactFormData.type,
        };

        if (selectedContact) {
            confirmEditContact({
                client,
                invoiceId: invoice?.id,
                contactId: selectedContact.id,
                contactData,
            });
            return;
        }

        confirmContactCreation({
            client,
            invoiceId: invoice?.id,
            contactData,
        });
    };

    const isSaveCTADisabled = !client || (selectedContact && !contactFormData?.type)
        || contactFormData?.businessRelations?.length === 0 || !contactFormData?.name
        || !contactFormData?.address?.street || !contactFormData?.address?.postCode
        || !contactFormData?.address?.city || !contactFormData?.address?.country;

    return (
        <div>
            <Form name="contactForm" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                <div
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--direction-column',
                        'global!ace-u-full-width',
                    ])}
                >
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--bottom-24')}>
                        <SelectField
                            name="client"
                            label={`${translateModal('select_field_label.client')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={client || ''}
                            onChange={setClient}
                        >
                            {Object.values(alfClientTypes).map((client, idx) => (
                                <Option
                                    key={`${client}-${idx}`}
                                    name={client}
                                    value={client}
                                >
                                    {client}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="type"
                            label={`${translateModal('select_field_label.contact_type')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.type || ''}
                            isDisabled={!!selectedContact}
                        >
                            {Object.values(alfContactTypes).map((contactType, idx) => (
                                <Option
                                    key={`${contactType}-${idx}`}
                                    name={contactType}
                                    value={contactType}
                                >
                                    {translateModal(`select_option_label.${contactType.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <SelectField
                            name="businessRelations"
                            label={`${translateModal('select_field_label.business_relation')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.businessRelations}
                            isDisabled={searchQueryParams.get('type') === contactDataModalTypes.EDIT_CONTACT}
                            isMultipleChoice={true}
                        >
                            {Object.values(alfBusinessRelationTypes).map((businessRelation, idx) => (
                                <Option
                                    key={`${businessRelation}-${idx}`}
                                    name={businessRelation}
                                    value={businessRelation}
                                    isDisabled={businessRelation === alfBusinessRelationTypes.MEMBER}
                                >
                                    {translate(`global.business_relation_type.${businessRelation.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                    </div>
                    <Divider />
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-24')}>
                        <SelectField
                            name="salutation"
                            label={translateModal('select_field_label.salutation')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.salutation || null}
                        >
                            {Object.values(alfSalutationTypes).map((salutation, idx) => (
                                <Option
                                    key={`${salutation}-${idx}`}
                                    name={salutation}
                                    value={salutation}
                                >
                                    {translate(`global.salutation_type.${salutation.toLowerCase()}`)}
                                </Option>
                            ))}
                        </SelectField>
                        <InputField
                            name="name"
                            label={`${translateModal('input_field_label.name')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.name || ''}
                        />
                        <InputField
                            name="street"
                            label={`${translateModal('input_field_label.address')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.address?.street || ''}
                        />
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--top-24')}>
                        <InputField
                            name="postCode"
                            label={`${translateModal('input_field_label.post_code')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.address?.postCode || ''}
                        />
                        <InputField
                            name="city"
                            label={`${translateModal('input_field_label.city')}*`}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.address?.city || ''}
                        />
                        <AutocompleteField
                            name="country"
                            label={`${translateModal('select_field_label.country')}*`}
                            placeholder={translate('global.select.placeholder')}
                            icon={arrowDownIcon}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={country || ''}
                            onChange={setCountry}
                        >
                            {sortedCountries.length > 0 && sortedCountries
                                .map(([countryCode, country]) => {
                                    return (
                                        <Option
                                            key={country.id}
                                            name={`country-${countryCode}`}
                                            value={europeanCountries[countryCode].name}
                                        >
                                            {country.name}
                                        </Option>
                                    );
                                })}
                        </AutocompleteField>
                    </div>
                    <div className={cx('global!ace-u-grid', 'global!ace-u-margin--24-0')}>
                        <InputField
                            name="email"
                            label={translateModal('input_field_label.email')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.email || ''}
                        />
                        <InputField
                            name="phoneNo"
                            label={translateModal('input_field_label.phone_no')}
                            className={cx('global!ace-u-grid-column--span-4')}
                            value={selectedContact?.phoneNo || ''}
                        />
                    </div>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--justify-flex-end',
                            'global!ace-u-margin--top-24',
                        ])}
                    >
                        <ButtonPrimary name="saveButton" type="submit" isDisabled={isSaveCTADisabled}>
                            <Icon
                                icon={saveIcon}
                                className={cx([
                                    'global!ace-c-icon--color-contrast',
                                    'global!ace-u-margin--right-8',
                                ])}
                            />
                            {translateModal('button_label.save')}
                        </ButtonPrimary>
                    </div>
                </div>
            </Form>
        </div>
    );
};

ContactData.propTypes = {
    location: PropTypes.object,
    invoice: PropTypes.object,
    selectedContact: PropTypes.object,
    confirmContactCreation: PropTypes.func.isRequired,
    confirmEditContact: PropTypes.func.isRequired,
};

ContactData.defaultProps = {
    location: null,
    invoice: null,
    selectedContact: null,
};

const mapStateToProps = (state, props) => {
    const getInvoice = invoiceSelectors.createInvoicesSelector();
    const getSelectedContact = contactSelectors.createContactSelector();

    return {
        invoice: getInvoice(state, props),
        selectedContact: getSelectedContact(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    confirmContactCreation: payload => dispatch({
        type: contactActionTypes.CONFIRM_CREATE_CONTACT,
        payload,
    }),
    confirmEditContact: payload => dispatch({
        type: contactActionTypes.CONFIRM_EDIT_CONTACT,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactData));
