import {take, put, fork, select} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {Task, etmTaskStatusTypes, etmTaskTypes} from '@ace-de/eua-entity-types';
import fetchRequest from '../../application/sagas/fetchRequest';
import modalIds from '../../modalIds';
import * as taskActionTypes from '../taskActionTypes';
import taskDataModalTypes from '../modals/taskDataModalTypes';

const initiateCreateTaskFlow = function* initiateCreateTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    while (true) {
        yield take(taskActionTypes.INITIATE_TASK_CREATION_FLOW);

        yield* openModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});
        const chosenModalOption = yield take([
            taskActionTypes.CONFIRM_TASK_CREATION,
            taskActionTypes.DECLINE_TASK_CREATION,
        ]);

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.CONFIRM_TASK_CREATION) {
            const {taskData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                taskActionTypes.CREATE_TASK_REQUEST,
                ecsTaskManagementService.createTask,
                {
                    taskDTO: Task.objectToDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.CREATE_TASK_REQUEST_FAILED,
            ]);

            if (responseAction.type === taskActionTypes.CREATE_TASK_REQUEST_SUCCEEDED) {
                const searchQueryParams = new URLSearchParams();
                searchQueryParams.append('assignedCaseId', taskData.assignedCaseId);
                searchQueryParams.append('taskType', etmTaskTypes.LEA);
                searchQueryParams.append('status', etmTaskStatusTypes.DUE);
                searchQueryParams.append('status', etmTaskStatusTypes.OVERDUE);

                yield put({
                    type: taskActionTypes.SEARCH_TASKS,
                    payload: {
                        searchQueryParams,
                    },
                });
            }
        }

        yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.CREATE_TASK});
    }
};

export default initiateCreateTaskFlow;
