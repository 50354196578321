import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {HeaderBar, useStyles, NavigationBar, ContentBlock, TabSet, ContentItem, Tab, TabBar, TabPanel, AppLayoutMain, Icon, plusIcon, ButtonPrimary, Badge} from '@ace-de/ui-components';
import {persistenceStates, alfServiceCaseStatusTypes} from '@ace-de/eua-entity-types';
import ServiceCaseHeader from './ui-elements/ServiceCaseHeader';
import scOverviewScreenTabs from './scOverviewScreenTabs';
import SCOverviewTab from './SCOverviewTab';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import * as invoiceActionTypes from '../invoices/invoiceActionTypes';
import SCBaseDataTab from './SCBaseDataTab';
import SCDocumentTab from './SCDocumentTab';
import SCCommunicationTab from './SCCommunicationTab';
import TaskListPanel from '../tasks/ui-elements/TaskListPanel';
import CaseProtocolPanel from '../case-logs/CaseProtocolPanel';
import FilterPanel from '../case-logs/FilterPanel';

const ServiceCaseOverviewScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('service_case_overview_screen');
    const {serviceCase, createNewInvoice, taskCountIndicator} = props;
    const {activeTab} = useSearchQueryParams();

    if (!serviceCase) return null;

    return (
        <Fragment>
            <HeaderBar className={cx('lea-c-service-case-header')}>
                <ServiceCaseHeader />
            </HeaderBar>
            <TabSet
                name="service-case-overview-screen-tab-set"
                isRoutingEnabled={true}
                qaIdent="service-case-overview-screen"
            >
                <NavigationBar>
                    <div
                        className={cx([
                            'global!ace-u-flex',
                            'global!ace-u-flex--direction-row',
                            'global!ace-u-flex--align-center',
                        ])}
                    >
                        <TabBar
                            name="service-case-overview-tabs"
                            tabSet="service-case-overview-screen-tab-set"
                            defaultValue={activeTab || scOverviewScreenTabs.OVERVIEW}
                            isDisabled={serviceCase.persistenceState === persistenceStates.PENDING}
                            className={cx('global!ace-u-flex--grow-1')}
                        >
                            <Tab
                                name={scOverviewScreenTabs.OVERVIEW}
                                value={scOverviewScreenTabs.OVERVIEW}
                            >
                                {translateScreen('tab_name.overview')}
                            </Tab>
                            <Tab
                                name={scOverviewScreenTabs.BASIC_DATA}
                                value={scOverviewScreenTabs.BASIC_DATA}
                            >
                                {translateScreen('tab_name.basic_data')}
                            </Tab>
                            <Tab
                                name={scOverviewScreenTabs.LOG}
                                value={scOverviewScreenTabs.LOG}
                            >
                                {translateScreen('tab_name.case_log')}
                            </Tab>
                            <Tab
                                name={scOverviewScreenTabs.COMMUNICATION}
                                value={scOverviewScreenTabs.COMMUNICATION}
                            >
                                {translateScreen('tab_name.communication')}
                            </Tab>
                            <Tab
                                name={scOverviewScreenTabs.TASKS}
                                value={scOverviewScreenTabs.TASKS}
                            >
                                {translateScreen('tab_name.tasks')}
                                {taskCountIndicator > 0 && (
                                    <Badge
                                        className={cx([
                                            'ace-c-badge--status-notification',
                                            'global!ace-u-margin--left-8',
                                        ])}
                                    >
                                        {taskCountIndicator}
                                    </Badge>
                                )}
                            </Tab>
                            <Tab
                                name={scOverviewScreenTabs.DOCUMENT}
                                value={scOverviewScreenTabs.DOCUMENT}
                            >
                                {translateScreen('tab_name.document')}
                            </Tab>
                        </TabBar>
                        {(serviceCase.status === alfServiceCaseStatusTypes.OPEN
                         || serviceCase.status === alfServiceCaseStatusTypes.COMPLETED) && (
                         <ButtonPrimary
                             onClick={() => { createNewInvoice({serviceCaseId: serviceCase.id}); }}
                             className={cx([
                                 'global!ace-u-flex',
                                 'global!ace-u-flex--justify-flex-end',
                                 'global!ace-u-flex--align-center',
                                 'global!ace-u-margin--right-32',
                             ])}
                         >
                             <Icon
                                 icon={plusIcon}
                                 className={cx([
                                     'ace-c-icon--color-contrast',
                                     'global!ace-u-margin--right-8',
                                 ])}
                             />
                             {translateScreen('button_label.create_new_invoice')}
                         </ButtonPrimary>
                        )}
                    </div>
                </NavigationBar>
                <AppLayoutMain>
                    <TabPanel for={scOverviewScreenTabs.OVERVIEW}>
                        <SCOverviewTab />
                    </TabPanel>
                    <TabPanel for={scOverviewScreenTabs.BASIC_DATA}>
                        <SCBaseDataTab />
                    </TabPanel>
                    <TabPanel for={scOverviewScreenTabs.LOG}>
                        <ContentBlock>
                            <ContentItem className={cx('ace-c-content-item--span-9')}>
                                <CaseProtocolPanel />
                            </ContentItem>
                            <ContentItem className={cx('ace-c-content-item--span-3')}>
                                <FilterPanel />
                            </ContentItem>
                        </ContentBlock>
                    </TabPanel>
                    <TabPanel for={scOverviewScreenTabs.COMMUNICATION}>
                        <SCCommunicationTab />
                    </TabPanel>
                    <TabPanel for={scOverviewScreenTabs.TASKS}>
                        <TaskListPanel />
                    </TabPanel>
                    <TabPanel for={scOverviewScreenTabs.DOCUMENT}>
                        <SCDocumentTab />
                    </TabPanel>
                </AppLayoutMain>
            </TabSet>
        </Fragment>
    );
};

ServiceCaseOverviewScreen.propTypes = {
    serviceCase: PropTypes.object,
    taskCountIndicator: PropTypes.number,
    createNewInvoice: PropTypes.func.isRequired,
};

ServiceCaseOverviewScreen.defaultProps = {
    serviceCase: null,
    taskCountIndicator: 0,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
        taskCountIndicator: state.tasks.taskCountIndicator,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createNewInvoice: payload => dispatch({
            type: invoiceActionTypes.CREATE_NEW_INVOICE,
            payload,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCaseOverviewScreen);
