import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {snakeCase} from 'change-case';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {ampMembershipTypes, ampMemberStatusTypes, ampMemberTariffGroups, ampMemberTariffAdditionalPackageTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow, Badge} from '@ace-de/ui-components';
import {Icon, warningAlertIcon} from '@ace-de/ui-components/icons';
import * as serviceCaseSelectors from '../serviceCaseSelectors';
import config from '../../config';

const MemberDataPanel = ({serviceCase}) => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sc_base_data_tab');

    if (!serviceCase) return null;

    const {member} = serviceCase;
    const memberName = member?.personalDetails
        ? member.personalDetails.membershipType === ampMembershipTypes.CORPORATE
            ? member.personalDetails.name
            : `${member.personalDetails.firstName} ${member.personalDetails.surname}`
        : '-';
    const tariffGroup = member?.tariffDetails?.tariffGroup ? translate(`global.member_tariff_group.${snakeCase(ampMemberTariffGroups[member.tariffDetails.tariffGroup])}`) : '-';
    const additionalPackages = member?.tariffDetails?.additionalPackages
        ? member.tariffDetails.additionalPackages?.reduce((prev, curr) => prev + (prev === '' ? '' : ' & ')
            + translate(`global.member_tariff_additional_package.${snakeCase(ampMemberTariffAdditionalPackageTypes[curr])}`), '')
        : '';

    return (
        <Panel
            title={translateTab('member_data_panel.title')}
            qaIdent="member-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.member_name')}
                        qaIdent="member-name"
                    >
                        {memberName}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.address')}
                        qaIdent="member-address"
                    >
                        {member?.personalDetails?.address?.displayAddress || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.phone_no')}
                        qaIdent="member-phone-no"
                    >
                        {member?.contactDetails?.phoneNo || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.birth_date')}
                        qaIdent="member-birth-date"
                    >
                        {member?.personalDetails?.birthday
                            ? moment(member.personalDetails.birthday).format('DD.MM.YYYY')
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.start_date_membership')}
                        qaIdent="member-start-date-membership"
                    >
                        {member.beginMembership
                            ? moment(member.beginMembership).format('DD.MM.YYYY')
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.external_id')}
                        qaIdent="member-external-id"
                    >
                        {serviceCase.externalCaseId || '-'}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.membership_no')}
                        qaIdent="member-membership-no"
                    >
                        {member?.membershipNo || '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.tariff')}
                        qaIdent="member-tariff"
                    >
                        {(tariffGroup || additionalPackages)
                            ? `${tariffGroup || ''} ${additionalPackages ? ` | ${additionalPackages}` : ''}`
                            : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.status')}
                        qaIdent="member-status"
                    >
                        {member.status !== ampMemberStatusTypes.EMPTY ? (
                            <Badge status={member.status.toLowerCase()}>
                                {translate(`global.member_status.${snakeCase(member.status)}`)}
                            </Badge>
                        ) : '-'}
                    </DataRow>
                    <DataRow
                        label={translateTab('member_data_panel_row_label.dunning_level')}
                        qaIdent="member-dunning-level"
                    >
                        {member?.dunningLevel !== null ? (
                            <Fragment>
                                <span
                                    className={cx({
                                        'global!ace-u-typography--color-warning': member.dunningLevel
                                            >= config.MEMBER_DUNNING_LEVEL_LIMIT,
                                        'global!ace-u-typography--color-success': member.dunningLevel
                                            < config.MEMBER_DUNNING_LEVEL_LIMIT,
                                    })}
                                >
                                    {member.dunningLevel}
                                </span>
                                {member.dunningLevel >= config.MEMBER_DUNNING_LEVEL_LIMIT && (
                                    <Icon
                                        icon={warningAlertIcon}
                                        className={cx('ace-c-icon--color-warning', 'global!ace-u-margin--left-8')}
                                    />
                                )}
                            </Fragment>
                        ) : '-'}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

MemberDataPanel.propTypes = {
    serviceCase: PropTypes.object,
};

MemberDataPanel.defaultProps = {
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const getServiceCase = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCase: getServiceCase(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(MemberDataPanel));
