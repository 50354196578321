import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as invoiceActionTypes from '../invoiceActionTypes';

const loadAccountPartyOptions = function* loadAccountPartyOptions({payload}) {
    const {accountPartyOptionsIds, client} = payload;

    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    if (accountPartyOptionsIds.length === 0) return;

    const searchQueryParams = new URLSearchParams();
    searchQueryParams.append('client', client);
    searchQueryParams.append('ids', accountPartyOptionsIds);

    yield fork(
        fetchRequest,
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST,
        leaAssignmentFlowService.getContacts,
        {searchQueryParams},
    );

    const actionResponse = yield take([
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_SUCCEEDED,
        invoiceActionTypes.FETCH_ACCOUNT_PARTY_OPTIONS_REQUEST_FAILED,
    ]);

    if (!actionResponse.error) {
        const {payload: searchResultsPayload} = actionResponse;
        const {contactDTOs} = searchResultsPayload.response;
        yield put({
            type: invoiceActionTypes.STORE_ACCOUNT_PARTY_OPTIONS,
            payload: {contactDTOs},
        });
    }
};

export default loadAccountPartyOptions;
