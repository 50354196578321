import {fork, put, select, take} from 'redux-saga/effects';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as contactActionTypes from '../contactActionTypes';

const searchContacts = function* searchContacts() {
    const {serviceManager} = yield select(state => state.application);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');
    while (true) {
        const {payload} = yield take(contactActionTypes.SEARCH_CONTACTS);
        const {searchQueryParams} = payload;

        if (!searchQueryParams) continue;

        if (searchQueryParams.get('modal')) searchQueryParams.delete('modal');

        yield fork(
            fetchRequest,
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST,
            leaAssignmentFlowService.getContacts,
            {searchQueryParams},
        );

        const responseAction = yield take([
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_SUCCEEDED,
            contactActionTypes.GET_CONTACTS_SEARCH_RESULTS_REQUEST_FAILED,
        ]);
        if (responseAction.error) {
            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_ERROR,
                payload: {isInvoiceContactsSearchError: true},
            });
        }

        if (!responseAction.error) {
            const {payload: searchResultsPayload} = responseAction;
            const {contactDTOs, totalCount} = searchResultsPayload.response;

            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_RESULTS,
                payload: {contactDTOs, totalCount},
            });

            yield put({
                type: contactActionTypes.STORE_CONTACTS_SEARCH_ERROR,
                payload: {isInvoiceContactsSearchError: false},
            });
        }
    }
};

export default searchContacts;
