import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, downloadIcon, Icon, InteractiveIcon, Panel, outgoingMessageIcon, useStyles, uploadIcon, incomingMessageIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {alfInvoiceStatusTypes, alfMessageChannelTypes, alfMessageTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {resolveRoute, useRouteUnmountEffect, withRouter} from '@computerrock/formation-router';
import routePaths from '../routePaths';
import * as invoiceSelectors from '../invoices/invoiceSelectors';
import * as communicationActionTypes from '../communication/communicationActionTypes';
import * as serviceCaseSelectors from './serviceCaseSelectors';
import config from '../config';
import serviceManager from '../serviceManager';
import useBeforeUnloadEffect from '../useBeforeUnloadEffect';

const generateMessagePreview = (messageContent, isFullContent) => {
    if (!messageContent) return;
    if (isFullContent || messageContent.length <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT) {
        return messageContent.replaceAll(/<[^>]*>/g, ' ').trim();
    }
    let characters = '';
    [...messageContent].forEach((char, index) => {
        if (index <= config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT - 1) characters += char;
    });
    return `${characters.replaceAll(/<[^>]*>/g, ' ').trim()}... `;
};

const SCCommunicationTab = props => {
    const {cx} = useStyles();
    const {initiateReplyUploadFlow, initiateDownloadAllDocuments, updateMessageAsRead} = props;
    const {history, serviceCaseInvoices, serviceCaseMessages, serviceCase, location} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('sc_communication_tab');
    const [messages, setMessages] = useState([]);
    const [readMessageIds, setReadMessageIds] = useState([]);
    const messageId = new URLSearchParams(location?.search).get('messageId');
    const {member} = serviceCase;
    const recipientContactNames = useMemo(() => {
        return member ? {[member.membershipNo]: member.personalDetails?.displayName || ''} : {};
    }, [member]);
    const leaAssignmentFlowService = serviceManager.loadService('leaAssignmentFlowService');

    const handleOnMessageContentExpansion = (messageId, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const openedMessage = serviceCaseMessages.find(message => message.id === Number(messageId));
        if (!openedMessage) return;

        if (!openedMessage.isRead) setReadMessageIds(prevState => ([
            ...(prevState.filter(id => id !== openedMessage.id)),
            openedMessage.id,
        ]));

        setMessages(prevState => {
            return prevState.map(message => {
                if (message.id === Number(messageId)) return {
                    ...message,
                    messageContentPreview: generateMessagePreview(openedMessage.content, true),
                };
                return message;
            });
        });
    };

    useEffect(() => {
        if (serviceCaseMessages.length === 0) return;
        setMessages(serviceCaseMessages.map(message => {
            return {
                ...message,
                ...(message.channel === alfMessageChannelTypes.ELLA
                    && message.messageType === alfMessageTypes.INCOMING && {
                    messageContentPreview: messageId && message.id === Number(messageId)
                        ? generateMessagePreview(message.content, true)
                        : generateMessagePreview(message.content, false),
                }),
            };
        }));
        const openedMessage = serviceCaseMessages.find(message => message.id === Number(messageId));
        if (!openedMessage) return;

        if (!openedMessage.isRead) setReadMessageIds(prevState => ([
            ...(prevState.filter(id => id !== openedMessage.id)),
            openedMessage.id,
        ]));
    }, [messageId, serviceCaseMessages]);

    useEffect(() => {
        serviceCaseInvoices.forEach(invoice => {
            if (invoice.accountParty) {
                recipientContactNames[invoice.accountParty.id] = invoice.accountParty.name;
            }
        });
    }, [serviceCaseInvoices, recipientContactNames]);

    const redirectToInvoiceDetails = invoiceId => {
        const invoice = serviceCaseInvoices.find(invoice => invoice.id === invoiceId);
        if (!invoice) return;

        const {status} = invoice;

        if (status === alfInvoiceStatusTypes.OPEN) return;

        if (status === alfInvoiceStatusTypes.IN_CREATION) {
            history.push(resolveRoute(routePaths.INVOICES, {
                serviceCaseId: invoice.serviceCaseId,
                invoiceId: invoice.id,
            }));
            return;
        }

        history.push(resolveRoute(routePaths.INVOICE_OVERVIEW, {
            serviceCaseId: invoice.serviceCaseId,
            invoiceId: invoice.id,
        }));
    };

    useRouteUnmountEffect(() => {
        if (readMessageIds?.length === 0) return;
        updateMessageAsRead({messageIds: readMessageIds});
    }, [updateMessageAsRead, readMessageIds]);

    useBeforeUnloadEffect(event => {
        if (readMessageIds?.length === 0) return;
        return {
            request: leaAssignmentFlowService.updateMessagesAsRead,
            requestData: {
                messageIds: readMessageIds,
            },
        };
    });

    return (
        <Panel
            title={translateTab('messages_panel.title')}
            actions={(
                <ButtonPrimary onClick={() => initiateReplyUploadFlow()}>
                    <Icon
                        icon={uploadIcon}
                        className={cx([
                            'global!ace-u-margin--right-16',
                            'ace-c-icon--color-contrast',
                        ])}
                    />
                    {translateTab('button_label.upload_reply')}
                </ButtonPrimary>
            )}
        >
            <Table qaIdent="messages-overview-table">
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="message-type" colSpan={2} />
                        <TableCell qaIdentPart="created-date" colSpan={3}>
                            {translateTab('table_header.created_date')}
                        </TableCell>
                        <TableCell qaIdentPart="invoice-id" colSpan={2}>
                            {translateTab('table_header.invoice_id')}
                        </TableCell>
                        <TableCell qaIdentPart="channel" colSpan={3}>
                            {translateTab('table_header.communication_channel')}
                        </TableCell>
                        <TableCell qaIdentPart="message-subject" colSpan={9}>
                            {translateTab('table_header.subject')}
                        </TableCell>
                        <TableCell qaIdentPart="contact-name" colSpan={4}>
                            {translateTab('table_header.contact_name')}
                        </TableCell>
                        <TableCell qaIdentPart="user" colSpan={3}>
                            {translateTab('table_header.user')}
                        </TableCell>
                        <TableCell qaIdentPart="attachment" colSpan={2}>
                            {translateTab('table_header.attachment')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {messages?.length > 0 && messages.map(message => {
                        return (
                            <TableRow
                                key={`${message.serviceCaseId}-${message.id}`}
                                qaIdentPart={message.serviceCaseId}
                            >
                                <TableCell
                                    qaIdentPart="message-type"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <Icon
                                            icon={message.messageType === alfMessageTypes.OUTGOING
                                                ? outgoingMessageIcon
                                                : incomingMessageIcon}
                                            className={cx('global!ace-u-flex--shrink-0')}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="created-date"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.createdAt || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="invoice-id"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-typography--variant-body-bold',
                                            {
                                                'global!ace-u-typography--color-highlighted': message.invoiceId,
                                                'global!ace-u-cursor--pointer': message.invoiceId,
                                            },
                                        ])}
                                        onClick={() => redirectToInvoiceDetails(message.invoiceId)}
                                    >
                                        {message.invoiceId || '-'}
                                    </div>
                                </TableCell>
                                <TableCell
                                    qaIdentPart="channel"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {translate(`global.communication_channel_option.${message.channel.toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="message-subject"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={9}
                                    className={cx([
                                        'ace-c-table__cell--overflow-visible',
                                        'ace-c-table__cell--white-space-normal',
                                    ])}
                                >
                                    {message.channel === alfMessageChannelTypes.ELLA
                                    && message.messageType === alfMessageTypes.INCOMING
                                        ? (
                                            <div>
                                                {message.messageContentPreview}
                                                {message.content?.length > config.MESSAGE_CONTENT_PREVIEW_CHAR_LIMIT
                                                && (
                                                <span
                                                    className={cx([
                                                        'global!ace-u-typography--variant-body-bold',
                                                        'global!ace-u-typography--color-highlighted',
                                                        'global!ace-u-cursor--pointer',
                                                    ])}
                                                    onClick={event => handleOnMessageContentExpansion(
                                                        message.id,
                                                        event,
                                                    )}
                                                >
                                                    [{translateTab('table_body.read_more')}]
                                                </span>
                                                )}
                                            </div>
                                        )
                                        : message.subject || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="contact-name"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={4}
                                >
                                    {message.messageType === alfMessageTypes.OUTGOING
                                        ? message.recipient || '-'
                                        : message.sender || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="user"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={3}
                                >
                                    {message.creatorName || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="attachment"
                                    qaIdentPartPostfix={message.serviceCaseId}
                                    colSpan={2}
                                >
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--align-center',
                                            'global!ace-u-flex--justify-content-center',
                                        ])}
                                    >
                                        <InteractiveIcon
                                            icon={downloadIcon}
                                            className={cx([
                                                'ace-c-interactive-icon--primary',
                                                'global!ace-u-flex--shrink-0',
                                                'global!ace-u-cursor--pointer',
                                            ])}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                initiateDownloadAllDocuments({
                                                    attachments: message.attachments,
                                                });
                                            }}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

SCCommunicationTab.propTypes = {
    initiateReplyUploadFlow: PropTypes.func.isRequired,
    initiateDownloadAllDocuments: PropTypes.func.isRequired,
    serviceCaseInvoices: PropTypes.array,
    serviceCaseMessages: PropTypes.array,
    history: PropTypes.object,
    serviceCase: PropTypes.object,
    updateMessageAsRead: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};

SCCommunicationTab.defaultProps = {
    serviceCaseInvoices: [],
    serviceCaseMessages: [],
    history: null,
    serviceCase: null,
};

const mapStateToProps = (state, props) => {
    const serviceCaseInvoicesSelector = invoiceSelectors.createServiceCaseInvoicesSelector();
    const serviceCaseSelector = serviceCaseSelectors.createServiceCaseSelector();
    return {
        serviceCaseInvoices: serviceCaseInvoicesSelector(state, props),
        serviceCaseMessages: state.communication.messages,
        serviceCase: serviceCaseSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    initiateReplyUploadFlow: () => dispatch({
        type: communicationActionTypes.INITIATE_UPLOAD_REPLY_FLOW,
    }),
    initiateDownloadAllDocuments: payload => dispatch({
        type: communicationActionTypes.INITIATE_MESSAGE_ATTACHMENTS_DOWNLOAD_FLOW,
        payload,
    }),
    updateMessageAsRead: payload => dispatch({
        type: communicationActionTypes.UPDATE_MESSAGE_AS_READ,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCCommunicationTab));
