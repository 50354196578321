import {take, select, fork} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import {etmTaskStatusTypes, Task} from '@ace-de/eua-entity-types';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as taskActionTypes from '../taskActionTypes';
import taskDataModalTypes from '../modals/taskDataModalTypes';

const editTaskFlow = function* editTaskFlow() {
    const {serviceManager} = yield select(state => state.application);
    const ecsTaskManagementService = serviceManager.loadService('ecsTaskManagementService');

    while (true) {
        const {payload} = yield take(taskActionTypes.INITIATE_TASK_EDIT_FLOW);
        const {taskId} = payload;

        yield* openModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()});

        const chosenModalOption = yield take([
            taskActionTypes.CONFIRM_EDIT_TASK,
            taskActionTypes.DECLINE_EDIT_TASK,
            taskActionTypes.COMPLETE_TASK,
        ]);

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.CONFIRM_EDIT_TASK) {
            const {taskData} = chosenModalOption.payload;

            yield fork(
                fetchRequest,
                taskActionTypes.UPDATE_TASK_REQUEST,
                ecsTaskManagementService.updateTask,
                {
                    taskId,
                    taskPatchDTO: Task.objectToPatchDTO(taskData),
                },
            );

            const responseAction = yield take([
                taskActionTypes.UPDATE_TASK_REQUEST_SUCCEEDED,
                taskActionTypes.UPDATE_TASK_REQUEST_FAILED,
            ]);

            if (responseAction.error) {
                // todo error handling
            }
        }

        if (chosenModalOption && chosenModalOption.type === taskActionTypes.COMPLETE_TASK) {
            yield fork(
                fetchRequest,
                taskActionTypes.UPDATE_TASK_REQUEST,
                ecsTaskManagementService.updateTask,
                {
                    taskId,
                    taskPatchDTO: Task.objectToPatchDTO({status: etmTaskStatusTypes.DONE}),
                },
            );
        }

        yield* closeModal(modalIds.TASK_DATA_MODAL, {type: taskDataModalTypes.EDIT_TASK, taskId: taskId.toString()});
    }
};

export default editTaskFlow;
